import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  source: string
  alt: string
  className?: string
  onClick?: () => void
}

const Container = styled.div`
  display: block;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const FuAsset: FC<Props> = ({
  className,
  source,
  alt = "image",
  onClick = () => {}
}) => (
  <Container>
    <Image
      className={className}
      src={source}
      alt={alt}
      onClick={onClick}
      loading="lazy"
      decoding="async"
    />
  </Container>
)

export default FuAsset