import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconFile: FC<IIcon> = ({ size = "26", color, className = "" }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#BDDFD3"/>
      <path d="M14.0466 10.6956V6.47063L18.2974 10.6956H14.0466ZM8.63658 5.31836C7.7787 5.31836 7.09082 6.00204 7.09082 6.85472V19.1456C7.09082 19.5531 7.25367 19.9439 7.54357 20.232C7.83342 20.5201 8.22661 20.682 8.63658 20.682H17.911C18.3209 20.682 18.7141 20.5201 19.004 20.232C19.2938 19.9439 19.4567 19.5531 19.4567 19.1456V9.92745L14.8195 5.31836H8.63658Z" fill="#2DC08B"/>
    </svg>
  )
}

export default FuIconFile
