import React, { FC } from "react"
import styled from "styled-components"

interface Props {
  label: string
  isChecked: boolean
  onChange: () => void
  customLabel?: JSX.Element
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const CenterVertical = styled.div``

const CommonCheckbox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: ${({ theme }) => theme.primaryMain};
`

const CheckboxLabel = styled.label`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  font-family: Poppins-Regular, sans-serif;
  margin-left: 12px;
`

const FuCheckbox: FC<Props> = ({ label, isChecked, onChange, customLabel }) => {
  return (
    <Container>
      <CenterVertical>
        <CommonCheckbox
          id="box" 
          type="checkbox" 
          value={label} 
          checked={isChecked} 		
          onChange={onChange}
        />
      </CenterVertical>
      {customLabel ? customLabel : (
        <CheckboxLabel htmlFor={"box"}>
          {label}
        </CheckboxLabel>
      )}
    </Container>
  )
}

export default FuCheckbox
