import { Dispatch } from "redux"
import { loginService, ReqLogin, ResLogin } from "fu/services/auth"
import { cacheUtil, STATE_SUCCESS as AUTHENTICATE_SUCCESS } from "./authenticate"
import { CacheKey } from "fu/models/auth"

const STATE_BEGIN = "LOGIN_BEGIN"
const STATE_SUCCESS = "LOGIN_SUCCESS"
const STATE_FAILED = "LOGIN_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResLogin
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqLogin) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await loginService(body)
    cacheUtil.set(CacheKey.Token, response.token)
    cacheUtil.set(CacheKey.User, response.user)
    
    const token = cacheUtil.get(CacheKey.Token)
    const user = cacheUtil.get(CacheKey.User)

    if (!token || !user) {
      cacheUtil.removeAll()
      dispatch({ type: STATE_FAILED })
      return
    }

    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload: {
        token: token,
        user: user
      }
    })
    dispatch({
      type: STATE_SUCCESS,
      payload: response
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
    
    return error
  }
}
