import FuAsset from "../FuImage/FuAsset"
import { FuModal } from "../FuModal"
import FuImage from "../FuImage/FuImage"
import { imagepicker } from "fu/assets/images/general"
import { FuIconCamera, FuIconGallery, FuIconCross } from "../FuIcon"
import { FuButton, ETypes, ESizes } from "../FuButton"
import { convertBase64ToFile } from "fu/utils/file"
import React, { FC, useState, ChangeEvent, useMemo, useCallback, Fragment, useRef } from "react"
import Webcam from "react-webcam"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const InputContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
`

const CameraContainer = styled.label`
  cursor: pointer;
`

const Input = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`

const Camera = styled(FuAsset)`
  width: 80px;
  height: 80px;
`

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`

const SavedImage = styled(FuImage)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`

const Label = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.greyDark};
  margin-bottom: 12px;
`

const PickerChoice = styled.div`
  margin: 12px;
  display: flex;
  flex-direction: row;
`

const PickerChoiceTextContainer = styled.div`
  justify-content: center;
  margin: auto 20px auto 20px;
`

const PickerChoiceText = styled.label`
  font-family: Poppins-Medium, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
`

const CloseContainer = styled.div`
  cursor: pointer;
`

const Button = styled.div`
  margin-top: 12px;
`

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const videoConstraints = {
  width: 320,
  height: 320,
  facingMode: "user"
};

interface Props {
  savedImg?: string
  onPickImg: (image: File | null) => void
}

const FuImagePicker: FC<Props> = ({ onPickImg, savedImg }) => {
  const { t } = useTranslation()
  const webcamRef: any = useRef(null)
  const [showPickerModal, setShowPickerModal] = useState<boolean>(false)
  const [showCameraModal, setShowCameraModal] = useState<boolean>(false)
  const [image, setImage] = useState<File | null>(null)

  const capturePhoto = useCallback(
    async () => {
      const imageSrc = webcamRef.current.getScreenshot()
      try {
        const file: File = await convertBase64ToFile(imageSrc)
        
        setImage(file)
        onPickImg(file)
        setShowCameraModal(false)
      } catch (_) {}
    },
    [webcamRef]
  )

  const handleShowPickerModal = useCallback(() => {
    setShowPickerModal(!showPickerModal)
  }, [showPickerModal])

  const handleShowCameraModal = useCallback(() => {
    setShowPickerModal(false)
    setShowCameraModal(!showCameraModal)
  }, [showCameraModal])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    
    setShowPickerModal(false)
    setImage(file)
    onPickImg(file)
  }

  const renderPickerModal = useMemo(() => showPickerModal && (
    <FuModal
      isOpen={showPickerModal}
      onClose={handleShowPickerModal}
      children={
        <Fragment>
          <CloseContainer onClick={handleShowPickerModal}>
            <FuIconCross color="primaryMain" width="16" height="16" />
          </CloseContainer>
          <PickerChoice>
            <FuIconGallery size={"40"} color={"black"} />
            <PickerChoiceTextContainer>
              <PickerChoiceText htmlFor="add-single-img">
                {t("form.imageGallery")}
              </PickerChoiceText>
            </PickerChoiceTextContainer>
          </PickerChoice>
          <PickerChoice>
            <FuIconCamera size={"40"} color={"black"} />
            <PickerChoiceTextContainer>
              <PickerChoiceText onClick={handleShowCameraModal}>
                {t("form.imageCamera")}
              </PickerChoiceText>
            </PickerChoiceTextContainer>
          </PickerChoice>
        </Fragment>
      }
    />
  ), [showPickerModal, handleShowPickerModal, handleShowCameraModal])

  const renderCameraModal = useMemo(() => (
    <FuModal
      isOpen={showCameraModal}
      onClose={handleShowCameraModal}
      children={
        <Fragment>
          <Webcam
            audio={false}
            height={240}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={320}
            videoConstraints={videoConstraints}
          />
          <Button>
            <FuButton
              button={mainButton}
              text={t("form.imageCameraCapture")}
              onClick={capturePhoto}
            />
          </Button>
        </Fragment>
      }
    />
  ), [showCameraModal, handleShowCameraModal])

  return (
    <form>
      <Label>{t("form.image")}</Label>
      <InputContainer>
        <CameraContainer onClick={handleShowPickerModal}>
          {(image || savedImg) ? (
            <Fragment>
              {(savedImg && image === null) ?
                <SavedImage source={`upload/community/${savedImg}`} alt="Saved-Community-Img" /> :
              (
                <Image src={image ? URL.createObjectURL(image) : ""} />
              )}
            </Fragment>
          ) : (
            <Camera
              source={imagepicker}
              alt="camera"
            />
          )}
        </CameraContainer>
        <Input type="file" id="add-single-img" accept="image/*" onChange={onChange}/>
      </InputContainer>
      {renderPickerModal}
      {showCameraModal && renderCameraModal}
    </form>
  )
}

export default FuImagePicker
