import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconArrowLeft: FC<IIcon> = ({ width = "9", height = "15", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.21289 0.782825L0 7.48907L7.21289 14.1953L9 12.5337L3.57422 7.48907L9 2.44441L7.21289 0.782825Z" />
    </Icon>
  )
}

export default FuIconArrowLeft