import React, { ChangeEvent, FC, useCallback } from "react"
import styled, { DefaultTheme } from "styled-components"
import { IInput } from "./types"

type Props = {
  input: IInput
  type: string
  placeholder: string
  value: string
  prepend?: JSX.Element
  onChange: (name: string, value: string) => void
}

type ContainerProps = {
  radius?: string
  border?: keyof DefaultTheme
  backgroundColor?: keyof DefaultTheme
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  border: solid 1px ${({ theme, border }) => border ? theme[border] : theme.greyBorder};
  border-radius: ${({ radius }) => radius || "6px"};
  padding: 8px 10px;
  background-color: ${({ theme, backgroundColor }) => backgroundColor ? theme[backgroundColor] : "transparent"};
`

const PrependIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`

const Input = styled.input.attrs(({ placeholder }) => ({
  placeholder: placeholder,
}))`
  width: 100%;
  box-sizing: border-box;
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.textMain} !important;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder { 
    color: ${({ theme }) => theme.greyPlaceholder};
  }
`

const FuInput: FC<Props> = ({
  input,
  type,
  placeholder,
  value,
  prepend,
  onChange
}) => {
  const handleChage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(input.name, event.target.value)
  }, [input.name, onChange])

  return (
    <Container
      radius={input.radius}
      border={input.border}
      backgroundColor={input.backgroundColor}
    >
      {
        prepend && (
          <PrependIcon>
            {prepend}
          </PrependIcon>
        )
      }
      <Input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleChage}
      />
    </Container>
  )
}

export default FuInput