import { Paths } from "fu/routes/paths";
import { DOCS_URL } from "fu/constants/Environment";
import { FuLoader } from "fu/components/FuApp";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components"

type OuterContainerProps = {
  loading: boolean
}

const OuterContainer = styled.div<OuterContainerProps>`
  width: ${({ loading }) => loading ? "auto" : "100%"};
`

const Loader = styled.div`
  width: 100%;
`

const MainContent = styled.iframe`
  width: 100%;
  height: 100%;
`

const { PRIVACY_POLICY: { path } } = Paths

const ViewTermsAndPolicy: FC = () => {
  const { pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(true)

  const handleSetLoading = useCallback(() => {
    setLoading(false)
  }, [])

  const getFullUrl = useMemo(() => (
    `${DOCS_URL}${path === pathname ? path.slice(1) : "terms"}`
  ), [location.pathname])

  return (
    <OuterContainer loading={loading}>
      {
        loading ? (
          <Loader>
            <FuLoader />
          </Loader>
        ) : <></>
      }
      <MainContent src={getFullUrl} onLoad={handleSetLoading} />
    </OuterContainer>
  )
}

export default ViewTermsAndPolicy
