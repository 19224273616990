import { IUser } from "fu/models/user"
import client from "./client"
import formatError from "./error"

export interface ReqUserProfile {
  id_user: string
  logged_id_user: string
}

export interface ResUserProfile {
  status: boolean
  message: string
  expired: number
  user: IUser
}

export const userProfileService = (body: ReqUserProfile): Promise<ResUserProfile> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.get<ResUserProfile>(`profile?id_user=${body.id_user}&logged_id_user=${body.logged_id_user}`)).data
    if (response.status) {
      resolve(response)
      return
    }

    reject(new Error(response.message))
  } catch (err: any) {
    reject(formatError(err))
  }
})