export interface Path {
  path: string
  title: string
}

export const Paths = {
  // Public
  LOGIN: {
    path: "/login",
    title: ""
  },
  TERMS_AND_CONDITIONS: {
    path: "/terms-and-conditions",
    title: "termsAndConditions.title"
  },
  PRIVACY_POLICY: {
    path: "/privacy-policy",
    title: "privacyPolicy.title"
  },
  // Protected
  HOME: {
    path: "/",
    title: ""
  },
  CATEGORY: {
    path: "/category",
    title: ""
  },
  CATEGORY_DETAIL: {
    path: "/category/:slug",
    title: ""
  },
  COMMUNITY: {
    path: "/community",
    title: ""
  },
  COMMUNITY_DETAIL: {
    path: "/community/:slug",
    title: ""
  },
  COMMUNITY_TIMELINE: {
    path: "/community/detail/:slug",
    title: ""
  },
  COMMUNITY_ADD: {
    path: "/community/add",
    title: "communityAdd.title"
  },
  COMMUNITY_EDIT: {
    path: "/community/edit",
    title: "communityEdit.title"
  },
  FORUM_DISCUSSION_ADD: {
    path: "/community/detail/:slug/add-discussion",
    title: ""
  },
  FORUM_DISCUSSION_EDIT: {
    path: "/community/detail/:slug/edit-discussion",
    title: ""
  },
  COMMUNITY_TIMELINE_POST: {
    path: "/community/detail/timeline/:slug",
    title: ""
  },
  COMMUNITY_THREAD_DETAIL: {
    path: "/community/detail/thread/:slug",
    title: ""
  },
  FILE_MANAGER_MOVE: {
    path: "/community/detail/file/:slug",
    title: ""
  }
}
