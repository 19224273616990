import { listTopicService, ResListTopic } from "fu/services/topic"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_TOPIC_BEGIN"
const STATE_SUCCESS = "LIST_TOPIC_SUCCESS"
const STATE_FAILED = "LIST_TOPIC_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListTopic
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default () => async (dispatch: Dispatch, getState: () => RootState) => {
  try {
    const items = getState().static.listTopic.topics
    if (items.length > 0) return

    dispatch({ type: STATE_BEGIN })

    const response = await listTopicService()

    dispatch({
      type: STATE_SUCCESS,
      payload: response
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
