import client from "./client"
import formatError from "./error"
import { ITimeline, ITimelineComment } from "fu/models/timeline"

export interface ReqListTimeline {
  id_community: string
  limit: number
  current_page: number
}

export interface ResListTimeline {
  status: boolean
  data: Array<ITimeline>
}

export interface ReqTimelineCreate {
  id_community: string
  id_user: string
  content: string
}

export interface ResTimelineCreate {
  status: boolean
  message: string
}

export interface ReqTimelineUpdate {
  id_feed: string
  content: string
}

export interface ResTimelineUpdate {
  status: boolean
  message: string
}

export interface ReqTimelineDelete {
  id_feed: string
}

export interface ResTimelineDelete {
  status: boolean
  message: string
}

export interface ReqListTimelineComment {
  id_feed: string
  limit: number
  current_page: number
}

export interface ResListTimelineComment {
  status: boolean
  data: Array<ITimelineComment>
}

export interface ReqTimelineCommentCreate {
  id_feed: string
  id_user: string
  comment: string
}

export interface ResTimelineCommentCreate {
  status: boolean
  message: string
}

export interface ReqTimelineCommentDelete {
  id_comment_feed: string
  id_user: string
}

export interface ResTimelineCommentDelete {
  status: boolean
  message: string
}

export const listTimelineService = (body: ReqListTimeline): Promise<ResListTimeline> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResListTimeline>("timeline", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const timelineCreateService = 
  (body: ReqTimelineCreate): Promise<ResTimelineCreate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResTimelineCreate>("timeline/create", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const timelineUpdateService = 
  (body: ReqTimelineUpdate): Promise<ResTimelineUpdate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResTimelineUpdate>("timeline/update", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const timelineDeleteService = 
  (body: ReqTimelineDelete): Promise<ResTimelineDelete> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResTimelineDelete>("timeline/delete", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const listTimelineCommentService =
  (body: ReqListTimelineComment): Promise<ResListTimelineComment> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListTimelineComment>("timeline/comment", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const timelineCommentCreateService =
  (body: ReqTimelineCommentCreate): Promise<ResTimelineCommentCreate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResTimelineCommentCreate>("timeline/comment/create", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const timelineCommentDeleteService =
  (body: ReqTimelineCommentDelete): Promise<ResTimelineCommentDelete> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResTimelineCommentDelete>("timeline/comment/delete", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })
