import { combineReducers } from "redux"
import listTimelineReducer from "./listTimeline"
import createTimelineReducer from "./createTimeline"
import updateTimelineReducer from "./updateTimeline"
import deleteTimelineReducer from "./deleteTimeline"
import listTimelineCommentReducer from "./listTimelineComment"
import createTimelineCommentReducer from "./createTimelineComment"
import deleteTimelineCommentReducer from "./deleteTimelineComment"

const timelineReducer = combineReducers({
  listTimeline: listTimelineReducer,
  createTimeline: createTimelineReducer,
  updateTimeline: updateTimelineReducer,
  deleteTimeline: deleteTimelineReducer,
  listTimelineComment: listTimelineCommentReducer,
  createTimelineComment: createTimelineCommentReducer,
  deleteTimelineComment: deleteTimelineCommentReducer
})

export default timelineReducer