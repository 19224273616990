import client from "./client"
import formatError from "./error"

import { IUser } from "fu/models/user"

export interface ReqLogin {
  email_phone: string
  password: string
  fcm_token: string
}

export interface ResLogin {
  status: boolean
  message: string
  token: string
  user: IUser
}

export const loginService = (body: ReqLogin): Promise<ResLogin> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResLogin>("login", body)).data
    if (response.status) {
      resolve(response)
      return
    }

    reject(new Error(response.message))
  } catch (err: any) {
    reject(formatError(err))
  }
})