import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconLogout: FC<IIcon> = ({ size = "23", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1578 16.0872L17.4672 12.7778H5.11111V10.2222H17.4672L14.1578 6.91278L15.9722 5.11111L22.3611 11.5L15.9722 17.8889L14.1578 16.0872ZM20.4444 0C21.1222 0 21.7722 0.269245 22.2515 0.748505C22.7308 1.22776 23 1.87778 23 2.55556V8.52278L20.4444 5.96722V2.55556H2.55556V20.4444H20.4444V17.0328L23 14.4772V20.4444C23 21.1222 22.7308 21.7722 22.2515 22.2515C21.7722 22.7308 21.1222 23 20.4444 23H2.55556C1.13722 23 0 21.85 0 20.4444V2.55556C0 1.13722 1.13722 0 2.55556 0H20.4444Z" />
    </Icon>
  )
}

export default FuIconLogout