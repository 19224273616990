import { ResListFileManager } from "fu/services/filemanager"
import { StateActions } from "fu/stores/actions/filemanager/listFileManager"

type State = {
  loading: boolean
  fileManagers: ResListFileManager | null
  error: any
}

const initialState: State = {
  loading: true,
  fileManagers: null,
  error: null
}

const listFileManagerReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_FILE_MANAGER_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_FILE_MANAGER_SUCCESS":
      return {
        ...state,
        loading: false,
        fileManagers: action.payload
      }
    case "LIST_FILE_MANAGER_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listFileManagerReducer
