import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconFileManagement: FC<IIcon> = ({ width = "20", height = "16", color = "iconDark", className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.375H18.5M16.9375 14.75H2.5625C2.1481 14.75 1.75067 14.5854 1.45765 14.2924C1.16462 13.9993 1 13.6019 1 13.1875V2.5625C1 2.1481 1.16462 1.75067 1.45765 1.45765C1.75067 1.16462 2.1481 1 2.5625 1H5.52695C5.83546 1.00001 6.13707 1.09135 6.39375 1.2625L7.48125 1.9875C7.73793 2.15865 8.03954 2.24999 8.34805 2.25H16.9375C17.3519 2.25 17.7493 2.41462 18.0424 2.70765C18.3354 3.00067 18.5 3.3981 18.5 3.8125V13.1875C18.5 13.6019 18.3354 13.9993 18.0424 14.2924C17.7493 14.5854 17.3519 14.75 16.9375 14.75Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </Icon>
  )
}

export default FuIconFileManagement