import { Dispatch } from "redux"
import {
  threadCreateService,
  ResThreadCreate
} from "fu/services/thread"
import { RootState } from "fu/stores"

const STATE_BEGIN = "THREAD_CREATE_BEGIN"
const STATE_END = "THREAD_CREATE_END"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResThreadCreate
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: any) => async (
  dispatch: Dispatch, getState: () => RootState
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const { user: { id_user } } = getState().auth.authenticate
    body.append("id_user", id_user)

    const response = await threadCreateService(body)
    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
