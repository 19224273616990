import client from "./client"
import formatError from "./error"
import { ITopic } from "fu/models/topic"

export interface ResListTopic {
  status: boolean
  topic: Array<ITopic>
}

export const listTopicService = (): Promise<ResListTopic> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.get<ResListTopic>("topic")).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})