const NODE_ENV = process.env.NODE_ENV
const BASE_URL = process.env.BASE_URL
const IMAGE_URL = process.env.IMAGE_URL
const DOCS_URL = process.env.DOCS_URL
const AVATAR_URL = process.env.AVATAR_URL

export {
	NODE_ENV,
	BASE_URL,
  IMAGE_URL,
	DOCS_URL,
	AVATAR_URL
}