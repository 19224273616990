import { IThread } from "fu/models/thread"
import { StateActions } from "fu/stores/actions/thread/detailThread"

type State = {
  loading: boolean
  thread: IThread | null
  error: any
}

const initialState: State = {
  loading: true,
  thread: null,
  error: null
}

const threadDetailReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "DETAIL_THREAD_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "DETAIL_THREAD_SUCCESS":
      return {
        ...state,
        loading: false,
        thread: action.payload
      }
    case "DETAIL_THREAD_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default threadDetailReducer