import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconTimeline: FC<IIcon> = ({ width = "22", height = "16", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 2H20M7 8H20M7 14H20M2 2H2.01M2 8H2.01M2 14H2.01" stroke="#24292E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </Icon>
  )
}

export default FuIconTimeline