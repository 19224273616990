import {
  listCommunityByTopicService,
  ReqListCommunityByTopic,
  ResListCommunityByTopic
} from "fu/services/community"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_COMMUNITY_BEGIN"
const STATE_SUCCESS = "LIST_COMMUNITY_SUCCESS"
const STATE_FAILED = "LIST_COMMUNITY_FAILED"  

interface PartialReqListCommunityByTopic {
  id_topic: string
  limit: number
  current_page: number
}

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListCommunityByTopic
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: PartialReqListCommunityByTopic) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { user: { id_user } } = getState().auth.authenticate
      const items = getState().community.listCommunity.communities
      dispatch({ type: STATE_BEGIN })

      const response = await listCommunityByTopicService({ ...body, id_user })

      dispatch({
        type: STATE_SUCCESS,
        payload:
          body.current_page === 1
            ? response
            : { data: [...items, ...response.data] }
      })
    } catch (error: any) {
      dispatch({
        type: STATE_FAILED,
        error,
      })
    }
  }
