import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconFolder: FC<IIcon> = ({ size = "26", color, className = "" }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15_846)">
      <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#FFB1B5"/>
      <path d="M11.3452 6.5H6.3816C5.46332 6.5 4.72705 7.2231 4.72705 8.125V17.875C4.72705 18.3059 4.90137 18.7193 5.21166 19.024C5.52194 19.3288 5.94279 19.5 6.3816 19.5H19.618C20.0568 19.5 20.4776 19.3288 20.7879 19.024C21.0982 18.7193 21.2725 18.3059 21.2725 17.875V9.75C21.2725 8.8481 20.528 8.125 19.618 8.125H12.9998L11.3452 6.5Z" fill="#FF212A"/>
      </g>
      <defs>
      <clipPath id="clip0_15_846">
      <rect width="26" height="26" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default FuIconFolder