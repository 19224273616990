import { CommunityDetail } from "fu/models/community"
import { StateActions } from "fu/stores/actions/community/detailCommunity"

type State = {
  loading: boolean
  detail: CommunityDetail | null
  error: any
}

const initialState: State = {
  loading: true,
  detail: null,
  error: null
}

const detailCommunityReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "DETAIL_COMMUNITY_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "DETAIL_COMMUNITY_SUCCESS":
      return {
        ...state,
        loading: false,
        detail: action.payload.data
      }
    case "DETAIL_COMMUNITY_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default detailCommunityReducer
