import { ITimeline } from "fu/models/timeline"
import { StateActions } from "fu/stores/actions/timeline/listTimeline"

type State = {
  loading: boolean
  timeline: Array<ITimeline>
  error: any
}

const initialState: State = {
  loading: true,
  timeline: [],
  error: null
}

const listTimelineReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_TIMELINE_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_TIMELINE_SUCCESS":
      return {
        ...state,
        loading: false,
        timeline: action.payload.data
      }
    case "LIST_TIMELINE_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listTimelineReducer