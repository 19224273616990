import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import LanguageDetector from "i18next-browser-languagedetector"

import { NODE_ENV } from "fu/constants/Environment"
import langID from "fu/langs/id.json"
import langEN from "fu/langs/en.json"

const resources = {
  en: {
    translation: langEN
  },
  id: {
    translation: langID
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "id",
    debug: NODE_ENV === "development" ? true : false,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n