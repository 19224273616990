import { combineReducers } from "redux"
import listFileManagerReducer from "./listFileManager"
import uploadFileManagerReducer from "./uploadFileManager"
import createFileManagerReducer from "./createFileManager"
import deleteFileManagerReducer from "./deleteFileManager"
import moveFileManagerReducer from "./moveFileManager"

const fileManagerReducer = combineReducers({
  listFileManager: listFileManagerReducer,
  uploadFileManager: uploadFileManagerReducer,
  createFileManager: createFileManagerReducer,
  deleteFileManager: deleteFileManagerReducer,
  moveFileManager: moveFileManagerReducer
})

export default fileManagerReducer
