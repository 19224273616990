import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconForumDiscussion: FC<IIcon> = ({ width = "18", height = "16", color = "iconDark", className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 15H15M13.6667 1H16.3333C16.5101 1 16.6797 1.07024 16.8047 1.19526C16.9298 1.32029 17 1.48986 17 1.66667V3.08333C17 3.59167 16.7981 4.07918 16.4386 4.43862C16.0792 4.79807 15.5917 5 15.0833 5H13.6667V1ZM2.33333 1H13.6667V9C13.6667 9.43774 13.5804 9.87119 13.4129 10.2756C13.2454 10.68 12.9999 11.0475 12.6904 11.357C12.3808 11.6666 12.0134 11.9121 11.6089 12.0796C11.2045 12.2471 10.7711 12.3333 10.3333 12.3333H5.66667C4.78261 12.3333 3.93477 11.9821 3.30964 11.357C2.68452 10.7319 2.33333 9.88406 2.33333 9V1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </Icon>
  )
}

export default FuIconForumDiscussion
