import { Dispatch } from "redux"
import {
  threadCommentDeleteService,
  ResThreadCommentDelete
} from "fu/services/thread"
import { RootState } from "fu/stores"

const STATE_BEGIN = "THREAD_COMMENT_DELETE_BEGIN"
const STATE_END = "THREAD_COMMENT_DELETE_END"

interface PartialReqThreadCommentDelete {
  id_comment_thread: string
}

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResThreadCommentDelete
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: PartialReqThreadCommentDelete) => async (
  dispatch: Dispatch, getState: () => RootState
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const { user: { id_user } } = getState().auth.authenticate

    const response = await threadCommentDeleteService({ ...body, id_user })
    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
