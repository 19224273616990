import { IBanner } from "fu/models/banner"
import { StateActions } from "fu/stores/actions/static/listBanner"

type State = {
  loading: boolean
  banners: Array<IBanner>
  error: any
}

const initialState: State = {
  loading: true,
  banners: [],
  error: null
}

const listBannerReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_BANNER_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_BANNER_SUCCESS":
      return {
        ...state,
        loading: false,
        banners: action.payload.banner
      }
    case "LIST_BANNER_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listBannerReducer