import React, { FC, useState, useEffect, useMemo, Fragment, useCallback } from "react"
import styled from "styled-components"
import { FuIconArrowLeft, FuIconFolderLarge } from "fu/components/FuIcon"
import { useLocation, useParams, useNavigate } from "react-router-dom"
import { IFileManager } from "fu/models/filemanager"
import { FuNotFound } from "fu/components/FuNotFound"
import { FuModal } from "fu/components/FuModal"
import { FuLoader } from "fu/components/FuLoader"
import listFileManager from "fu/stores/actions/filemanager/listFileManager"
import { handleToast } from "fu/utils/toast"
import moveFileManager from "fu/stores/actions/filemanager/moveFileManager"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import moment from "moment"
import { useAppDispatch } from "fu/stores"
import { useSelector } from "react-redux"
import { AppState } from "fu/stores/reducers"
import { useTranslation } from "react-i18next"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  background: ${({ theme }) => theme.white};
  padding: 14px 0;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow-y: auto;
  
  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  user-select: none;
  padding: 0 18px;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 18px;
`

const CurrentPathContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const CurrentPath = styled.p`
  font-size: 18px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme  }) => theme.primaryMain};
  margin-left: 20px;
`

const ChooseButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primaryMain};
  padding: 8px 14px;
  border-radius: 12px;
`

const ChooseButtonText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Regular, sans-serif;
`

const FileListContainer = styled.div`
  overflow-y: scroll;
`

const FileListItemBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 22px;
  margin-top: 10px;
`

const FileListItemBackText = styled.p`
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
  font-size: 11px;
`

const FileListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  cursor: pointer;
`

const FileListItemIconContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const FileListItemRightContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const FileListItemName = styled.p`
  font-size: 15px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-bottom: -6px;
`

const FileListItemUploadedBy = styled.span`
  font-size: 10px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
`

const FileListItemUploadedByName = styled(FileListItemUploadedBy)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.primaryMain};
`

const FileListItemDate = styled.p`
  font-size: 10px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
`

const ViewFileManagerMove: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { slug } = useParams()
  const dispatch = useAppDispatch()
  const { state: { file = { } } } = useLocation()
  const [currentFileManagerPath, setCurrentFileManagerPath] = useState<string>("/")
  const { fileManagers, loading: loadingFileManagers } = useSelector((state: AppState) => state.fileManager.listFileManager)

  useEffect(() => {
    dispatch(listFileManager({
      id_community: slug || "",
      path: currentFileManagerPath
    }))
  }, [currentFileManagerPath])

  const handlePageBack = () => {
    navigate(-1)
  }

  const renderLoaderModal = useMemo(() => (
    loadingFileManagers
  ) && (
    <FuModal
      isOpen={loadingFileManagers}
      onClose={() => {}}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loadingFileManagers])

  const handleBackFileManager = useCallback(() => {
    let lastIndexOfSlash = currentFileManagerPath.slice(0, currentFileManagerPath.length - 1).lastIndexOf("/")
    setCurrentFileManagerPath(`${currentFileManagerPath.slice(0, lastIndexOfSlash)}/`)
  }, [currentFileManagerPath])


  const handleClickFolderListItem = useCallback((file: IFileManager) => () => {
    setCurrentFileManagerPath(`${currentFileManagerPath}${file.path.slice(file.path.lastIndexOf('/') + 1)}/`)
  }, [currentFileManagerPath])

  const handleChoose = useCallback(async () => {
    const result = await dispatch(moveFileManager({
      action: "move_file",
      id_community: slug,
      origin_path: file.path.slice(file.path.slice(1).indexOf("/") + 1),
      destination_path: currentFileManagerPath + file.path.slice(file.path.lastIndexOf("/") + 1)
    }))

    if (result.isSuccess) {
      navigate(-1)
      handleToast("success", result.message || `${file.type === "folder" ? "Folder" : "File"} berhasil dipindahkan`)
    } else {
      handleToast("error", result.message)
    }
  }, [slug, file, currentFileManagerPath])

  const renderFileList = useMemo(() => !loadingFileManagers && (
    <FileListContainer>
      {currentFileManagerPath !== "/" && (
        <FileListItemBackContainer onClick={handleBackFileManager}>
          <FileListItemBackText>Kembali</FileListItemBackText>
        </FileListItemBackContainer>
      )}
      {!fileManagers?.data.length ? (
        <FuNotFound text={t("communityTimeline.noFile")}/>
      ) : (
        <Fragment>
          {fileManagers?.data.filter(data => data.type === "folder" && data.path !== file.path).map((file, index) => (
            <FileListItemContainer key={index} onClick={handleClickFolderListItem(file)}>
              <FileListItemIconContainer>
                <FuIconFolderLarge />
              </FileListItemIconContainer>
              <FileListItemRightContainer>
                <div>
                  <FileListItemName>{file?.name}</FileListItemName>
                  <FileListItemUploadedBy>
                    {t("communityTimeline.fileManagerUploadedBy")}{' '}
                    <FileListItemUploadedByName>
                      {file.meta?.user_name || ""}
                    </FileListItemUploadedByName>
                    <FileListItemDate>
                      {moment(file?.meta?.last_modified).format('DD MMM YYYY')}
                    </FileListItemDate>
                  </FileListItemUploadedBy>
                </div>
              </FileListItemRightContainer>
            </FileListItemContainer>
          ))}
        </Fragment>
      )}
    </FileListContainer>
  ), [fileManagers, loadingFileManagers, currentFileManagerPath, handleBackFileManager])

  return (
    <Container>
      <Content>
        <Header>
          <IconHeader onClick={handlePageBack}>
            <FuIconArrowLeft />
          </IconHeader>
          <TextHeader>{t("fileManagerMove.title")}</TextHeader>
        </Header>
      </Content>
      <ContentScroll>
        {loadingFileManagers ? renderLoaderModal : (
          <Fragment>
            <TopContainer>
              <CurrentPathContainer>
                <CurrentPath>
                  {fileManagers?.current_path.slice(1, fileManagers?.current_path.length - 1)}
                </CurrentPath>
              </CurrentPathContainer>
              <ChooseButton onClick={handleChoose}>
                <ChooseButtonText>{t("fileManagerMove.fileChoose")}</ChooseButtonText>
              </ChooseButton>
            </TopContainer>
            {renderFileList}
          </Fragment>
        )}
      </ContentScroll>
    </Container>
  )
}

export default ViewFileManagerMove
