import { FuIconArrowLeft, FuIconAdd, FuIconBgCheckmark } from "fu/components/FuIcon"
import { FuImagePicker } from "fu/components/FuImagePicker"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import { FuModal } from "fu/components/FuModal"
import { FuInputLabel } from "fu/components/FuInput"
import { FuImage } from "fu/components/FuImage"
import { FuLoader } from "fu/components/FuLoader"
import { FuIconDropdown, FuIconCross } from "fu/components/FuIcon"
import { CommunityAddForm } from "fu/models/community"
import { handleToast } from "fu/utils/toast"
import { useAppDispatch } from "fu/stores"
import { AppState } from "fu/stores/reducers"
import listTopic from "fu/stores/actions/static/listTopic"
import createCommunity from "fu/stores/actions/community/createCommunity"
import updateCommunity from "fu/stores/actions/community/updateCommunity"
import React, { FC, useState, useCallback, useMemo, Fragment, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  background: ${({ theme }) => theme.white};
  padding: 14px 0;
  padding-bottom: 20px;
  overflow-y: scroll;

  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
`
const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  user-select: none;
  padding: 0 18px;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 26px 24px;
  background: ${({ theme }) => theme.white};
  border-radius: 12px 12px 0 0;
`

const InputSpacer = styled.div`
  margin: 6px 0 6px 0;
`

const AgreementTitleText = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.greyDark};
`

const AgreementInput = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.greyBorder};
  border-radius: 10px;
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 20px 0 20px;
`

const AgreementInputText = styled.span`
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
`

const AgreementDropdownIcon = styled.div`
  padding-top: 8px;
`

const AgrementDropdownChoice = styled.p`
  margin: 15px 0 10px 0;
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
  cursor: pointer;
`

const TopicTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const TopicTitleText = styled.span`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
  margin-right: 8px;
`

const CategoryList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(67px, 1fr));
  grid-gap: 12px;
  margin-top: 24px;
  overflow-y: scroll;
  height: 75%;
`
const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`

const CategoryListTitle = styled.p`
  font-size: 18px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.primaryMain};
  text-align: center;
`

const CategoryListDesc = styled.p`
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
  text-align: center;
  margin-top: 4px;
`

const CategoryItemImage = styled(FuImage)`
  width: 60px;
  height: 60px;
`

const CategoryItemText = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
  text-align: center;
  word-break: break-word;
`

const CategoryCheckContainer = styled.div`
  position: absolute;
  z-index: 20
`

const ChosenTopicContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  flex-wrap: wrap;
`

const ChosenTopic = styled.div`
  display: flex;
  padding: 6px 16px 6px 16px;
  border-radius: 12px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.lightMain};
  margin-bottom: 8px;
`

const ChosenTopicText = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.primaryMain};
`

const ChosenTopicCloseBtn = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  margin-left: 14px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

const CloseContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`

const topicCustomStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '600px',
    height: '60%',
    overflow: "hidden"
  }
}

const agreementCustomStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '360px'
  }
}

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const inputTitle = { name: "title" }

const inputDescription = { name: "description" }

const ViewCommunityAdd: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { state = {} } = useLocation()
  const { topics } = useSelector((state: AppState) => state.static.listTopic)
  const { loading } = useSelector((state: AppState) => state.community.createCommunity)
  const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false)
  const [showTopicModal, setShowTopicModal] = useState<boolean>(false)
  const [form, setForm] = useState<CommunityAddForm>({
    image: null,
    title: state?.isEdit ? state?.detail?.title : "",
    description: state?.isEdit ? state?.detail?.description : "",
    join_need_approval: state?.isEdit ? (state?.detail?.join_need_approval === "false" ? false : true) : false,
    topics: state?.detail ? (state?.detail?.topics?.map((topic: any) => topic.id_topic).join(',')) : ""
  })

  useEffect(() => {
    if (!topics.length) {
      dispatch(listTopic())
    }
  }, [])

  const handleShowAgreementModal = useCallback(() => {
    setShowAgreementModal(!showAgreementModal)
  }, [showAgreementModal])

  const handleShowTopicModal = useCallback(() => {
    setShowTopicModal(!showTopicModal)
  }, [showTopicModal])

  const handleChangeInput = useCallback((
    name: string, value: string | boolean | File | null
  ) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [setForm])

  const handlePageBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onPickImage = useCallback((image: File | null) => {
    handleChangeInput("image", image)
  }, [handleChangeInput])

  const handleConfirmAgreement = useCallback((value: boolean) => () => {
    handleChangeInput("join_need_approval", value)
    handleShowAgreementModal()
  }, [handleChangeInput, handleShowAgreementModal])

  const handleChooseTopic = useCallback((value: string) => () => {
    let topics = form.topics.split(",").filter(topic => !!topic.length)

    if (topics.includes(value)) {
      topics.splice(topics.indexOf(value), 1)
    } else if (topics.length === 3) {
      handleToast("error", t("communityAdd.toastErrorChooseTopic"))
    } else {
      topics.push(value)
    }

    handleChangeInput("topics", topics.join(","))
  }, [form.topics, handleChangeInput])

  const isChosenTopic = useCallback((topic_id: string) => {
    return form.topics.split(",").includes(topic_id)
  }, [form.topics])

  const chosenTopics = useMemo(() => {
    const filteredTopics = form.topics.split(",").filter(topic => !!topic.length)

    let result = []
    for (let i = 0; i < filteredTopics.length; i++) {
      result.push(topics.find(topic => topic.id_topic === filteredTopics[i]))
    }

    return result
  }, [form.topics, topics])

  const renderAgreementInput = useMemo(() => (
    <InputSpacer>
      <AgreementTitleText>
        {t("communityAdd.formAgreement")}
      </AgreementTitleText>
      <AgreementInput onClick={handleShowAgreementModal}>
        <AgreementInputText>
          {
            t(
              form.join_need_approval
              ? "form.confirmationYes"
              : "form.confirmationNo"
            )
          }
        </AgreementInputText>
        <AgreementDropdownIcon>
          <FuIconDropdown />
        </AgreementDropdownIcon>
      </AgreementInput>
    </InputSpacer>
  ), [form.join_need_approval, handleShowAgreementModal])

  const renderAgreementModal = useMemo(() => showAgreementModal && (
    <FuModal
      isOpen={showAgreementModal}
      onClose={handleShowAgreementModal}
      customStyles={agreementCustomStyles}
      children={
        <Fragment>
          <p>{t("communityAdd.formAgreement")}</p>
          <AgrementDropdownChoice onClick={handleConfirmAgreement(true)}>
            {t("form.confirmationYes")}
          </AgrementDropdownChoice>
          <AgrementDropdownChoice onClick={handleConfirmAgreement(false)}>
            {t("form.confirmationNo")}
          </AgrementDropdownChoice>
        </Fragment>
      }
    />
  ), [showAgreementModal, handleShowAgreementModal, handleConfirmAgreement])

  const renderTopics = useMemo(() => (
    <InputSpacer>
      <TopicTitleContainer onClick={handleShowTopicModal}>
        <TopicTitleText>
          {t("communityAdd.formTopic")}
        </TopicTitleText>
        <FuIconAdd color={"primaryMain"} />
      </TopicTitleContainer>
      <ChosenTopicContainer>
        {chosenTopics.map((topic, index) => (
          <ChosenTopic key={index}>
            <ChosenTopicText>
              {topic?.title}
            </ChosenTopicText>
            <ChosenTopicCloseBtn
              onClick={handleChooseTopic(topic?.id_topic || "")}
            >
              <FuIconCross width={"8"} height={"8"} color="primaryMain" />
            </ChosenTopicCloseBtn>
          </ChosenTopic>
        ))}
      </ChosenTopicContainer>
    </InputSpacer>
  ), [handleShowTopicModal, chosenTopics])

  const renderChooseTopicModal = useMemo(() => showTopicModal && (
    <FuModal
      isOpen={showTopicModal}
      customStyles={topicCustomStyles}
      onClose={handleShowTopicModal}
      children={
        <Fragment>
          <div >
            <CloseContainer onClick={handleShowTopicModal}>
              <FuIconCross color="primaryMain" width="20" height="20" />
            </CloseContainer>
            <CategoryListTitle>
              {t("communityAdd.formTopicChooseTitle")}
            </CategoryListTitle>
            <CategoryListDesc>
              {t("communityAdd.formTopicChooseDesc")}
            </CategoryListDesc>
          </div>
          <CategoryList>
            {topics.map((topic, index) => (
              <CategoryItem key={index} onClick={handleChooseTopic(topic.id_topic)}>
                <div style={{display: "flex", alignItems: "center", justifyContent: 'center',}}>
                  <CategoryItemImage
                    source={`upload/topic/${topic.icon}`}
                    alt={topic.title}
                  />
                </div>
                {isChosenTopic(topic.id_topic) && (
                  <CategoryCheckContainer>
                    <FuIconBgCheckmark size={"72"} />
                  </CategoryCheckContainer>
                )}
                <CategoryItemText>
                  {topic.title}
                </CategoryItemText>
              </CategoryItem>
            ))}
          </CategoryList>
        </Fragment>
      }
    />
  ), [
    showTopicModal,
    handleShowTopicModal,
    handleChooseTopic,
    isChosenTopic
  ])

  const renderLoaderModal = useMemo(() => loading && (
    <FuModal
      isOpen={loading}
      onClose={() => {}}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loading])

  const handleValidate = useCallback(() => {
    const { title, description, image, topics } = form
    let errorMessage = ""

    if (!image && !state?.isEdit) {
      errorMessage = t("communityAdd.toastErrorFormImage")
    } else if (!title.length) {
      errorMessage = t("communityAdd.toastErrorFormTitle")
    } else if (!description.length) {
      errorMessage = t("communityAdd.toastErrorFormDescription")
    } else if (!topics.length) {
      errorMessage = t("communityAdd.toastErrorFormTopic")
    }

    if (errorMessage.length) return handleToast("error", errorMessage)
    return true
  }, [form, state])

  const handleSubmit = useCallback(async () => {
    const validation = handleValidate()

    if (validation) {
      const formData: any = new FormData()
      for (const field in form) {
        formData.append(field, form[field as keyof CommunityAddForm])
      }

      if (state?.isEdit) {
        formData.append("id_community", state?.detail?.id_community)
        if (!form.image) {
          formData.delete("image")
        }
      }

      const result =
        state?.isEdit ?
        await dispatch(updateCommunity(formData)) :
        await dispatch(createCommunity(formData))
      
      if (result.isSuccess) {
        navigate(-1)
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }
    }
  }, [handleValidate, form, state])

  return (
    <Container>
      <ContentScroll>
        <Header>
          <IconHeader onClick={handlePageBack}>
            <FuIconArrowLeft />
          </IconHeader>
          <TextHeader>
            {state?.isEdit ? t("communityEdit.title") : t("communityAdd.title")}
          </TextHeader>
        </Header>
        <Form>
          <FuImagePicker
            savedImg={state?.isEdit ? state?.detail?.image : null}
            onPickImg={onPickImage}
          />
          <InputSpacer>
            <FuInputLabel
              input={inputTitle}
              type={"text"}
              label={t("communityAdd.formTitle")}
              placeholder={t("communityAdd.formTitlePlaceholder")}
              value={form.title}
              onChange={handleChangeInput}
            />
          </InputSpacer>
          <InputSpacer>
            <FuInputLabel
              isTextArea
              input={inputDescription}
              type={"text"}
              label={t("communityAdd.formDescription")}
              placeholder={t("communityAdd.formDescriptionPlaceholder")}
              value={form.description}
              onChange={handleChangeInput}
            />
          </InputSpacer>
          {renderAgreementInput}
          {renderTopics}
          <FuButton
            button={mainButton}
            text={
              state?.isEdit ? 
              t("communityEdit.formEditButton") :
              t("communityAdd.formAddButton")
            }
            onClick={handleSubmit}
          />
        </Form>
      </ContentScroll>
      {renderAgreementModal}
      {renderChooseTopicModal}
      {renderLoaderModal}
    </Container>
  )
}

export default ViewCommunityAdd
