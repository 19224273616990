import {
  FuIconArrowLeft,
  FuIconTimeline,
  FuIconForumDiscussion,
  FuIconMembers,
  FuIconFileManagement,
  FuIconGroupInfo,
  FuIconEdit,
  FuIconMenu,
  FuIconChat,
  FuIconWarning,
  FuIconTrash,
  FuIconEditPost,
  FuIconAddCircle,
  FuIconFile,
  FuIconFolder,
  FuIconFileLarge,
  FuIconFolderLarge,
  FuIconDownload,
  FuIconMoveFolder,
  FuIconCrossCircle,
  FuIconCheckCircle,
  FuIconBackDirectory
} from "fu/components/FuIcon"
import { FuInputLabel, FuInput } from "fu/components/FuInput"
import { IMAGE_URL } from "fu/constants/Environment"
import { IFileManager } from "fu/models/filemanager"
import { AVATAR_URL } from "fu/constants/Environment"
import { FuNotFound } from "fu/components/FuNotFound"
import { FuModal } from "fu/components/FuModal"
import { CommunityDetail, CommunityMember } from "fu/models/community"
import { FuLoader } from "fu/components/FuLoader"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { Paths } from "fu/routes/paths"
import { FuImage } from "fu/components/FuImage"
import { handleToast } from "fu/utils/toast"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import { useAppDispatch } from "fu/stores"
import listTimeline from "fu/stores/actions/timeline/listTimeline"
import listUserJoinRequest from "fu/stores/actions/community/listUserJoinRequest"
import actionUserJoinRequest from "fu/stores/actions/community/actionUserJoinRequest"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import createTimeline from "fu/stores/actions/timeline/createTimeline"
import updateTimeline from "fu/stores/actions/timeline/updateTimeline"
import deleteTimeline from "fu/stores/actions/timeline/deleteTimeline"
import listFileManager from "fu/stores/actions/filemanager/listFileManager"
import deleteFileManager from "fu/stores/actions/filemanager/deleteFileManager"
import detailCommunity from "fu/stores/actions/community/detailCommunity"
import moveFileManager from "fu/stores/actions/filemanager/moveFileManager"
import listThread from "fu/stores/actions/thread/listThread"
import deleteThread from "fu/stores/actions/thread/deleteThread"
import uploadFileManager from "fu/stores/actions/filemanager/uploadFileManager"
import createFileManager from "fu/stores/actions/filemanager/createFileManager"
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState, ChangeEvent, useRef, UIEvent, useContext } from "react"
import useDownloader from 'react-use-downloader';
import styled from "styled-components"
import moment from "moment";
import { useSelector } from "react-redux"
import { useNavigate, useLocation, useParams, generatePath } from "react-router-dom"
import useOutsideClick from "fu/utils/useOutsideClick"
import { DefaultTheme } from "styled-components"
import { Trans, useTranslation } from "react-i18next"
import { AppState } from "fu/stores/reducers"
import { IUser } from "fu/models/user"
import { IThread } from "fu/models/thread"
import { ITimeline } from "fu/models/timeline"
import { FuPointReward } from "fu/components/FuPointReward"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TopContainer = styled.div`
  max-width: 670px;
  display: flex;
  flex-direction: column;
  position: sticky;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`

const TopHeader = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.primaryMain};
  width: 100%;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const HeaderGreetingsContainer = styled(RowContainer)`
  justify-content: space-between;
  margin-top: 24px;
`

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const HeaderTitle = styled.p`
  color: ${({ theme }) => theme.white};
  font-family: Poppins-Medium, sans-serif;
  margin-left: 24px;
  font-size: 16px;
`

const HeaderGreetingTitle = styled.p`
  color: ${({ theme }) => theme.white};
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
`

const HeaderGreetingDesc = styled.p`
  color: ${({ theme }) => theme.white};
  font-family: Poppins-Light, sans-serif;
  font-size: 14px;
  margin-top: 4px;
`

const HeaderUserLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderUserLogo = styled(FuImage)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`

const HeaderGreetingContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const TabContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  padding: 12px 0px 2px 0px;
  display: flex;
  flex-direction: row;
`

const TabMenuContainer = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const TabMenuLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.iconDark};
`

const TabMenuHiddenLine = styled.div`
  width: 5%;
  height: 2px;
`

const MenuTitle = styled.p`
  font-family: Poppins-Regular, sans-serif;
  font-size: 11px;
  color: ${({ theme }) => theme.greyLight};
  padding: 20px;
`

const BottomBtnContainer = styled.div`
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 250px;
  align-items: center;
  align-self: center;
`

const BottomBtn = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.primaryMain};
  border-radius: 20px;
  padding: 6px 0px;
  flex-direction: row;
  display: flex;
`

const BottomBtnIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-left: auto;
`

const BottomBtnText = styled.p`
  font-size: 15px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.white};
  margin-right: auto;
`

const AddNewTimelineTitle = styled.p`
  font-size: 16px;
  font-family: Poppins-Medium, sans-serif;
  text-align: center;
  color: ${({ theme }) => theme.greyLabel};
  margin-bottom: 12px;
`

const AddNewTimelineTextTnc = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Poppins-Regular, sans-serif;
  margin-top: 8px;
  margin-bottom: 20px;
`

const AddNewTimelineTextTncActive = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Medium, sans-serif;
  cursor: pointer;
`

const TimelineListContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
`

const FileListContainer = styled(TimelineListContainer)`
  height: 100%;
`

const ThreadPost = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  padding: 10px 25px;
  margin: 10px;
  cursor: pointer;
`

const TimelinePost = styled(ThreadPost)`
  display: flex;
  padding: 10px;
`

const TimelinePostPhoto = styled(FuImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
`

const TimelinePostRight = styled.div`
  width: 100%;
`

const TimelinePostTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ThreadPostTop = styled(TimelinePostTop)`
  margin-top: 10px;
`

const TimelinePostName = styled.p`
  font-size: 12px;
  font-family: Poppins-Bold, sans-serif;
  color: ${({ theme }) => theme.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
`

const ThreadPostDate = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
`

const ThreadPostName = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
`

const TimelinePostDate = styled.p`
  font-size: 10px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
`

const TimelinePostContent = styled.p`
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyDark};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TimelinePostCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
`

const TimelinePostMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  width: 150px;
  padding: 12px;
  padding-bottom: 0px;
  border-radius: 10px;
  left: -150px;
  top: 15px;
  box-shadow: 0 0 2px 2px #ccc;
`

const TimelinePostMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 14px;
`

const TimelinePostMenuContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FileManagerMenuContainerIcon = styled(TimelinePostMenuContainerIcon)`
  width: 20px;
`

const TimelinePostMenuText = styled.p`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-left: 6px;
`

const TimelinePostMenuIcon = styled.div`
  position: sticky;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
`

const TimelinePostCommentText = styled.p`
  margin-left: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Poppins-Medium, sans-serif;
`

const ThreadPostTopMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
`

const ThreadPostTopMenuIcon = styled.div`
  position: sticky;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
`

const MemberContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-bottom: 12px;
  border-bottom: 1px solid #dddddd;
`

const MemberLeftContainer = styled.div`
  flex-direction: row;
  display: flex;
`

const MemberPhoto = styled(FuImage)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
`

const MemberVerticalContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const MemberName = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.black};
  margin-right: 12px;
`

const MemberNameContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const MemberBio = styled.p`
  font-family: Poppins-Regular, sans-serif;
  font-size: 11px;
  color: ${({ theme }) => theme.greyDark};
  margin-top: 4px;
`

const GroupInfoLogo = styled(FuImage)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 12px auto;
  display: flex;
  align-items: center;
`

const GroupInfoName = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.greyLabel};
  margin-bottom: 12px;
`

const GroupInfoCreatedBy = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.black};
  margin-bottom: 8px;
`

const GroupInfoCreatedByName = styled.p`
  font-family: Poppins-Regular, sans-serif;
  font-size: 10px;
  text-align: center;
  color: ${({ theme }) => theme.greyDark};
  margin-bottom: 24px;
`

const GroupInfoDescTitle = styled(GroupInfoCreatedBy)`
  margin-bottom: 16px;
  text-align: left;
  padding-left: 18px;
  padding-right: 18px;
`

const GroupInfoDesc = styled(GroupInfoCreatedByName)`
  text-align: left;
  padding-left: 18px;
  padding-right: 18px;
`

const FileManagerTopContainer = styled.div`
  margin-top: -24px;
  padding-bottom: 12px;
  border-bottom-style: dashed;
  border-bottom-width: 0.5px;
  border-bottom-color: ${({ theme }) => theme.greyLight};
`

const AwaitingApprovalMemberContainer = styled(FileManagerTopContainer)`
  align-items: flex-end;
  align-self: flex-end;
  justify-content: flex-end;
  display: flex;
  margin-top: -10px;
  margin-left: 18px;
  margin-right: 18px;
`

const FileManagerTopButton = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
`

const FileManagerCurrentPath = styled.p`
  font-size: 18px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme  }) => theme.primaryMain};
  margin-left: 20px;
`

const FileManagerOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0;
  cursor: pointer;
`

const FileManagerOptionText = styled.label`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
`

const FileManagerOptionTextContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
`

const FileManagerInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`

const FileManagerCreateFolderInputLabel = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
  text-align: left;
`

const FileManagerCreateFolderInput = styled.div`
  margin: 16px 0;
  width: 250px;
`

const ConfirmAddFileTitle = styled.p`
  font-family: Poppins-Bold, sans-serif;
  margin-bottom: 12px;
  font-size: 16px;
`

const ConfirmAddFileDesc = styled.p`
  font-family: Poppins-Regular, sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
  max-width: 450px;
`

const ConfirmAddFileBtnContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
`

const ConfirmAddFileBtnText = styled(ConfirmAddFileDesc)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
`

const ConfirmAddFileBtnLeftText = styled(ConfirmAddFileBtnText)`
  margin-right: 20px
`

const MemberAdminContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryMain};
  border-radius: 5px;
  padding: 5px;
`
const MemberAdminText = styled.p`
  font-size: 9px;
  color: ${({ theme }) => theme.white};
`

const FileListItemBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 22px;
  margin-top: 10px;
`

const FileListItemBackText = styled.p`
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
  font-size: 11px;
  margin-left: 8px;
`

const FileListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  cursor: pointer;
`

const FileListItemIconContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const FileListItemRightContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const FileListItemName = styled.p`
  font-size: 15px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-bottom: -6px;
`

const FileListItemUploadedBy = styled.span`
  font-size: 10px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
`

const FileListItemUploadedByName = styled(FileListItemUploadedBy)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.primaryMain};
`

const FileListItemDate = styled.p`
  font-size: 10px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
`

const AwaitingApprovalMemberText = styled.p`
  font-size: 11px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.primaryMain};
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  cursor: pointer;
`

const PreviewImage = styled(FuImage)`
  max-width: 400px;
`

const UserJoinRequestContainer = styled.div`
  margin: 18px;
  display: flex;
  flex-direction: row;
`

const UserJoinRequestPhotoContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 14px;
`

const UserJoinRequestPhoto = styled(FuImage)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

const UserJoinRequestName = styled.p`
  font-size: 14px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
`

const UserJoinRequestBio = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
  margin-bottom: 8px;
`

const UserJoinRequestOptions = styled.div`
  display: flex;
  flex-direction: row;
`

const UserJoinRequestOptionText = styled.p`
  margin-left: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  font-family: Poppins-Light, sans-serif;
`

const UserJoinRequestOptionLeft = styled(UserJoinRequestOptions)`
  margin: 0 18px 0 0;
  cursor: pointer;
`

const UserJoinRequestOption = styled(UserJoinRequestOptions)`
  margin: 0;
  cursor: pointer;
`

const ConfirmRejectMemberModalTitle = styled.p`
  font-size: 13px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
  text-align: center;
  margin-bottom: 4px;
`

const ConfirmRejectMemberModalDesc = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
  text-align: center;
  margin-bottom: 8px;
`

const ConfirmRejectMemberButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
`

const ConfirmRejectMemberButton = styled.div`
  cursor: pointer;
  padding: 8px 0px;
  width: 46%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.primaryMain};
`

const ConfirmRejectMemberSecondButton = styled(ConfirmRejectMemberButton)`
  background-color: ${({ theme }) => theme.greyInput};
`

const ConfirmRejectMemberButtonText = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.white};
  font-family: Poppins-Medium, sans-serif;
  text-align: center;
`

const addNewTimelineModalCustomStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '360px'
  }
}

const previewImageStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderWidth: 0,
    borderRadius: 0
  },
  overlay: {
    backgroundColor: defaultTheme.black,
    zIndex: 999
  }
}

const tabMenus = [
  {
    id: 0,
    icon: FuIconTimeline,
    title: "communityTimeline.timeline"
  },
  {
    id: 1,
    icon: FuIconForumDiscussion,
    title: "communityTimeline.forumDiscussion"
  },
  {
    id: 2,
    icon: FuIconMembers,
    title: "communityTimeline.members"
  },
  {
    id: 3,
    icon: FuIconFileManagement,
    title: "communityTimeline.fileManagement"
  },
  {
    id: 4,
    icon: FuIconGroupInfo,
    title: "communityTimeline.groupInformation"
  }
]

const inputNewTimelinePost = { name: "content" }

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

interface NewTimelinePost {
  content: string
}

interface Props {
  handleSetBgColor: (color: keyof DefaultTheme) => void
}

const {
  PRIVACY_POLICY: { path: PrivacyPolicyPath },
  TERMS_AND_CONDITIONS: { path: TnCPath },
  FORUM_DISCUSSION_ADD: { path: ForumDiscussionsAddPath },
  FORUM_DISCUSSION_EDIT: { path: ForumDiscussionsEditPath },
  COMMUNITY_TIMELINE_POST: { path: CommunityTimelinePostPath },
  COMMUNITY_THREAD_DETAIL: { path: CommunityThreadDetailPath },
  FILE_MANAGER_MOVE: { path: FileManagerMovePath }
} = Paths

const ViewCommunityTimeline: FC<Props> = ({ handleSetBgColor }) => {
  const wrapperRef = useRef(null);
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { download, percentage } = useDownloader()
  const {
    currentMenu,
    handleSetCurrentMenu,
    showPointReward,
    handleShowPointReward,
    currentShowPoint,
    handleShowCurrentPoint,
    showPointRewardTitle,
    handleShowPointRewardTitle
  }:
    {
      currentMenu: number,
      handleSetCurrentMenu: (menu: number) => () => void,
      showPointReward: boolean,
      handleShowPointReward: () => void,
      currentShowPoint: number,
      handleShowCurrentPoint: (val: number) => void,
      showPointRewardTitle: string,
      handleShowPointRewardTitle: (val: string) => void
    } =
    useContext(GeneralContext)
  const { slug } = useParams()
  const { detail }: { detail: CommunityDetail | null } = useSelector((state: AppState) => state.community.detailCommunity)
  const { user }: { user: IUser } = useSelector((state: AppState) => state.auth.authenticate)
  const { loading: loadingCreate } = useSelector((state: AppState) => state.timeline.createTimeline)
  const { timeline, loading: loadingTimeline } = useSelector((state: AppState) => state.timeline.listTimeline)
  const { threads, loading: loadingThread }: { threads: Array<IThread>, loading: boolean } =
    useSelector((state: AppState) => state.thread.listThread)
  const { fileManagers, loading: loadingFileManagers } = useSelector((state: AppState) => state.fileManager.listFileManager)
  const { loading: loadingUploadFile } = useSelector((state: AppState) => state.fileManager.uploadFileManager)
  const { loading: loadingUserJoinRequest, data: dataUserJoinRequest } = useSelector((state: AppState) => state.community.listUserJoinRequest)
  const [currentFileManagerPath, setCurrentFileManagerPath] = useState<string>("/")
  const [currentTimelinePage, setCurrentTimelinePage] = useState<number>(1)
  const [currentThreadPage, setCurrentThreadPage] = useState<number>(1)
  const [showAddNewTimeline, setShowAddNewTimeline] = useState<boolean>(false)
  const [timelineIdFeed, setTimelineIdFeed] = useState<string | null>(null)
  const [timelineIdFeedDelete, setTimelineIdFeedDelete] = useState<string | null>(null)
  const [threadIdDelete, setThreadIdDelete] = useState<string | null>(null)
  const [filePathDelete, setFilePathDelete] = useState<string | null>(null)
  const [fileTypeDelete, setFileTypeDelete] = useState<"folder" | "file">("file")
  const [newTimelinePost, setNewTimelinePost] = useState<NewTimelinePost>({
    content: ""
  })
  const [showTimelinePostMenu, setShowTimelinePostMenu] = useState<string | null>(null)
  const [showFileManagerMenu, setShowFileManagerMenu] = useState<string | null>(null)
  const [showThreadPostMenu, setShowThreadPostMenu] = useState<string | null>(null)
  const [showAddFileModal, setShowAddFileModal] = useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [showConfirmAddFileModal, setShowConfirmAddFileModal] = useState<boolean>(false)
  const [showConfirmDeleteTimelineModal, setShowConfirmDeleteTimelineModal] = useState<boolean>(false)
  const [showConfirmDeleteThreadModal, setShowConfirmDeleteThreadModal] = useState<boolean>(false)
  const [showConfirmDeleteFileModal, setShowConfirmDeleteFileModal] = useState<boolean>(false)
  const [showCreateFolderModal, setShowCreateFolderModal] = useState<boolean>(false)
  const [createFolderName, setCreateFolderName] = useState<string>("")
  const [showFileManagerPreviewImage, setShowFileManagerPreviewImage] = useState<string>("")
  const [loadingDownloadFile, setLoadingDownloadFile] = useState<boolean>(false)
  const [showEditFileModal, setShowEditFileModal] = useState<"file" | "folder" | null>(null)
  const [originEditFileName, setOriginEditFileName] = useState<string>("")
  const [editFileName, setEditFileName] = useState<string>("")
  const [showMemberList, setShowMemberList] = useState<boolean>(false)
  const [currentMemberListPage, setCurrentMemberListPage] = useState<number>(1)
  const [showConfirmApproveMemberModal, setShowConfirmApproveMemberModal] = useState<CommunityMember | null>(null)
  const [showConfirmRejectMemberModal, setShowConfirmRejectMemberModal] = useState<CommunityMember | null>(null)
  const [reasonRejectMember, setReasonRejectMember] = useState<string>("")
  useOutsideClick(wrapperRef, () => {
    setShowTimelinePostMenu(null)
    setShowThreadPostMenu(null)
    setShowFileManagerMenu(null)
  })

  const handleShowConfirmRejectMemberModal = useCallback((user?: CommunityMember) => () => {
    setShowConfirmRejectMemberModal(user || null)
  }, [showConfirmRejectMemberModal])

  const handleOnChangeReasonRejectMember = (_: string, value: string) => {
    setReasonRejectMember(value)
  }

  const handleShowConfirmApproveMemberModal = useCallback((user?: CommunityMember) => () => {
    setShowConfirmApproveMemberModal(user || null)
  }, [showConfirmApproveMemberModal])

  useEffect(() => {
    dispatch(detailCommunity({ id_community: slug || "" }))
  }, [slug])

  useEffect(() => {
    if (showMemberList) {
      dispatch(listUserJoinRequest({
        id_user: user.id_user,
        id_community: slug || "",
        limit: 10,
        current_page: currentMemberListPage
      }))
    }
  }, [showMemberList, user.id_user, slug, currentMemberListPage])

  const handleShowMemberList = useCallback(() => {
    setShowMemberList(!showMemberList)
  }, [showMemberList])

  const handleShowEditFileModal = (type?: "file" | "folder", name?: string) => () => {
    setShowFileManagerMenu(null)
    setShowEditFileModal(type || null)
    setOriginEditFileName(name || "")
    setEditFileName(name ? name.split(".")[0] : "")
  }

  const handleChangeEditFileName = (_: string, name: string) => {
    setEditFileName(name)
  }

  const handleShowFileManagerPreviewImage = useCallback((itemId?: string) => () => {
    if (itemId) return setShowFileManagerPreviewImage(itemId)

    setShowFileManagerPreviewImage("")
  }, [])

  const handleChangeCreateFolderName = useCallback((_: string, value: string) => {
    setCreateFolderName(value)
  }, [])

  const handleShowConfirmDeleteThreadModal = useCallback((id_thread?: string | null) => () => {
    setThreadIdDelete(id_thread || null)
    setShowConfirmDeleteThreadModal(!showConfirmDeleteThreadModal)
  }, [showConfirmDeleteThreadModal])

  const handleShowConfirmDeleteTimelineModal = useCallback((id_feed?: string | null) => () => {
    setTimelineIdFeedDelete(id_feed || null)
    setShowConfirmDeleteTimelineModal(!showConfirmDeleteTimelineModal)
  }, [showConfirmDeleteTimelineModal])

  const handleShowConfirmDeleteFileModal = useCallback((file_name?: string | null, type?: "file" | "folder") => () => {
    setFilePathDelete(file_name || null)
    setFileTypeDelete(type || "file")
    setShowConfirmDeleteFileModal(!showConfirmDeleteFileModal)
  }, [showConfirmDeleteFileModal])

  const handleShowCreateFolderModal = useCallback(() => {
    setShowAddFileModal(false)
    setShowCreateFolderModal(!showCreateFolderModal)
  }, [showCreateFolderModal])

  const handleScroll = useCallback(
    ({ currentTarget }: UIEvent<HTMLDivElement>
  ) => {
    const bottom =
      Math.ceil(currentTarget.scrollHeight - currentTarget.scrollTop)
        === currentTarget.clientHeight;
    if (bottom) {
      if (timeline.length === (currentTimelinePage * 10) && currentMenu === 0) {
        setCurrentTimelinePage(currentTimelinePage + 1)
      } else if (threads.length === (currentThreadPage * 10) && currentMenu === 1) {
        setCurrentThreadPage(currentThreadPage + 1)
      }
    }
  }, [currentTimelinePage, timeline.length, currentMenu, currentThreadPage, threads.length])

  const handleConfirmUploadFile = useCallback(async () => {
    setShowConfirmAddFileModal(false)

    const formData: any = new FormData()
    formData.append("id_community", slug || "")
    formData.append("path", currentFileManagerPath)
    formData.append("file", uploadedFile)

    const result = await dispatch(uploadFileManager(formData))
      
    if (result.isSuccess) {
      await dispatch(listFileManager({
        id_community: slug || "", path: currentFileManagerPath
      }))
      handleToast("success", result.message)
    } else {
      handleToast("error", result.message)
    }
  }, [slug, uploadedFile, currentFileManagerPath])

  const handleOnChangeUploadFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null

    setUploadedFile(file)
    setShowAddFileModal(false)
    setShowConfirmAddFileModal(true)
  }, [])

  const handleShowConfirmAddFileModal = useCallback(() => {
    setShowConfirmAddFileModal(!showConfirmAddFileModal)
  }, [showConfirmAddFileModal])

  const handleShowAddFileModal = useCallback(() => {
    setShowAddFileModal(!showAddFileModal)
  }, [showAddFileModal])

  const handleShowTimelinePostMenu = useCallback((idx?: string) => (e: any) => {
    e.stopPropagation()
    setShowTimelinePostMenu(idx || null)
  }, [])

  const handleShowFileManagerMenu = useCallback((idx?: string) => (e: any) => {
    e.stopPropagation()
    setShowFileManagerMenu(idx || null)
  }, [])

  const handleShowThreadPostMenu = useCallback((idx?: string) => (e: any) => {
    e.stopPropagation()
    setShowThreadPostMenu(idx || null)
  }, [])

  const handleAddNewThread = () => {
    const combinedPath =
      generatePath(ForumDiscussionsAddPath, { slug: detail?.id_community })

    navigate(combinedPath, { state: { detail }})
  }

  const handleChangeInput = useCallback((
    name: string, value: string
  ) => {
    setNewTimelinePost(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [setNewTimelinePost])

  useEffect(() => {
    handleSetBgColor("lightMain")

    return () => {
      handleSetBgColor("white")
    }
  }, [])

  useEffect(() => {
    if (currentMenu === 0) {
      dispatch(listTimeline({
        id_community: slug || "",
        limit: 10,
        current_page: currentTimelinePage
      }))
    } else if (currentMenu === 1) {
      dispatch(listThread({
        id_community: slug || "",
        limit: 10,
        current_page: currentThreadPage
      }))
    } else if (currentMenu === 2) {
      dispatch(detailCommunity({ id_community: slug || "" }))
      dispatch(listUserJoinRequest({
        id_user: user.id_user,
        id_community: slug || "",
        limit: 10,
        current_page: currentMemberListPage
      }))
    } else if (currentMenu === 3) {
      dispatch(listFileManager({
        id_community: slug || "",
        path: currentFileManagerPath
      }))
    }
  }, [user, slug, currentMemberListPage, currentTimelinePage, currentMenu, currentThreadPage, currentFileManagerPath])

  const handleShowAddNewTimeline = useCallback(() => {
    setShowAddNewTimeline(!showAddNewTimeline)
    setTimelineIdFeed(null)
  }, [showAddNewTimeline])

  const handleNavigateBack = useCallback(() => {
    navigate(-1)
  }, [])

  const handleNavigateTermsAndPolicy = useCallback((route: string) => () => {
    navigate(route);
  }, [navigate])

  const handleAddNewTimeline = useCallback(async () => {
    if (!newTimelinePost.content.length) {
      handleToast("error", t("communityTimeline.addTimelineModal.error"))
    } else {
      setShowAddNewTimeline(false)

      const result = timelineIdFeed
        ? await dispatch(updateTimeline({
          id_feed: timelineIdFeed,
          content: newTimelinePost.content
        }))
        : await dispatch(createTimeline({
          id_community: slug || "",
          content: newTimelinePost.content
        }))

      setTimelineIdFeed(null)
      setNewTimelinePost({
        content: ""
      })
      if (result.isSuccess) {
        setCurrentTimelinePage(1)
        await dispatch(listTimeline({
          id_community: slug || "", limit: 10, current_page: 1
        }))
        handleToast("success", result.message)
        if (!timelineIdFeed) {
          handleShowCurrentPoint(result.points.poin.point)
          handleShowPointRewardTitle(result.points.poin.point_activity)
          handleShowPointReward()
        }
      } else {
        handleToast("error", result.message)
      }
    }
  }, [newTimelinePost, slug, setShowAddNewTimeline, timelineIdFeed])

  const renderHeader = useMemo(() => (
    <TopHeader>
      <RowContainer>
        <BackButton onClick={handleNavigateBack}>
          <FuIconArrowLeft
            color="white"
          />
        </BackButton>
        <HeaderTitle>
          {detail?.title}
        </HeaderTitle>
      </RowContainer>
      <HeaderGreetingsContainer>
        <HeaderGreetingContainer>
          <HeaderGreetingTitle>
            {t("communityTimeline.greetingPre")} {user?.full_name}
          </HeaderGreetingTitle>
          <HeaderGreetingDesc>
            {t("communityTimeline.greetingDesc")}
          </HeaderGreetingDesc>
        </HeaderGreetingContainer>
        <HeaderUserLogoContainer>
          <HeaderUserLogo
            source={
              user?.photo || user?.photo.length > 0 ?
                `upload/profil/${user?.photo}` :
                (AVATAR_URL || "")
            }
            alt={'User-Photo'}
            alternateImage={AVATAR_URL}
          />
        </HeaderUserLogoContainer>
      </HeaderGreetingsContainer>
    </TopHeader>
  ), [handleNavigateBack, detail?.title, user])

  const renderMemberHeader = useMemo(() => (
    <TopHeader>
      <RowContainer>
        <BackButton onClick={handleShowMemberList}>
          <FuIconArrowLeft
            color="white"
          />
        </BackButton>
        <HeaderTitle>
          {t("communityTimeline.waitingApproval")}
        </HeaderTitle>
      </RowContainer>
    </TopHeader>
  ), [handleShowMemberList])

  const renderMenus = useMemo(() => (
    <Fragment>
      <TabContainer>
        {tabMenus.map((menu, index) => {
          const Icon = menu.icon
          return (
            <TabMenuContainer
              key={index}
              onClick={handleSetCurrentMenu(menu.id)}
            >
              <Icon />
            </TabMenuContainer>
          )
        })}
      </TabContainer>
      <TabContainer>
        {tabMenus.map((menu, index) => (
          <TabMenuContainer key={index}>
            {menu.id === currentMenu ? (
              <TabMenuLine />
            ) : (
              <TabMenuHiddenLine />
            )}
          </TabMenuContainer>
        ))}
      </TabContainer>
    </Fragment>
  ), [currentMenu])

  const renderBottomBtn = useMemo(() => (currentMenu === 0 || currentMenu == 1) && (
    <Fragment>
      <BottomBtnContainer
        onClick={
          currentMenu === 0 ?
          handleShowAddNewTimeline :
          handleAddNewThread
        }>
        <BottomBtn>
          <BottomBtnIcon>
            <FuIconEdit color="white" width="20" height="20" />
          </BottomBtnIcon>
          <BottomBtnText>
            {t(currentMenu === 0 ?
              "communityTimeline.addTimeline" :
              "communityTimeline.addThread"
            )}
          </BottomBtnText>
        </BottomBtn>
      </BottomBtnContainer>
    </Fragment>
  ), [currentMenu, handleShowAddNewTimeline, handleAddNewThread])

  const renderAddNewTimelineModal = useMemo(() => (
    <FuModal
      isOpen={showAddNewTimeline}
      onClose={handleShowAddNewTimeline}
      customStyles={addNewTimelineModalCustomStyles}
      children={
        <Fragment>
          <AddNewTimelineTitle>
            {t("communityTimeline.addTimelineModal.title")}
          </AddNewTimelineTitle>
          <FuInputLabel
            isTextArea
            input={inputNewTimelinePost}
            type={"text"}
            label={""}
            placeholder={t("communityTimeline.addTimelineModal.placeholder")}
            value={newTimelinePost.content}
            onChange={handleChangeInput}
          />
          <AddNewTimelineTextTnc>
            <Trans
              i18nKey="login.tnc"
              components={{
                policy:
                  <AddNewTimelineTextTncActive
                    onClick={
                      handleNavigateTermsAndPolicy(PrivacyPolicyPath)
                    }
                  />,
                tnc:
                  <AddNewTimelineTextTncActive
                    onClick={
                      handleNavigateTermsAndPolicy(TnCPath)
                    }
                  />
              }}
            />
          </AddNewTimelineTextTnc>
          <FuButton
            button={mainButton}
            text={t("communityTimeline.addTimelineModal.button")}
            onClick={handleAddNewTimeline}
          />
        </Fragment>
      }
    />
  ), [showAddNewTimeline, handleShowAddNewTimeline, newTimelinePost.content])

  useEffect(() => {
    if (percentage === 100 || percentage === 0) {
      setShowFileManagerMenu(null)
      setLoadingDownloadFile(false)
    }
  }, [percentage])

  const renderLoaderModal = useMemo(() => (
    loadingCreate || loadingUploadFile || loadingDownloadFile
  ) && (
    <FuModal
      isOpen={loadingCreate || loadingUploadFile || loadingDownloadFile}
      onClose={() => {}}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loadingCreate, loadingUploadFile, loadingDownloadFile])

  const renderUserJoinReqLoaderModal = useMemo(() => loadingUserJoinRequest && (
    <FuModal
      isOpen={loadingUserJoinRequest}
      onClose={() => {}}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loadingUserJoinRequest])

  const handleEditTimeline = useCallback((id_feed: string, content: string) => () => {
    setShowTimelinePostMenu(null)
    setTimelineIdFeed(id_feed)
    setShowAddNewTimeline(true)
    setNewTimelinePost({ content })
  }, [])

  const handleConfirmDeleteFile = useCallback(async () => {
    setShowConfirmDeleteFileModal(false)

    const result = await dispatch(deleteFileManager({
      path: currentFileManagerPath + (filePathDelete?.split(' ').join('_') || ""),
      id_community: slug,
      action: fileTypeDelete,
      force: 0
    }))

    if (result.isSuccess) {
      await dispatch(listFileManager({
        id_community: slug || "", path: currentFileManagerPath
      }))
      handleToast("success", result.message)
    } else {
      handleToast("error", result.message)
    }
  }, [filePathDelete, fileTypeDelete, slug])

  const handleConfirmDeleteThread = useCallback(async () => {
    setShowConfirmDeleteThreadModal(false)

    if (threadIdDelete) {
      const result = await dispatch(deleteThread({
        id_thread: threadIdDelete
      }))
      
      if (result.isSuccess) {
        setCurrentThreadPage(1)
        await dispatch(listThread({
          id_community: slug || "", limit: 10, current_page: 1
        }))
        setThreadIdDelete(null)
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }
    }
  }, [threadIdDelete, slug])

  const handleConfirmDeleteTimeline = useCallback(async () => {
    setShowConfirmDeleteTimelineModal(false)

    if (timelineIdFeedDelete) {
      const result = await dispatch(deleteTimeline({
        id_feed: timelineIdFeedDelete
      }))
      
      if (result.isSuccess) {
        setCurrentTimelinePage(1)
        await dispatch(listTimeline({
          id_community: slug || "", limit: 10, current_page: 1
        }))
        setTimelineIdFeedDelete(null)
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }
    }
  }, [timelineIdFeedDelete, slug])

  const handleReportTimelinePost = useCallback((user_name: string) => () => {
    setShowTimelinePostMenu(null)
    const line1 = 'Saya ingin melaporkan Postingan Timeline'
    const line2 = `Dari: *${user_name}*`
    const line3 = 'Grup'
    const link = `https://web.whatsapp.com/send?phone=+6281219406474&text=${encodeURIComponent(`${line1}\r\n\n${line2}\r\n${line3}`)}&app_absent=0`
    window.open(link, '_blank', 'noopener,noreferrer')
  }, [])

  const handleReportThreadPost = useCallback((user_name: string) => () => {
    setShowThreadPostMenu(null)
    const line1 = 'Saya ingin melaporkan Postingan Thread'
    const line2 = `Dari: *${user_name}*`
    const line3 = 'Grup'
    const link = `https://web.whatsapp.com/send?phone=+6281219406474&text=${encodeURIComponent(`${line1}\r\n\n${line2}\r\n${line3}`)}&app_absent=0`
    window.open(link, '_blank', 'noopener,noreferrer')
  }, [])

  const handleNavigateEditThread = useCallback((thread: IThread) => () => {
    const combinedPath =
      generatePath(ForumDiscussionsEditPath, { slug: detail?.id_community })

    navigate(combinedPath, { state: { thread, isUpdate: true } })
  }, [detail?.id_community])

  const handleNavigateTimelinePost = useCallback((post: ITimeline) => () => {
    const combinedPath =
      generatePath(CommunityTimelinePostPath, { slug: post.id_feed })

    navigate(combinedPath, { state: { post, detail } })
  }, [detail])

  const handleNavigateThreadPost = useCallback((thread: IThread) => () => {
    const combinedPath =
      generatePath(CommunityThreadDetailPath, { slug: thread.id_thread })

    navigate(combinedPath, { state: { detail } })
  }, [detail])

  const renderTimelineList = useMemo(() => (
    <TimelineListContainer onScroll={handleScroll}>
      {!timeline.length ?
        <FuNotFound text={t("communityTimeline.noTimeline")} /> :
        <Fragment>
          {timeline.map((post, index) => (
            <TimelinePost key={index} onClick={handleNavigateTimelinePost(post)}>
              <TimelinePostPhoto
                source={
                  post.user.photo.length > 0 ?
                    `upload/profil/${post.user.photo}` :
                    (AVATAR_URL || "")
                }
                alt={'User-Photo'}
                alternateImage={AVATAR_URL}
              />
              <TimelinePostRight>
                <TimelinePostTop>
                  <TimelinePostName>
                    {post.user.full_name}
                  </TimelinePostName>
                  <TimelinePostMenuIcon
                    onClick={handleShowTimelinePostMenu(post.id_feed)}
                  >
                    <Fragment>
                      {showTimelinePostMenu === post.id_feed && (
                        <TimelinePostMenu ref={wrapperRef}>
                          <TimelinePostMenuContainer onClick={handleReportTimelinePost(post.user.full_name)}>
                            <TimelinePostMenuContainerIcon>
                              <FuIconWarning />
                            </TimelinePostMenuContainerIcon>
                            <TimelinePostMenuText>
                              {t("communityTimeline.menu.report")}
                            </TimelinePostMenuText>
                          </TimelinePostMenuContainer>
                          
                            {user.id_user === post.user.id_user && (
                              <TimelinePostMenuContainer
                                onClick={handleEditTimeline(post.id_feed, post.content)}
                              >
                                <TimelinePostMenuContainerIcon>
                                  <FuIconEditPost color={"primaryMain"} />
                                </TimelinePostMenuContainerIcon>
                                <TimelinePostMenuText>
                                  {t("communityTimeline.menu.edit")}
                                </TimelinePostMenuText>
                              </TimelinePostMenuContainer>
                            )}

                            {(user.id_user === post.user.id_user ||
                              detail?.is_admin
                            ) && (
                              <TimelinePostMenuContainer onClick={handleShowConfirmDeleteTimelineModal(post.id_feed)}>
                                <TimelinePostMenuContainerIcon>
                                  <FuIconTrash color={"primaryMain"} />
                                </TimelinePostMenuContainerIcon>
                                <TimelinePostMenuText>
                                  {t("communityTimeline.menu.delete")}
                                </TimelinePostMenuText>
                              </TimelinePostMenuContainer>
                            )}
                          
                        </TimelinePostMenu>
                      )}
                    </Fragment>
                    <FuIconMenu />
                  </TimelinePostMenuIcon>
                </TimelinePostTop>
                <TimelinePostDate>
                  {moment(post.created_at).format('DD MMM YYYY')}
                </TimelinePostDate>
                <TimelinePostContent>
                  {post.content}
                </TimelinePostContent>
                <TimelinePostCommentContainer>
                  <FuIconChat color={"white"} />
                  <TimelinePostCommentText>
                    {post.count_comments}
                  </TimelinePostCommentText>
                </TimelinePostCommentContainer>
              </TimelinePostRight>
            </TimelinePost>
          ))}
        </Fragment>
      }
    </TimelineListContainer>
  ), [handleScroll, timeline, showTimelinePostMenu, user.id_user, handleEditTimeline, handleShowConfirmDeleteTimelineModal])

  const renderThreadList = useMemo(() => (
    <TimelineListContainer>
      {!threads.length ?
        <FuNotFound text={t("communityTimeline.noThread")} /> :
        <Fragment>
          {threads.map((thread, index) => (
            <ThreadPost key={index} onClick={handleNavigateThreadPost(thread)}>
              <ThreadPostTopMenu>
                <ThreadPostTopMenuIcon onClick={handleShowThreadPostMenu(thread.id_thread)}>
                  {showThreadPostMenu === thread.id_thread && (
                    <TimelinePostMenu ref={wrapperRef}>
                      <TimelinePostMenuContainer
                        onClick={handleReportThreadPost(thread.user.full_name)}
                      >
                        <TimelinePostMenuContainerIcon>
                          <FuIconWarning />
                        </TimelinePostMenuContainerIcon>
                        <TimelinePostMenuText>
                          {t("communityTimeline.menu.report")}
                        </TimelinePostMenuText>
                      </TimelinePostMenuContainer>

                      {user.id_user === thread.user.id_user && (
                        <TimelinePostMenuContainer onClick={handleNavigateEditThread(thread)}>
                          <TimelinePostMenuContainerIcon>
                            <FuIconEditPost color={"primaryMain"} />
                          </TimelinePostMenuContainerIcon>
                          <TimelinePostMenuText>
                            {t("communityTimeline.menu.edit")}
                          </TimelinePostMenuText>
                        </TimelinePostMenuContainer>
                      )}

                      {(
                        user.id_user === thread.user.id_user ||
                        detail?.is_admin
                      ) && (
                        <TimelinePostMenuContainer
                          onClick={handleShowConfirmDeleteThreadModal(thread.id_thread)}
                        >
                          <TimelinePostMenuContainerIcon>
                            <FuIconTrash color={"primaryMain"} />
                          </TimelinePostMenuContainerIcon>
                          <TimelinePostMenuText>
                            {t("communityTimeline.menu.delete")}
                          </TimelinePostMenuText>
                        </TimelinePostMenuContainer>
                      )}
                      
                    </TimelinePostMenu>
                  )}
                  <FuIconMenu />
                </ThreadPostTopMenuIcon>
              </ThreadPostTopMenu>
              <ThreadPostTop>
                <TimelinePostName>
                  {thread.title}
                </TimelinePostName>
                <ThreadPostDate>
                  {moment(thread.created_at).format('DD/MM/YYYY HH:mm')}
                </ThreadPostDate>
              </ThreadPostTop>
              <ThreadPostName>
                {thread.user.full_name}
              </ThreadPostName>
              <TimelinePostCommentContainer>
                <FuIconChat color={"white"} />
                <TimelinePostCommentText>
                  {thread.replies}
                </TimelinePostCommentText>
              </TimelinePostCommentContainer>
            </ThreadPost>
          ))}
        </Fragment>
      }
    </TimelineListContainer>
  ), [handleNavigateEditThread, threads, user.id_user, loadingThread, showThreadPostMenu, detail, handleShowConfirmDeleteThreadModal, handleReportThreadPost])


  const renderMembers = useMemo(() => detail && (
    <TimelineListContainer>
      {detail?.members?.map((member: CommunityMember, index: number) => (
        <MemberContainer key={index}>
          <MemberLeftContainer>
            <MemberPhoto
              source={
                member?.photo.length ?
                  `upload/profil/${member?.photo}` :
                  (AVATAR_URL || "")
                }
              alt={"Photo"}
              alternateImage={AVATAR_URL}
            />
            <MemberVerticalContainer>
              <MemberNameContainer>
                <MemberName>{member?.full_name}</MemberName>
                {member?.id_user === detail?.admin.id_user && (
                  <MemberAdminContainer>
                    <MemberAdminText>Admin</MemberAdminText>
                  </MemberAdminContainer>
                )}
              </MemberNameContainer>
              {member?.bio && <MemberBio>{member?.bio}</MemberBio>}
            </MemberVerticalContainer>
          </MemberLeftContainer>
        </MemberContainer>
      ))}
    </TimelineListContainer>
  ), [detail])

  const getPathName = useMemo(() => {
    const splitPath = fileManagers?.current_path.slice(1, fileManagers?.current_path.length - 1).split('/')

    if (splitPath) {
      if (splitPath.length > 1) {
        return `Home/${splitPath.slice(1).join('/')}`
      } else {
        return 'Home'
      }
    } else {
      return ''
    }
  }, [fileManagers])

  const renderFileManagerTop = useMemo(() => (
    <FileManagerTopContainer>
      <FileManagerTopButton onClick={handleShowAddFileModal}>
        <FuIconAddCircle color={"transparent"} />
      </FileManagerTopButton>
      <FileManagerCurrentPath>
        {getPathName}
      </FileManagerCurrentPath>
    </FileManagerTopContainer>
  ), [fileManagers, handleShowAddFileModal, getPathName])

  const handleClickFileListItem = useCallback((file: IFileManager) => () => {
    if (file.type === "file") {
      if (file.name.includes('.jpg') || file.name.includes('jpeg') || file.name.includes('png')) {
        handleShowFileManagerPreviewImage(file.path)()
      } else {
        window.open(`${IMAGE_URL}/upload/community/filemanager${file.path}`, '_blank', 'noopener,noreferrer')
      }
    } else {
      setCurrentFileManagerPath(`${currentFileManagerPath}${file.path.slice(file.path.lastIndexOf('/') + 1)}/`)
    }
  }, [handleShowFileManagerPreviewImage, currentFileManagerPath])

  const handleBackFileManager = useCallback(() => {
    let lastIndexOfSlash = currentFileManagerPath.slice(0, currentFileManagerPath.length - 1).lastIndexOf("/")
    setCurrentFileManagerPath(`${currentFileManagerPath.slice(0, lastIndexOfSlash)}/`)
  }, [currentFileManagerPath])

  const handleDownloadFileManagerFile = useCallback((path: string, name: string) => () => {
    setShowFileManagerMenu(null)
    setLoadingDownloadFile(true)
    download(`https://api.codetabs.com/v1/proxy?quest=${IMAGE_URL}upload/community/filemanager${path}`, name)
  }, [])

  const handleNavigateMoveFile = useCallback((file: IFileManager) => () => {
    setShowFileManagerMenu(null)
    
    const combinedPath =
      generatePath(FileManagerMovePath, { slug: detail?.id_community })

    navigate(combinedPath, {
      state: {
        file
      }
    })
  }, [detail])

  const checkShowFileListMenu = useCallback((file: IFileManager) => {
    const showEditDeleteMoveMenu = (user.id_user === file?.meta?.id_user) || detail?.is_admin
    const showDownloadMenu = file?.type === "file"

    if (showEditDeleteMoveMenu || showDownloadMenu) return true
    return false
  }, [user, detail])

  const renderFileList = useMemo(() => !loadingFileManagers && (
    <FileListContainer>
      {currentFileManagerPath !== "/" && (
        <FileListItemBackContainer onClick={handleBackFileManager}>
          <FuIconBackDirectory />
          <FileListItemBackText>Kembali</FileListItemBackText>
        </FileListItemBackContainer>
      )}
      {!fileManagers?.data.length ? (
        <FuNotFound text={t("communityTimeline.noFile")}/>
      ) : (
        <Fragment>
          {fileManagers?.data.map((file, index) => (
            <FileListItemContainer key={index} onClick={handleClickFileListItem(file)}>
              <FileListItemIconContainer>
                {file.type === "file" ? <FuIconFileLarge /> : <FuIconFolderLarge />}
              </FileListItemIconContainer>
              <FileListItemRightContainer>
                <div>
                  <FileListItemName>{file?.name}</FileListItemName>
                  <FileListItemUploadedBy>
                    {t("communityTimeline.fileManagerUploadedBy")}{' '}
                    <FileListItemUploadedByName>
                      {file.meta?.user_name || "-"}
                    </FileListItemUploadedByName>
                    <FileListItemDate>
                      {moment.unix(file?.meta?.last_modified).format('DD MMM YYYY')}
                    </FileListItemDate>
                  </FileListItemUploadedBy>
                </div>
                {checkShowFileListMenu(file) && (
                  <TimelinePostMenuIcon onClick={handleShowFileManagerMenu(file?.path)}>
                    <Fragment>
                        {showFileManagerMenu === file?.path && (
                          <TimelinePostMenu ref={wrapperRef}>
                            {(user.id_user === file?.meta?.id_user ||
                                detail?.is_admin
                              ) && (
                              <TimelinePostMenuContainer onClick={
                                handleShowConfirmDeleteFileModal(file?.name, file?.type)
                              }>
                                <FileManagerMenuContainerIcon>
                                  <FuIconTrash color={"primaryMain"} />
                                </FileManagerMenuContainerIcon>
                                <TimelinePostMenuText>
                                  {t("communityTimeline.menu.delete")}
                                </TimelinePostMenuText>
                              </TimelinePostMenuContainer>
                            )}
                            {(user.id_user === file?.meta?.id_user ||
                                detail?.is_admin
                              ) && (
                              <TimelinePostMenuContainer onClick={handleShowEditFileModal(file?.type, file?.name)}>
                                <FileManagerMenuContainerIcon>
                                  <FuIconEdit color={"primaryMain"} />
                                </FileManagerMenuContainerIcon>
                                <TimelinePostMenuText>
                                  {t("communityTimeline.menu.edit")}
                                </TimelinePostMenuText>
                              </TimelinePostMenuContainer>
                            )}
                            {(user.id_user === file?.meta?.id_user ||
                                detail?.is_admin
                              ) && (
                              <TimelinePostMenuContainer onClick={handleNavigateMoveFile(file)}>
                                <FileManagerMenuContainerIcon>
                                  <FuIconMoveFolder />
                                </FileManagerMenuContainerIcon>
                                <TimelinePostMenuText>
                                  {t("communityTimeline.menu.move")}
                                </TimelinePostMenuText>
                              </TimelinePostMenuContainer>
                            )}
                            {file?.type === "file" && (
                              <TimelinePostMenuContainer
                                onClick={handleDownloadFileManagerFile(file?.path, file?.name)}>
                                <FileManagerMenuContainerIcon>
                                  <FuIconDownload />
                                </FileManagerMenuContainerIcon>
                                <TimelinePostMenuText>
                                  {t("communityTimeline.menu.download")}
                                </TimelinePostMenuText>
                              </TimelinePostMenuContainer>
                            )}
                          
                            
                          </TimelinePostMenu>
                        )}
                      </Fragment>
                    <FuIconMenu />
                  </TimelinePostMenuIcon>
                )}
              </FileListItemRightContainer>
            </FileListItemContainer>
          ))}
        </Fragment>
      )}
    </FileListContainer>
  ), [user, detail, fileManagers, loadingFileManagers, currentFileManagerPath, handleBackFileManager, showFileManagerMenu, handleNavigateMoveFile, checkShowFileListMenu])

  const renderShowAddFileModal = useMemo(() => showAddFileModal && (
    <FuModal
      isOpen={showAddFileModal}
      onClose={handleShowAddFileModal}
      children={
        <Fragment>
          <FileManagerOptionContainer>
            <FuIconFile />
            <FileManagerOptionTextContainer>
              <FileManagerOptionText htmlFor="add-single-img">
                {t("communityTimeline.fileManagerFile")}
              </FileManagerOptionText>
              <FileManagerInput
                type="file"
                id="add-single-img"
                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, .mp4, .mp3, .ppt, .pptx, .avi, .wav"
                onChange={handleOnChangeUploadFile}
              />
            </FileManagerOptionTextContainer>
          </FileManagerOptionContainer>
          <FileManagerOptionContainer onClick={handleShowCreateFolderModal}>
            <FuIconFolder />
            <FileManagerOptionTextContainer>
              <FileManagerOptionText>
                {t("communityTimeline.fileManagerFolder")}
              </FileManagerOptionText>
            </FileManagerOptionTextContainer>
          </FileManagerOptionContainer>
        </Fragment>
      }
    />
  ), [showAddFileModal, handleShowAddFileModal])

  const handleActionMemberJoin = useCallback((type: "accept" | "reject") => async () => {
    let rawData: any = {
      id_request: type === "accept" ?
        (showConfirmApproveMemberModal?.id_reqmember_community || "") :
        (showConfirmRejectMemberModal?.id_reqmember_community || ""),
      action: type
    }

    if (reasonRejectMember.length > 0) {
      rawData['reason'] = reasonRejectMember
    }

    const result = await dispatch(actionUserJoinRequest(rawData))

    if (result.isSuccess) {
      setShowConfirmApproveMemberModal(null)
      setShowConfirmRejectMemberModal(null)
      setReasonRejectMember("")
      await dispatch(listUserJoinRequest({
        id_user: user.id_user,
        id_community: slug || "",
        limit: 10,
        current_page: currentMemberListPage
      }))
      handleToast("success", result.message)
    } else {
      setShowConfirmApproveMemberModal(null)
      setShowConfirmRejectMemberModal(null)
      setReasonRejectMember("")
      handleToast("error", result.message)
    }
  }, [showConfirmRejectMemberModal, showConfirmApproveMemberModal, reasonRejectMember, user, slug, currentMemberListPage])

  const renderConfirmRejectMemberModal = useMemo(() => showConfirmRejectMemberModal && (
    <FuModal
      isOpen={showConfirmRejectMemberModal ? true : false}
      onClose={handleShowConfirmRejectMemberModal()}
      customStyles={addNewTimelineModalCustomStyles}
      children={
        <Fragment>
          <ConfirmRejectMemberModalTitle>
            {t("communityTimeline.rejectMemberModal.title")}
          </ConfirmRejectMemberModalTitle>
          <ConfirmRejectMemberModalDesc>
            {t("communityTimeline.rejectMemberModal.description")}
          </ConfirmRejectMemberModalDesc>
          <FuInputLabel
            isTextArea
            input={inputNewTimelinePost}
            type={"text"}
            label={""}
            placeholder={t("communityTimeline.rejectMemberModal.inputText")}
            value={reasonRejectMember}
            onChange={handleOnChangeReasonRejectMember}
          />
          <ConfirmRejectMemberButtons>
            <ConfirmRejectMemberButton onClick={handleActionMemberJoin("reject")}>
              <ConfirmRejectMemberButtonText>
                {t("communityTimeline.rejectMemberModal.agree")}
              </ConfirmRejectMemberButtonText>
            </ConfirmRejectMemberButton>
            <ConfirmRejectMemberSecondButton onClick={handleShowConfirmRejectMemberModal()}>
              <ConfirmRejectMemberButtonText>
                {t("communityTimeline.rejectMemberModal.cancel")}
              </ConfirmRejectMemberButtonText>
            </ConfirmRejectMemberSecondButton>
          </ConfirmRejectMemberButtons>
        </Fragment>
      }
    />
  ), [handleActionMemberJoin, reasonRejectMember, showConfirmRejectMemberModal, handleShowConfirmRejectMemberModal, handleOnChangeReasonRejectMember])

  const renderConfirmApproveMemberModal = useMemo(() => showConfirmApproveMemberModal && (
    <FuModal
      isOpen={showConfirmApproveMemberModal ? true : false}
      onClose={handleShowConfirmApproveMemberModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.approveMemberModal.title")}{' '}
            {showConfirmApproveMemberModal?.full_name || ""}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.approveMemberModal.description")}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleActionMemberJoin("accept")}>
              {t("communityTimeline.approveMemberModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmApproveMemberModal()}>
              {t("communityTimeline.approveMemberModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmApproveMemberModal, handleShowConfirmApproveMemberModal, handleActionMemberJoin])

  const renderConfirmAddFileModal = useMemo(() => showConfirmAddFileModal && (
    <FuModal
      isOpen={showConfirmAddFileModal}
      onClose={handleShowConfirmAddFileModal}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.fileManagerUploadModal.title")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.fileManagerUploadModal.description")}{' '}
            {uploadedFile?.name || ""} ?
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmUploadFile}>
              {t("communityTimeline.fileManagerUploadModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={() => {
              setUploadedFile(null)
              handleShowConfirmAddFileModal()
            }}>
              {t("communityTimeline.fileManagerUploadModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmAddFileModal, handleShowConfirmAddFileModal, uploadedFile])

  const renderConfirmDeleteTimelineModal = useMemo(() => showConfirmDeleteTimelineModal && (
    <FuModal
      isOpen={showConfirmDeleteTimelineModal}
      onClose={handleShowConfirmDeleteTimelineModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.deleteTimelineModal.title")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.deleteTimelineModal.description")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteTimeline}>
              {t("communityTimeline.deleteTimelineModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteTimelineModal()}>
              {t("communityTimeline.deleteTimelineModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmDeleteTimelineModal, handleShowConfirmDeleteTimelineModal, handleConfirmDeleteTimeline])

  const renderConfirmDeleteThreadModal = useMemo(() => showConfirmDeleteThreadModal && (
    <FuModal
      isOpen={showConfirmDeleteThreadModal}
      onClose={handleShowConfirmDeleteThreadModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.deleteThreadModal.title")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.deleteThreadModal.description")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteThread}>
              {t("communityTimeline.deleteThreadModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteThreadModal()}>
              {t("communityTimeline.deleteThreadModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmDeleteThreadModal, handleShowConfirmDeleteThreadModal, handleConfirmDeleteThread])

  const renderConfirmDeleteFileModal = useMemo(() => showConfirmDeleteFileModal && (
    <FuModal
      isOpen={showConfirmDeleteFileModal}
      onClose={handleShowConfirmDeleteFileModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.deleteFileModal.title", { type: `${fileTypeDelete?.charAt(0).toUpperCase()}${fileTypeDelete?.slice(1)}` || "" })}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.deleteFileModal.description", { type: `${fileTypeDelete?.charAt(0).toUpperCase()}${fileTypeDelete?.slice(1)}` || "" })}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteFile}>
              {t("communityTimeline.deleteFileModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteFileModal()}>
              {t("communityTimeline.deleteFileModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [fileTypeDelete, showConfirmDeleteFileModal, handleShowConfirmDeleteFileModal, handleConfirmDeleteFile])

  const handleCreateFileManagerFolder = useCallback(async () => {
    if (!createFolderName.length) {
      handleToast("error", t("communityTimeline.fileManagerFolderInputError"))
    } else {
      const formData = {
        path: currentFileManagerPath,
        folder_name: createFolderName,
        id_community: detail?.id_community
      };

      const result = await dispatch(createFileManager(formData))

      if (result.isSuccess) {
        await dispatch(listFileManager({
          id_community: slug || "", path: currentFileManagerPath
        }))
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }

      setShowCreateFolderModal(false)
      setCreateFolderName("")
    }
  }, [detail, currentFileManagerPath, createFolderName])

  const renderShowCreateFolderModal = useMemo(() => showCreateFolderModal && (
    <FuModal
      isOpen={showCreateFolderModal}
      onClose={handleShowCreateFolderModal}
      children={
        <Fragment>
          <FileManagerCreateFolderInputLabel>
            {t("communityTimeline.fileManagerFolderInput")}
          </FileManagerCreateFolderInputLabel>
          <FileManagerCreateFolderInput>
            <FuInput
              placeholder=""
              input={{
                name: "search",
                radius: "10px",
                border: "greySearch"
              }}
              type="text"
              value={createFolderName}
              onChange={handleChangeCreateFolderName}
            />
          </FileManagerCreateFolderInput>
          <FuButton
            button={mainButton}
            text={t("communityTimeline.fileManagerFolder")}
            onClick={handleCreateFileManagerFolder}
          />
        </Fragment>
      }
    />
  ), [showCreateFolderModal, handleShowCreateFolderModal, createFolderName, handleCreateFileManagerFolder])

  const handleConfirmEditFileManager = useCallback(async () => {
    if (!editFileName.length) {
      if (showEditFileModal === "file") {
        handleToast("error", t("communityTimeline.fileManagerFileInputError"))
      } else {
        handleToast("error", t("communityTimeline.fileManagerFolderInputError"))
      }
    } else {
      const result = await dispatch(moveFileManager({
        action: `rename_${showEditFileModal}`,
        id_community: slug,
        origin_path: currentFileManagerPath + originEditFileName.split(' ').join('_'),
        destination_path: currentFileManagerPath + editFileName + "." + originEditFileName.split(".")[1]
      }))

      if (result.isSuccess) {
        await dispatch(listFileManager({
          id_community: slug || "", path: currentFileManagerPath
        }))
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }

      setShowEditFileModal(null)
      setOriginEditFileName("")
      setEditFileName("")
    }
  }, [editFileName, showEditFileModal, slug, currentFileManagerPath])

  const renderShowEditFileModal = useMemo(() => showEditFileModal && (
    <FuModal
      isOpen={showEditFileModal ? true : false}
      onClose={handleShowEditFileModal()}
      children={
        <Fragment>
          <FileManagerCreateFolderInputLabel>
            {t(
              showEditFileModal === "file" ?
              "communityTimeline.fileManagerFileInput" :
              "communityTimeline.fileManagerFolderInput"
             )}
          </FileManagerCreateFolderInputLabel>
          <FileManagerCreateFolderInput>
            <FuInput
              placeholder=""
              input={{
                name: "search",
                radius: "10px",
                border: "greySearch"
              }}
              type="text"
              value={editFileName}
              onChange={handleChangeEditFileName}
            />
          </FileManagerCreateFolderInput>
          <FuButton
            button={mainButton}
            text={t("communityTimeline.fileManagerFileEdit")}
            onClick={handleConfirmEditFileManager}
          />
        </Fragment>
      }
    />
  ), [showEditFileModal, handleShowEditFileModal, editFileName, handleConfirmEditFileManager])

  const renderGroupInfo = useMemo(() => (
    <TimelineListContainer>
      <GroupInfoLogo
        source={`upload/community/${detail?.image}`}
        alt={'Group-Info-Logo'}
      />
      <GroupInfoName>
        {detail?.title}
      </GroupInfoName>
      <GroupInfoCreatedBy>
        {t("communityTimeline.groupInfoCreatedBy")}
      </GroupInfoCreatedBy>
      <GroupInfoCreatedByName>
        {detail?.admin?.full_name}
      </GroupInfoCreatedByName>
      <GroupInfoDescTitle>
        {t("communityTimeline.groupInfoDescription")}
      </GroupInfoDescTitle>
      <GroupInfoDesc>
        {detail?.description}
      </GroupInfoDesc>
    </TimelineListContainer>
  ), [detail])

  const renderPointRewardModal = useMemo(() => showPointReward && (
    <FuPointReward
      title={showPointRewardTitle}
      point={currentShowPoint}
      isOpen={showPointReward}
      onClose={handleShowPointReward}
    />
  ), [showPointReward, handleShowPointReward, showPointRewardTitle, currentShowPoint])

  const renderFileManagerPreviewImage = useMemo(() => (
    <FuModal
      customStyles={previewImageStyles}
      isOpen={showFileManagerPreviewImage.length > 0 ? true : false}
      onClose={handleShowFileManagerPreviewImage()}
      children={
        <PreviewImage
          source={
            `upload/community/filemanager${showFileManagerPreviewImage}`
          }
          alt="preview-image"
        />
      }
    />
  ), [showFileManagerPreviewImage, handleShowFileManagerPreviewImage])

  const renderMemberTop = useMemo(() => currentMenu === 2 && detail?.is_admin && (
    <AwaitingApprovalMemberContainer>
      <AwaitingApprovalMemberText onClick={handleShowMemberList}>
        {t("communityTimeline.waitingApproval")} ({dataUserJoinRequest?.length})
      </AwaitingApprovalMemberText>
    </AwaitingApprovalMemberContainer>
  ), [detail, currentMenu, handleShowMemberList, dataUserJoinRequest])

  const renderUserRequestJoinList = useMemo(() => (
    <Fragment>
      {dataUserJoinRequest?.length === 0 ? (
        <FuNotFound text={t("communityTimeline.noMember")} />
      ) : (
        <Fragment>
          {dataUserJoinRequest?.map((data, index) => (
            <UserJoinRequestContainer key={index}>
              <UserJoinRequestPhotoContainer>
                <UserJoinRequestPhoto
                  source={
                    data?.photo || data?.photo.length > 0 ?
                      `upload/profil/${data?.photo}` :
                      (AVATAR_URL || "")
                  }
                  alt={'User-Photo'}
                  alternateImage={AVATAR_URL}
                />
              </UserJoinRequestPhotoContainer>
              <div>
                <UserJoinRequestName>{data?.full_name}</UserJoinRequestName>
                <UserJoinRequestBio>{data?.bio || "Tidak ada Bio"}</UserJoinRequestBio>
                <UserJoinRequestOptions>
                  <UserJoinRequestOptionLeft onClick={handleShowConfirmApproveMemberModal(data)}>
                    <FuIconCheckCircle />
                    <UserJoinRequestOptionText>
                      {t("communityTimeline.acceptMember")}
                    </UserJoinRequestOptionText>
                  </UserJoinRequestOptionLeft>
                  <UserJoinRequestOption onClick={handleShowConfirmRejectMemberModal(data)}>
                    <FuIconCrossCircle />
                    <UserJoinRequestOptionText>
                      {t("communityTimeline.rejectMember")}
                    </UserJoinRequestOptionText>
                  </UserJoinRequestOption>
                </UserJoinRequestOptions>
              </div>
            </UserJoinRequestContainer>
          ))}
        </Fragment>
      )}
    </Fragment>
  ), [dataUserJoinRequest, handleShowConfirmApproveMemberModal, handleShowConfirmRejectMemberModal])

  return (
    <Container>
      {!showMemberList ? (
        <Fragment>
          <TopContainer>
            {renderHeader}
            {renderMenus}
            <MenuTitle>
              {t(tabMenus[currentMenu].title)}
            </MenuTitle>
            {currentMenu === 2 && detail?.is_admin && renderMemberTop}
            {currentMenu === 3 && renderFileManagerTop}
          </TopContainer>
          <BottomContainer>
            {currentMenu === 0 && renderTimelineList}
            {currentMenu === 1 && renderThreadList}
            {currentMenu === 2 && renderMembers}
            {currentMenu === 3 && renderFileList}
            {currentMenu === 4 && renderGroupInfo} 
            {renderBottomBtn}
            {renderAddNewTimelineModal}
            {renderShowAddFileModal}
            {renderConfirmAddFileModal}
            {renderShowCreateFolderModal}
            {renderShowEditFileModal}
            {renderConfirmDeleteThreadModal}
            {renderConfirmDeleteTimelineModal}
            {renderConfirmDeleteFileModal}
            {renderLoaderModal}
            {renderPointRewardModal}
            {renderFileManagerPreviewImage}
          </BottomContainer>
        </Fragment>
      ) : (
        <Fragment>
          <TopContainer>
            {renderMemberHeader}
          </TopContainer>
          {renderUserRequestJoinList}
          {renderUserJoinReqLoaderModal}
          {renderConfirmApproveMemberModal}
          {renderConfirmRejectMemberModal}
        </Fragment>
      )}
    </Container>
  )
}

export default ViewCommunityTimeline
