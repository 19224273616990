import { FuIconSearch } from "fu/components/FuIcon"
import { Loader } from "fu/components/FuApp/Component"
import { Paths } from "fu/routes/paths"
import FuIconArrowLeft from "fu/components/FuIcon/IconArrowLeft"
import { FuImage } from "fu/components/FuImage"
import { FuInput, IInput } from "fu/components/FuInput"
import { FuNotFound } from "fu/components/FuNotFound"
import { useAppDispatch } from "fu/stores"
import listCommunity from "fu/stores/actions/community/listCommunity"
import listCommunitySearch from "fu/stores/actions/community/listCommunitySearch"
import { AppState } from "fu/stores/reducers"
import React, { FC, useCallback, useEffect, useState, UIEvent } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate, useLocation, generatePath } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  background: ${({ theme }) => theme.white};
  padding: 14px 0;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow-y: auto;

  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  user-select: none;
  padding: 0 18px;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const SearchHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0 18px;
`

const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  user-select: none;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  cursor: pointer;
`

const ItemList = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  padding: 0 6px;
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ImageItem = styled.div`
  height: 52px;
  width: 52px;
  border-radius: 100%;
  overflow: hidden;
`

const IconImageItem = styled(FuImage)`
  height: 52px;
  object-fit: cover;
`

const TextTitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
  font-family: Poppins-Bold, sans-serif;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
`

const TextGroupStatus = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  font-family: Poppins-Light, sans-serif;
`

const TextMember = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.sub};
  font-family: Roboto-Regular, sans-serif;
`

const TextDescription = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.description};
  font-family: Roboto-Regular, sans-serif;
  text-overflow: ellipsis; 
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const inputSearch: IInput = {
  name: "search",
  radius: "10px",
  border: "greySearch"
}

const LoaderContainer = styled.div`
  margin-top: 20px;
`

const { COMMUNITY_DETAIL: { path } } = Paths

const ViewCommunity: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState<string>(state?.search || "")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { communities, loading } =
    useSelector((state: AppState) => state.community.listCommunity)

  const handleNavigateDetail = useCallback((id: string) => () => {
    const combinedPath = generatePath(path, { slug: id })
    navigate(combinedPath)
  }, [])
  
  const handleFetchPaginationData = useCallback((page: number) => {
    dispatch(listCommunity({ limit: 10, current_page: page }))
  }, [dispatch, listCommunity])

  useEffect(() => {
    handleFetchPaginationData(currentPage)
  }, [handleFetchPaginationData, currentPage])

  useEffect(() => {
    if (search.length) {
      dispatch(listCommunitySearch({ title: search }))
    } else {
      handleFetchPaginationData(1)
    }
  }, [dispatch, listCommunitySearch, search, handleFetchPaginationData])

  const handlePageBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleChangeSearch = useCallback((_: string, value: string) => {
    setSearch(value)
  }, [setSearch])

  const handleScroll = useCallback(
    ({ currentTarget }: UIEvent<HTMLDivElement>
  ) => {
    const bottom =
      (currentTarget.scrollHeight - currentTarget.scrollTop)
        === currentTarget.clientHeight;

    if (bottom && communities.length === (currentPage * 10) && !search.length) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentPage, communities.length, search.length])

  return (
    <Container>
      <Content>
        <Header>
          <IconHeader onClick={handlePageBack}>
            <FuIconArrowLeft />
          </IconHeader>
          <TextHeader>{t("community.all")}</TextHeader>
        </Header>
        <SearchHeader>
          <FuInput
            input={inputSearch}
            type="text"
            placeholder={t("community.search")}
            value={search}
            prepend={<FuIconSearch
              color="greySearch"
              size="16"
            />}
            onChange={handleChangeSearch}
          />
        </SearchHeader>
      </Content>
      <ContentScroll onScroll={handleScroll}>
        <List>
          {
            !communities.length && !loading && (
              <FuNotFound text={"community.notFound"} />
            )
          }
          {
            communities.map((item, index) => (
              <ContentList key={index} onClick={handleNavigateDetail(item.id_community)}>
                <ItemList>
                  <ImageItem>
                    <IconImageItem
                      source={`upload/community/${item.image}`}
                      alt={item.title}
                    />
                  </ImageItem>
                  <ContentItem>
                    <TitleContainer>
                      <TextTitle>{item.title}</TextTitle>
                      {item.join_need_approval === "true" && (
                        <TextGroupStatus>
                          {t("categoryDetail.privateLabel")}
                        </TextGroupStatus>
                      )}
                    </TitleContainer>
                    <TextMember>
                      <Trans i18nKey="home.member" values={{ count: item.count_members }} />
                    </TextMember>
                    <TextDescription>{item.description}</TextDescription>
                  </ContentItem>
                </ItemList>
              </ContentList>
            ))
          }
          {loading && (
            <LoaderContainer>
              <Loader small />
            </LoaderContainer>
          )}
        </List>
      </ContentScroll>
    </Container>
  )
}

export default ViewCommunity