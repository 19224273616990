import { FuModal } from "fu/components/FuModal"
import { FuImage } from "fu/components/FuImage"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import React, { FC } from "react"
import styled from "styled-components"

interface Props {
  onClose: () => void
  image: string | null
}

const customBannerStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderWidth: 0,
    borderRadius: 0
  },
  overlay: {
    backgroundColor: defaultTheme.black,
    zIndex: 999
  }
}

const Image = styled(FuImage)`
  max-width: 700px;
`

const BannerPreview: FC<Props> = ({
  onClose,
  image
}) => {
  return (
    <FuModal
      customStyles={customBannerStyles}
      isOpen={image ? true : false}
      onClose={onClose}
      children={
        <Image
          source={`upload/banner/${image}`}
          alt="banner"
        />
      }
    />
  )
}

export default BannerPreview
