export const convertBase64ToFile = (url: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file: File = new File([blob], "CaptureImg",{ type: "image/png" })
        resolve(file)
      })
      .catch(err => {
        reject(err)
      })
  })
}