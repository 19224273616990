import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconTrash: FC<IIcon> = ({ width = "15", height="17", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.6875 0V0.9375H0V2.8125H0.9375V15C0.9375 15.4973 1.13504 15.9742 1.48667 16.3258C1.83831 16.6775 2.31522 16.875 2.8125 16.875H12.1875C12.6848 16.875 13.1617 16.6775 13.5133 16.3258C13.865 15.9742 14.0625 15.4973 14.0625 15V2.8125H15V0.9375H10.3125V0H4.6875ZM2.8125 2.8125H12.1875V15H2.8125V2.8125ZM4.6875 4.6875V13.125H6.5625V4.6875H4.6875ZM8.4375 4.6875V13.125H10.3125V4.6875H8.4375Z" fill="#FF212A"/>
    </Icon>
  )
}

export default FuIconTrash
