import React, { FC, lazy } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { DefaultTheme } from "styled-components"
import { Paths } from "./paths"

const {
  PRIVACY_POLICY: { path: PrivacyPolicyPath },
  TERMS_AND_CONDITIONS: { path: TnCPath },
  HOME: { path: HomePath },
  CATEGORY: { path: CategoryPath },
  COMMUNITY: { path: CommunityPath },
  COMMUNITY_DETAIL: { path: CommunityDetailPath },
  COMMUNITY_ADD: { path: CommunityAddPath },
  COMMUNITY_EDIT: { path: CommunityEditPath },
  COMMUNITY_TIMELINE: { path: CommunityTimelinePath },
  CATEGORY_DETAIL: { path: CategoryDetailPath },
  FORUM_DISCUSSION_ADD: { path: ForumDiscussionAddPath },
  FORUM_DISCUSSION_EDIT: { path: ForumDiscussionEditPath },
  COMMUNITY_TIMELINE_POST: { path: CommunityTimelinePostPath },
  COMMUNITY_THREAD_DETAIL: { path: CommunityThreadDetailPath },
  FILE_MANAGER_MOVE: { path: FileManagerMovePath }
} = Paths

const TermsAndPolicy = lazy(() => import("../views/TermsAndPolicy"))
const Home = lazy(() => import("../views/Home"))
const Category = lazy(() => import("../views/Category"))
const Community = lazy(() => import("../views/Community"))
const CommunityDetail = lazy(() => import("../views/CommunityDetail"))
const CommunityAdd = lazy(() => import("../views/CommunityAdd"))
const CommunityTimeline = lazy(() => import("../views/CommunityTimeline"))
const CategoryDetail = lazy(() => import("../views/CategoryDetail"))
const ForumDiscussionAdd = lazy(() => import("../views/ForumDiscussionAdd"))
const CommunityTimelineComment = lazy(() => import("../views/CommunityTimelineComment"))
const CommunityThreadComment = lazy(() => import("../views/CommunityThreadComment"))
const FileManagerMove = lazy(() => import("../views/FileManagerMove"))

interface Props {
  handleSetBgColor: (color: keyof DefaultTheme) => void
}

const ProtectedRoutes: FC<Props> = ({ handleSetBgColor }) => (
  <Routes>
    <Route path={HomePath} element={<Home />} />
    <Route path={CategoryPath} element={<Category />} />
    <Route path={CategoryDetailPath} element={<CategoryDetail handleSetBgColor={handleSetBgColor} />} />
    <Route path={CommunityPath} element={<Community />} />
    <Route path={CommunityDetailPath} element={<CommunityDetail handleSetBgColor={handleSetBgColor} />} />
    <Route path={CommunityAddPath} element={<CommunityAdd />} />
    <Route path={CommunityEditPath} element={<CommunityAdd />} />
    <Route path={CommunityTimelinePath} element={<CommunityTimeline handleSetBgColor={handleSetBgColor} />} />
    <Route path={CommunityTimelinePostPath} element={<CommunityTimelineComment handleSetBgColor={handleSetBgColor} />} />
    <Route path={CommunityThreadDetailPath} element={<CommunityThreadComment handleSetBgColor={handleSetBgColor} />} />
    <Route path={ForumDiscussionAddPath} element={<ForumDiscussionAdd />} />
    <Route path={ForumDiscussionEditPath} element={<ForumDiscussionAdd />} />
    <Route path={FileManagerMovePath} element={<FileManagerMove />} />
    <Route path={TnCPath} element={<TermsAndPolicy />} />
    <Route path={PrivacyPolicyPath} element={<TermsAndPolicy />} />
    <Route path="*" element={<Navigate to={HomePath} replace />} />
  </Routes>
)

export default ProtectedRoutes