import React, { FC } from "react"
import FuHeader from "./FuHeader"
import { Main, Container } from "./Component"
import { DefaultTheme } from "styled-components"

type Props = {
  children: JSX.Element
  customBgColor?: keyof DefaultTheme
}

const FuLayout: FC<Props> = ({ children, customBgColor }) => (
  <Container bgColor={customBgColor}>
    <FuHeader />
    <Main>
      {children}
    </Main>
  </Container>
)

export default FuLayout