import { FuImage } from "fu/components/FuImage"
import { Paths } from "fu/routes/paths"
import { FuNotFound } from "fu/components/FuNotFound"
import { AppState } from "fu/stores/reducers"
import React, { FC, useMemo, useCallback } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate, generatePath } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0 18px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  user-select: none;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  cursor: pointer;
`

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  padding: 0 6px;
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Image = styled.div`
  height: 52px;
  width: 52px;
  border-radius: 100%;
  overflow: hidden;
`

const MainImage = styled(FuImage)`
  height: 52px;
  width: 52px;
  object-fit: cover;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
`

const TextTitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
  font-family: Poppins-Bold, sans-serif;
`

const TextGroupStatus = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  font-family: Poppins-Light, sans-serif;
`

const TextDescription = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.description};
  font-family: Roboto-Regular, sans-serif;
  text-overflow: ellipsis; 
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 550px;

  @media only screen and (max-width: 600px) {
    max-width: 250px;
  }
`

interface Props {
  search: string
}

const { COMMUNITY_DETAIL: { path } } = Paths

const HomeMyCommunity: FC<Props> = ({ search }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { communities } = useSelector((state: AppState) => state.community.myCommunity)

  const handleNavigateDetail = useCallback((id: string) => () => {
    const combinedPath = generatePath(path, { slug: id })
    navigate(combinedPath)
  }, [])

  const filteredCommunities = useMemo(() => {
    if (search.length) return communities.filter(
      (item) => item.title.toLowerCase().includes(search.toLowerCase())
    )

    return communities
  }, [communities, search])

  return (
    <Container>
      {
        !filteredCommunities.length && (
          <FuNotFound text={"community.notFound"} />
        )
      }
      {
        filteredCommunities.map((item, index) => (
          <Content key={index} onClick={handleNavigateDetail(item.id_community)}>
            <Item>
              <Image>
                <MainImage
                  alt={item.title}
                  source={`upload/community/${item.image}`}
                />
              </Image>
              <Detail>
                <TitleContainer>
                  <TextTitle>{item.title}</TextTitle>
                  {item.join_need_approval === "true" && (
                    <TextGroupStatus>
                      {t("categoryDetail.privateLabel")}
                    </TextGroupStatus>
                  )}
                </TitleContainer>
                <TextDescription>{item.description}</TextDescription>
              </Detail>
            </Item>
          </Content>
        ))
      }
    </Container>
  )
}

export default HomeMyCommunity