import { StateActions } from "fu/stores/actions/timeline/createTimeline"

type State = {
  loading: boolean
}

const initialState: State = {
  loading: false
}

const createTimelineReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "TIMELINE_CREATE_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "TIMELINE_CREATE_END":
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default createTimelineReducer
