export default (error: any) => {
  if (error.response) {
    return error.response.data
  }

  if (error.request) {
    return error.request
  }

  return { message: "internal error" }
}