import React, { FC } from "react"

import { Container, Loader } from "./Component"

const FuLoader: FC = () => (
  <Container>
    <Loader />
  </Container>
)

export default FuLoader