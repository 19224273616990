import { ITopic } from "fu/models/topic"
import { StateActions } from "fu/stores/actions/static/listTopic"

type State = {
  loading: boolean
  topics: Array<ITopic>
  error: any
}

const initialState: State = {
  loading: true,
  topics: [],
  error: null
}

const listTopicReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_TOPIC_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_TOPIC_SUCCESS":
      return {
        ...state,
        loading: false,
        topics: action.payload.topic
      }
    case "LIST_TOPIC_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listTopicReducer