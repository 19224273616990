import { FuAsset } from "../FuImage";
import { notfound } from "fu/assets/images/general";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
`

const NotFoundImage = styled(FuAsset)`
  width: 160px;
  height: 160px;
`

const NotFoundText = styled.span`
  font-size: 14px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
`

interface Props {
  text: string
}

const FuNotFound: FC<Props> = ({ text }) => {
  const { t } = useTranslation()

  return (
    <NotFoundContainer>
      <NotFoundImage
        source={notfound}
        alt="Not-Found"
      />
      <NotFoundText>
        {t(text)}
      </NotFoundText>
    </NotFoundContainer>
  )
}

export default FuNotFound
