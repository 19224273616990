import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconGroupInfo: FC<IIcon> = ({ width = "23", height = "17", color = "iconDark", className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0ZM16 16H2V2H16V16Z" fill="black"/>
      <path d="M8 4H10V6H8V4ZM8 8H10V14H8V8Z" fill="black"/>
    </Icon>
  )
}

export default FuIconGroupInfo
