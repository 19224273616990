import { combineReducers } from "redux"
import listThreadReducer from "./listThread"
import deleteThreadReducer from "./deleteThread"
import deleteThreadCommentReducer from "./deleteThreadComment"
import createThreadReducer from "./createThread"
import updateThreadReducer from "./updateThread"
import detailThreadReducer from "./detailThread"
import listThreadCommentReducer from "./listCommentThread"
import createThreadCommentReducer from "./createThreadComment"

const threadReducer = combineReducers({
  listThread: listThreadReducer,
  deleteThread: deleteThreadReducer,
  createThread: createThreadReducer,
  updateThread: updateThreadReducer,
  detailThread: detailThreadReducer,
  listThreadComment: listThreadCommentReducer,
  deleteThreadComment: deleteThreadCommentReducer,
  createThreadComment: createThreadCommentReducer
})

export default threadReducer