import React, { FC, Fragment, useContext } from 'react'
import { GeneralContext } from 'fu/contexts/GeneralProvider'
import { FuModal } from '../FuModal'
import { FuAsset } from '../FuImage'
import faimage from "fu/assets/images/static"
import { defaultTheme } from '../FuApp/FuTheme'
import { FuButton, ETypes, ESizes } from '../FuButton'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  point: number
}

const Title = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.black};
  font-family: Poppins-Medium, sans-serif;
  text-align: center;
`

const Description = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Regular, sans-serif;
  text-align: center;
  margin-bottom: 12px;
`

const PointNumber = styled(Title)`
  color: ${({ theme }) => theme.primaryMain};
`

const RocketImage = styled(FuAsset)`
  width: 25%;
  display: flex;
  margin: 18px auto;
`

const CustomStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '400px',
    overflow: "hidden"
  }
}

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const FuPointReward: FC<Props> = ({
  isOpen,
  onClose,
  title,
  point
}) => {
  const { t } = useTranslation()
  
  return (
    <FuModal
      isOpen={isOpen}
      onClose={onClose}
      customStyles={CustomStyles}
      children={
        <Fragment>
          <Title>{t("pointRewardsModal.title")} {title}</Title>
          <PointNumber>{point} Poin &#129395;</PointNumber>
          <RocketImage
            source={faimage.rocket}
            alt="point-rewards"
          />
          <Description>
            {t("pointRewardsModal.description")}
          </Description>
          <FuButton
            button={mainButton}
            onClick={onClose}
            text={t("pointRewardsModal.close")}
          />
        </Fragment>
      }
    />
  )
}

export default FuPointReward
