import { IUser } from "./user"

export interface IStorage {
  token: string
  user: IUser
}

export enum CacheKey {
  Token = "TOKEN",
  User = "USER"
}

export type AuthStatus = "checking" | "authenticated" | "no-authenticated"

export type Tabs = "email" | "phone"

export interface TabsProps {
  isHighlight: boolean
}

export interface MenuTab {
  value: Tabs
  text: string
}

export interface AppStores {
  alt: string
  source: string
  link: string
}

export interface LoginInputForm {
  email?: string
  phone?: string
  password: string
}
