import { FuImage, FuAsset } from "fu/components/FuImage"
import { FuIconArrowLeft } from "fu/components/FuIcon"
import communityAsset from "fu/assets/images/community"
import { Paths } from "fu/routes/paths"
import { AVATAR_URL } from "fu/constants/Environment"
import { FuModal } from "fu/components/FuModal"
import { FuLoader } from "fu/components/FuLoader"
import { FuNotFound } from "fu/components/FuNotFound"
import { useAppDispatch } from "fu/stores"
import listCommunityByTopic from "fu/stores/actions/community/listCommunityByTopic"
import React, { FC, useCallback, useEffect, useMemo, useState, Fragment, UIEvent } from "react"
import { useNavigate, useParams, useLocation, generatePath } from "react-router-dom"
import styled, { DefaultTheme } from "styled-components"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import { ICommunityByTopic } from "fu/models/community"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { AppState } from "fu/stores/reducers"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
  overflow-y: scroll;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0 0 20px 20px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  user-select: none;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  height: 198px;
  background: ${({ theme }) => theme.placeholder};
`

const Banner = styled(FuImage)`
  position: absolute;
  object-fit: cover;
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -60px;
`

const HeaderAction = styled.div`
  display: grid;
  padding: 14px 16px;
  grid-template-columns: 1fr auto;
  grid-gap: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeaderBack = styled.div`
  display: flex;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
`

const HeaderTitle = styled.p`
  height: 100px;
  padding: 10px 30px 0 30px;
  background-color: ${({ theme }) => theme.white};
  font-family: Poppins-Medium, sans-serif;
  border-radius: 35%;
  overflow: hidden;
  border: 6px solid ${({ theme }) => theme.white};
`

const HeaderLine = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 80px;
  height: 3px;
  background-color: ${({ theme }) => theme.black};
  margin-top: 10px;
` 

const DescriptionText = styled.p`
  padding: 36px;
  display: flex;
  text-align: center;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
`

const CommunityListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  flex-wrap: wrap;
  padding: 24px 0;
`

const CommunityCard = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 36px 36px 10px 10px;
  max-width: 152px;
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 20px 5px 20px 5px;

  @media only screen and (max-width: 345px) {
    max-width: 140px;
  }

  @media only screen and (max-width: 325px) {
    max-width: 130px;
  }
`

const CommunityCardInner = styled.div`
  cursor: pointer;
`

const CommunityHeader = styled.div`
  position: relative;
`

const CommunityBanner = styled(FuAsset)`
  border-radius: 36px;
  width: 100%;
  object-fit: contain;
`

const CommunityHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const CommunityHeaderAvatar = styled.div`
  height: 60px;
  width: 40%;
  border-radius: 100%;
  margin-top: 8%;
  overflow: hidden;
  border: 6px solid ${({ theme }) => theme.white};

  @media only screen and (max-width: 325px) {
    height: 55px;
  }
`

const CommunityAvatarImage = styled(FuImage)`
  background-color: ${({ theme }) => theme.white};
  object-fit: cover;
  height: 60px;
`

const CommunityName = styled.p`
  text-align: center;
  font-size: 14px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  padding: 28px 4px 0px 4px;
`

const CommunityGroupStatus = styled.p`
  text-align: center;
  font-size: 10px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
`

const CommunityCountMember = styled.p`
  text-align: center;
  font-size: 10px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
  padding-top: 8px;
`

const CommunityMemberPhotos = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-self: center;
`

const CommunityMemberPhoto = styled(FuImage)`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`

const CommunityMemberCenterPhoto = styled(CommunityMemberPhoto)`
  margin: 0 -4px 0 -4px;
`

const CommunityDisableBtn = styled.div`
  background-color: ${({ theme }) => theme.greyLight};
  border-radius: 15px;
  display: flex;
  margin: 12px auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const CommunityBtn = styled(CommunityDisableBtn)`
  background-color: ${({ theme }) => theme.primaryMain};
`

const CommunityDisableBtnText = styled.p`
  padding: 4px 10px;
  color: ${({ theme }) => theme.white};
  font-size: 10px;
  font-family: Poppins-Medium, sans-serif;
`

interface Props {
  handleSetBgColor: (color: keyof DefaultTheme) => void
}

const { COMMUNITY_DETAIL: { path } } = Paths;

const ViewCategoryDetail: FC<Props> = ({ handleSetBgColor }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { slug } = useParams()
  const { state: { topic } } = useLocation()
  const dispatch = useAppDispatch()
  const { communities, loading }: { communities: Array<ICommunityByTopic>, loading: boolean } =
    useSelector((state: AppState) => state.community.listCommunityByTopic)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleScroll = useCallback(
    ({ currentTarget }: UIEvent<HTMLDivElement>
  ) => {
    const bottom =
      Math.ceil(currentTarget.scrollHeight - currentTarget.scrollTop)
        === currentTarget.clientHeight;

    if (bottom && communities.length === (currentPage * 10)) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentPage, communities.length])

  const handleNavigateDetail = useCallback((id: string) => () => {
    const combinedPath = generatePath(path, { slug: id })
    navigate(combinedPath)
  }, [])

  useEffect(() => {
    handleSetBgColor("whiteGrey")

    return () => {
      handleSetBgColor("white")
    }
  }, [])

  useEffect(() => {
    dispatch(listCommunityByTopic({
      id_topic: slug || "",
      limit: 10,
      current_page: currentPage
    }))
  }, [currentPage, slug])

  const handleNavigateBack = useCallback(() => {
    navigate(-1)
  }, [])

  const getMemberPhoto = useCallback((community: ICommunityByTopic) => {
    return community.members ? community.members.map(
      member => member.photo.length > 0 ? member.photo : null
    ) : [];
  }, [])

  const renderLoaderModal = useMemo(() => loading && (
    <FuModal
      isOpen={loading}
      onClose={() => {}}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loading])

  const getCommunityButton = useCallback((community: ICommunityByTopic) => {
    if (community.is_joined || community.already_req_join) {
      return (
        <CommunityDisableBtn>
          <CommunityDisableBtnText>
            {t
              (
                community.is_joined ?
                  "communityDetail.joinedDirectButton" :
                  "communityDetail.joinedOnProgressButton"
              )
            }
          </CommunityDisableBtnText>
        </CommunityDisableBtn>
      )
    } else {
      return (
        <CommunityBtn onClick={handleNavigateDetail(community.id_community)}>
          <CommunityDisableBtnText>
            {t
              (
                community.join_need_approval === "true" ?
                  "communityDetail.joinedReqButton" :
                  "communityDetail.joinedDirectButton"
              )
            }
          </CommunityDisableBtnText>
        </CommunityBtn>
      )
    }
  }, [])

  const renderCommunities = useMemo(() => communities.length > 0 && (
    <CommunityListContainer>
      {communities.map((community, index) => (
        <CommunityCard key={index}>
          <CommunityCardInner onClick={handleNavigateDetail(community.id_community)}>
            <CommunityHeader>
              <CommunityBanner
                source={communityAsset.banner}
                alt="Banner Community"
              />
              <CommunityHeaderContent>
                <CommunityHeaderAvatar>
                  <CommunityAvatarImage
                    source={`upload/community/${community.image}`}
                    alt="Detail Community"
                  />
                </CommunityHeaderAvatar>
              </CommunityHeaderContent>
            </CommunityHeader>
            <CommunityName>{community.title}</CommunityName>
            {community.join_need_approval === "true" && (
              <CommunityGroupStatus>
                {t("categoryDetail.privateLabel")}
              </CommunityGroupStatus>
            )}
            <CommunityCountMember>
              {community.count_members} {t("categoryDetail.communityMemberJoin")}
            </CommunityCountMember>
            <CommunityMemberPhotos>
              {getMemberPhoto(community).slice(0,3).map((photo, index) => (
                <Fragment>
                  {index === 1 ? (
                    <CommunityMemberCenterPhoto
                      key={index}
                      source={photo ? `upload/profil/${photo}` : (AVATAR_URL || "")}
                      alt={`member-${index}`}
                      alternateImage={AVATAR_URL}
                    />
                  ) : (
                    <CommunityMemberPhoto
                      key={index}
                      source={photo ? `upload/profil/${photo}` : (AVATAR_URL || "")}
                      alt={`member-${index}`}
                      alternateImage={AVATAR_URL}
                    />
                  )}
                </Fragment>
              ))}
            </CommunityMemberPhotos>
          </CommunityCardInner>
          {getCommunityButton(community)}
        </CommunityCard>
      ))}
    </CommunityListContainer>
  ), [communities, getMemberPhoto])

  const renderMainContent = useMemo(() => {
    if (loading) return renderLoaderModal
    if (!communities.length) return <FuNotFound text={t("categoryDetail.noCommunity")} />
    return renderCommunities
  }, [loading, communities.length, renderLoaderModal, renderCommunities])

  return (
    <Container onScroll={handleScroll}>
      <Content>
        <Header>
          <Banner
            source={`upload/topic/${topic.icon}`}
            alt="Banner Detail Community"
          />
          <HeaderContent>
            <HeaderAction onClick={handleNavigateBack}>
              <HeaderBack>
                <FuIconArrowLeft
                  color="white"
                />
              </HeaderBack>
            </HeaderAction>
            <HeaderTitle>
              {topic.title}
            </HeaderTitle>
          </HeaderContent>
        </Header>
        <HeaderLine />
        <DescriptionText>
          {t("categoryDetail.description")}
        </DescriptionText>
      </Content>
      {renderMainContent}
    </Container>
  ) 
}

export default ViewCategoryDetail
