import { FuIconArrowLeft, FuIconAdd, FuIconCross } from "fu/components/FuIcon"
import { FuInputLabel } from "fu/components/FuInput"
import { FuLoader } from "fu/components/FuLoader"
import { IMAGE_URL } from "fu/constants/Environment"
import { FuModal } from "fu/components/FuModal"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { Paths } from "fu/routes/paths"
import { FuAsset, FuImage } from "fu/components/FuImage"
import createThread from "fu/stores/actions/thread/createThread"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import { handleToast } from "fu/utils/toast"
import { doc, pdf, ppt, video, xls } from "fu/assets/images/thread"
import { AppState } from "fu/stores/reducers"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import React, { FC, useState, useCallback, ChangeEvent, useMemo, useContext } from "react"
import { useSelector } from "react-redux"
import { useTranslation, Trans } from "react-i18next"
import { useAppDispatch } from "fu/stores"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Editor from 'react-simple-wysiwyg';
import styled from "styled-components"
import { IThreadMedia } from "fu/models/thread"
import updateThread from "fu/stores/actions/thread/updateThread"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  background: ${({ theme }) => theme.white};
  padding: 14px 0;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  user-select: none;
  padding: 0 18px;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const EditorInputLabel = styled.p`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-bottom: 8px;
`

const InputSpacer = styled.div`
  margin: 18px;
  margin-bottom: 0px;
`

const AttachmentInputContainer = styled.label`
  display: flex;
  flex-direction: row;
  margin-left: 18px;
  cursor: pointer;
`

const AttachmentInputText = styled.p`
  font-size: 14px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
`

const AttachmentInputIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
`

const AttachmentInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`

const AttachedFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 18px;
  margin-bottom: 18px;
`

const AttachedFileContainer = styled.div`
  position: relative;
  margin-right: 18px;
  cursor: pointer;
`

const AttachedFileDelete = styled.div`
  position: absolute;
  left: 45px;
  top: -10px;
  z-index: 50;
  cursor: pointer;
`

const AttachedFileAssetIcon = styled(FuAsset)`
  width: 50px;
  height: 50px;
`

const AttachedFileImageIcon = styled.img`
  width: 50px;
  height: 50px;
`

const SavedFileImageIcon = styled(FuImage)`
  width: 50px;
  height: 50px;
`

const TncText = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Poppins-Regular, sans-serif;
  margin-bottom: 20px;
  margin-left: 18px;
  margin-right: 18px;
`

const TncTextActive = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Medium, sans-serif;
  cursor: pointer;
`

const MainButtonContainer = styled.div`
  margin: 0 18px;
`

const PreviewImage = styled(FuImage)`
  max-width: 400px;
`

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const previewImageStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderWidth: 0,
    borderRadius: 0
  },
  overlay: {
    backgroundColor: defaultTheme.black,
    zIndex: 999
  }
}

interface Form {
  title: string
  content: string
  files: Array<File | null>
}

const inputTitle = { name: "title" }

const {
  PRIVACY_POLICY: { path: PrivacyPolicyPath },
  TERMS_AND_CONDITIONS: { path: TnCPath }
} = Paths

const ViewForumDiscussionAdd: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    handleShowPointReward,
    handleShowCurrentPoint,
    handleShowPointRewardTitle
  }:
    {
      handleShowPointReward: () => void,
      handleShowCurrentPoint: (val: number) => void,
      handleShowPointRewardTitle: (val: string) => void
    } =
    useContext(GeneralContext)
  const { slug } = useParams()
  const { state: { detail = {}, thread = {}, isUpdate = false } } = useLocation()
  const { loading } = useSelector((state: AppState) => state.thread.createThread)
  const [form, setForm] = useState<Form>({
    title: isUpdate ? thread.title : "",
    content: isUpdate ? thread.content : "",
    files: []
  })
  const [savedFiles, setSavedFiles] = useState<Array<any>>(isUpdate ? thread.media : [])
  const [deletedSavedFiles, setDeletedSavedFiles] = useState<Array<string>>([])
  const [showPreviewImage, setShowPreviewImage] = useState<string | File | null>(null)

  const handleShowPreviewImage = useCallback((itemIdt?: string | File) => () => {
    if (itemIdt) return setShowPreviewImage(itemIdt)

    setShowPreviewImage(null)
  }, [])

  const handleNavigateTermsAndPolicy = useCallback((route: string) => () => {
    navigate(route);
  }, [navigate])

  const handlePageBack = () => {
    navigate(-1)
  }

  const handleChangeInput = useCallback((
    name: string, value: string | boolean | File | null
  ) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [setForm])

  const handleOnChangeFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    setForm(prevState => ({
      ...prevState,
      files: [...prevState.files, file]
    }))
  }, [setForm])

  const handleDeleteFile = useCallback((index: number) => (e: any) => {
    e.stopPropagation()

    setForm(prevState => ({
      ...prevState,
      files: prevState.files.filter((_, idxFile) => idxFile !== index)
    }))
  }, [setForm, form])

  const handleDeletedSavedFiles = useCallback((index: number) => (e: any) => {
    e.stopPropagation()

    const idMedia = savedFiles[index].id_media_thread
    setDeletedSavedFiles([...deletedSavedFiles, idMedia])

    setSavedFiles(prevState => ([
      ...prevState.filter((_, idxFile) => idxFile !== index)
    ]))
  }, [deletedSavedFiles, setSavedFiles])

  const handleCreateThread = useCallback(async () => {
    if (!form.title.length) {
      handleToast("error", t("forumDiscussionsForm.noSubject"))
    } else if (!form.content.length) {
      handleToast("error", t("forumDiscussionsForm.noDiscussion"))
    } else {
      let formData = new FormData()
      let sValue = form.content
      if (typeof sValue === "string") {
        sValue = sValue.replace(new RegExp(/href="(.*?)"/, 'g'), function(_, v) {
          if (!v.startsWith("https://") && !v.startsWith("http://")) {
            let text ='style="color : #0037FF" href = "https://' + v + '"'
            return text
          }else{
            return 'style="color : #0037FF" href = "' + v + '"'
          }
        })
      }

      if (isUpdate) {
        formData.append("id_thread", thread.id_thread || "")
        if (deletedSavedFiles.length > 0) {
          formData.append("deleted_files", deletedSavedFiles.join(','))
        }
      } else {
        formData.append("id_community", slug || "")
      }
      formData.append("title", form.title)
      formData.append("content", sValue)
      if (form.files.length > 0) {
        for (let i = 0; i < form.files.length; i++) {
          formData.append(`files[${i}]`, (form.files[i] as any))
        }
      }

      const result = isUpdate ?
        await dispatch(updateThread(formData)) :
        await dispatch(createThread(formData))

      if (result.isSuccess) {
        navigate(-1)
        handleToast("success", result.message)
        if (!isUpdate) {
          handleShowCurrentPoint(result.points.poin.point)
          handleShowPointRewardTitle(result.points.poin.point_activity)
          handleShowPointReward()
        }
      } else {
        handleToast("error", result.message)
      }
    }
  }, [form, slug, detail, isUpdate, thread, deletedSavedFiles])

  const renderLoaderModal = useMemo(() => loading && (
    <FuModal
      isOpen={loading}
      onClose={() => { }}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loading])

  const handleOpenAttachedFile = useCallback((link: string | File) => () => {
    if (typeof link === "string") {
      window.open(`${IMAGE_URL}/upload/thread/${link}`, '_blank', 'noopener,noreferrer')
    } else {
      window.open(URL.createObjectURL(link), '_blank', 'noopener,noreferrer')
    }
  }, [])

  const renderAttachedFiles = useCallback((file: File | null, index: number) => {
    if (file?.name.includes("png") ||
      file?.type.includes("png") ||
      file?.name.includes("jpeg") ||
      file?.type.includes("jpeg") ||
      file?.name.includes("jpg") ||
      file?.type.includes("jpg")) {
      return (
        <AttachedFileContainer onClick={handleShowPreviewImage(file)}>
          <AttachedFileImageIcon
            src={URL.createObjectURL(file)}
            alt={"Image-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (file?.name.includes("doc") ||
      file?.type.includes("doc") ||
      file?.name.includes("docx") ||
      file?.type.includes("docx")) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file)}>
          <AttachedFileAssetIcon
            source={doc}
            alt={"Document-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("pdf") ||
      file?.type.includes("pdf")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file)}>
          <AttachedFileAssetIcon
            source={pdf}
            alt={"Pdf-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("xls") ||
      file?.type.includes("xls") ||
      file?.name.includes("xlsx") ||
      file?.type.includes("xlsx")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file)}>
          <AttachedFileAssetIcon
            source={xls}
            alt={"Excel-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("ppt") ||
      file?.type.includes("ppt") ||
      file?.name.includes("pptx") ||
      file?.type.includes("pptx")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file)}>
          <AttachedFileAssetIcon
            source={ppt}
            alt={"Ppt-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("avi") ||
      file?.type.includes("avi") ||
      file?.name.includes("wav") ||
      file?.type.includes("wav") ||
      file?.name.includes("mp4") ||
      file?.type.includes("mp4") ||
      file?.name.includes("mp3") ||
      file?.type.includes("mp3")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file)}>
          <AttachedFileAssetIcon
            source={video}
            alt={"Video-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    }
  }, [handleDeleteFile, handleOpenAttachedFile])

  const renderSavedFiles = useCallback((file: IThreadMedia | null, index: number) => {
    if (file?.media.includes("png") ||
      file?.media.includes("jpeg") ||
      file?.media.includes("jpg")) {
      return (
        <AttachedFileContainer onClick={handleShowPreviewImage(file.media)}>
          <SavedFileImageIcon
            source={`upload/thread/${file.media}`}
            alt={"Media-File"}
          />
          <AttachedFileDelete onClick={handleDeletedSavedFiles(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (file?.media.includes("doc") ||
      file?.media.includes("docx")) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file.media)}>
          <AttachedFileAssetIcon
            source={doc}
            alt={"Document-File"}
          />
          <AttachedFileDelete onClick={handleDeletedSavedFiles(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (file?.media.includes("pdf")) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file.media)}>
          <AttachedFileAssetIcon
            source={pdf}
            alt={"Pdf-File"}
          />
          <AttachedFileDelete onClick={handleDeletedSavedFiles(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.media.includes("xls") ||
      file?.media.includes("xlsx")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file.media)}>
          <AttachedFileAssetIcon
            source={xls}
            alt={"Excel-File"}
          />
          <AttachedFileDelete onClick={handleDeletedSavedFiles(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.media.includes("ppt") ||
      file?.media.includes("pptx")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file.media)}>
          <AttachedFileAssetIcon
            source={ppt}
            alt={"Ppt-File"}
          />
          <AttachedFileDelete onClick={handleDeletedSavedFiles(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.media.includes("avi") ||
      file?.media.includes("wav") ||
      file?.media.includes("mp4") ||
      file?.media.includes("mp3")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(file.media)}>
          <AttachedFileAssetIcon
            source={video}
            alt={"Video-File"}
          />
          <AttachedFileDelete onClick={handleDeletedSavedFiles(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    }
  }, [handleDeletedSavedFiles, handleOpenAttachedFile])

  const renderPreviewImage = useMemo(() => showPreviewImage && (
    <FuModal
      customStyles={previewImageStyles}
      isOpen={showPreviewImage ? true : false}
      onClose={handleShowPreviewImage()}
      children={
        <PreviewImage
          source={
            typeof showPreviewImage === "string" ?
              `upload/thread/${showPreviewImage}` :
              URL.createObjectURL(showPreviewImage)
          }
          alt="preview-image"
        />
      }
    />
  ), [showPreviewImage, handleShowPreviewImage])

  return (
    <Container>
      <Content>
        <Header>
          <IconHeader onClick={handlePageBack}>
            <FuIconArrowLeft />
          </IconHeader>
          <TextHeader>
            {
              isUpdate ?
                t("forumDiscussionsForm.editTitle") :
                t("forumDiscussionsForm.title")
            }
          </TextHeader>
        </Header>
        <InputSpacer>
          <FuInputLabel
            input={inputTitle}
            type={"text"}
            label={t("forumDiscussionsForm.subjectLabel")}
            placeholder={t("forumDiscussionsForm.subjectPlaceholder")}
            value={form.title}
            onChange={handleChangeInput}
          />
        </InputSpacer>
        <InputSpacer>
          <EditorInputLabel>
            {t("forumDiscussionsForm.discussionLabel")}
          </EditorInputLabel>
          <Editor
            containerProps={{ style: { resize: 'vertical' } }}
            value={form.content}
            placeholder={t("forumDiscussionsForm.discussionPlaceholder") || ""}
            onChange={(e) => handleChangeInput("content", e.target.value)}
          />
        </InputSpacer>
        <AttachmentInputContainer htmlFor="add-single-img">
          <AttachmentInputText>
            {t("forumDiscussionsForm.discussionAttachment")}
          </AttachmentInputText>
          <AttachmentInputIcon>
            <FuIconAdd />
          </AttachmentInputIcon>
          <AttachmentInput
            type="file"
            id="add-single-img"
            accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, .mp4, .mp3, .ppt, .pptx, .avi, .wav"
            onChange={handleOnChangeFile}
          />
        </AttachmentInputContainer>
        {(form.files.length > 0 || savedFiles.length > 0) && (
          <AttachedFilesContainer>
            {savedFiles.length > 0 && savedFiles.map((media, index) => (
              <div key={index}>{renderSavedFiles(media, index)}</div>
            ))}
            {form.files.length > 0 && form.files.map((file, index) => (
              <div key={index}>{renderAttachedFiles(file, index)}</div>
            ))}
          </AttachedFilesContainer>
        )}
        <TncText>
          <Trans
            i18nKey="login.tnc"
            components={{
              policy:
                <TncTextActive
                  onClick={
                    handleNavigateTermsAndPolicy(PrivacyPolicyPath)
                  }
                />,
              tnc:
                <TncTextActive
                  onClick={
                    handleNavigateTermsAndPolicy(TnCPath)
                  }
                />
            }}
          />
        </TncText>
        <MainButtonContainer>
          <FuButton
            button={mainButton}
            text={
              isUpdate ?
                t("forumDiscussionsForm.editMainButton") :
                t("forumDiscussionsForm.mainButton")
            }
            onClick={handleCreateThread}
          />
        </MainButtonContainer>
      </Content>
      {renderLoaderModal}
      {renderPreviewImage}
    </Container>
  )
}

export default ViewForumDiscussionAdd
