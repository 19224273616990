import React, { FC, CSSProperties } from "react"
import Modal from "react-modal";
import { defaultTheme } from "../FuApp/FuTheme";

interface Styles {
  content?: CSSProperties | undefined
  overlay?: CSSProperties | undefined
}

interface Props {
  isOpen: boolean
  onClose: () => void
  customStyles?: Styles
  children: JSX.Element
}

const defaultCustomModalStyles: Partial<Styles> = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
  }
};

const FuModal: FC<Props> = ({
  isOpen,
  onClose,
  customStyles,
  children
}) => {
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      style={customStyles || defaultCustomModalStyles}
    >
      {children}
    </Modal>
  )
}

export default FuModal
