import { Dispatch } from "redux"
import {
  timelineUpdateService,
  ReqTimelineUpdate,
  ResTimelineUpdate
} from "fu/services/timeline"

const STATE_BEGIN = "TIMELINE_UPDATE_BEGIN"
const STATE_END = "TIMELINE_UPDATE_END"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResTimelineUpdate
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: ReqTimelineUpdate) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await timelineUpdateService(body)
    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
