import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconEditPost: FC<IIcon> = ({ size = "15", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3333 13.3333V1.66667H1.66667V13.3333H13.3333ZM13.3333 0C13.7754 0 14.1993 0.175595 14.5118 0.488155C14.8244 0.800716 15 1.22464 15 1.66667V13.3333C15 14.2583 14.25 15 13.3333 15H1.66667C1.22464 15 0.800716 14.8244 0.488155 14.5118C0.175595 14.1993 0 13.7754 0 13.3333V1.66667C0 1.22464 0.175595 0.800716 0.488155 0.488155C0.800716 0.175595 1.22464 0 1.66667 0H13.3333ZM11.4167 5.29167L10.5833 6.125L8.875 4.41667L9.70833 3.58333C9.88333 3.4 10.175 3.4 10.35 3.58333L11.4167 4.65C11.6 4.825 11.6 5.11667 11.4167 5.29167ZM3.33333 9.95L8.38333 4.9L10.1 6.61667L5.05 11.6667H3.33333V9.95Z" fill="#FF212A"/>
    </Icon>
  )
}

export default FuIconEditPost