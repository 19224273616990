import { myCommunityService, ReqListCommunity, ReqMyCommunity, ResListCommunity } from "fu/services/community"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "MY_COMMUNITY_BEGIN"
const STATE_SUCCESS = "MY_COMMUNITY_SUCCESS"
const STATE_FAILED = "MY_COMMUNITY_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListCommunity
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListCommunity) => async (dispatch: Dispatch, getState: () => RootState) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const { user: { id_user } } = getState().auth.authenticate
    const payload: ReqMyCommunity = {...body, id_user }

    const response = await myCommunityService(payload)

    dispatch({
      type: STATE_SUCCESS,
      payload: response
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
