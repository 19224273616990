import { IThread } from "fu/models/thread"
import { StateActions } from "fu/stores/actions/thread/listThread"

type State = {
  loading: boolean
  threads: Array<IThread>
  error: any
}

const initialState: State = {
  loading: true,
  threads: [],
  error: null
}

const listThreadReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_THREAD_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_THREAD_SUCCESS":
      return {
        ...state,
        loading: false,
        threads: action.payload.data
      }
    case "LIST_THREAD_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listThreadReducer