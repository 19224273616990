import React, { useState, createContext, FC, useCallback } from 'react';

const GeneralContext: any = createContext(null);

const GeneralProvider: FC<any> = ({ children }) => {
  const [currentMenuHome, setCurrentMenuHome] = useState<0 | 1>(0)
  const [currentMenu, setCurrentMenu] = useState<number>(0)
  const [fcmToken, setFcmToken] = useState<string>("")
  const [showPointReward, setShowPointReward] = useState<boolean>(false)
  const [showPointRewardTitle, setShowPointRewardTitle] = useState<string>("")
  const [currentShowPoint, setShowCurrentShowPoint] = useState<number>(0)

  const handleSetCurrentMenuHome = useCallback(() => {
    if (currentMenuHome === 0) {
      setCurrentMenuHome(1)
    } else {
      setCurrentMenuHome(0)
    }
  }, [currentMenuHome])

  const handleShowPointReward = useCallback(() => {
    if (showPointReward) {
      setShowPointRewardTitle("")
      setShowCurrentShowPoint(0)
    }
    setShowPointReward(!showPointReward)
  }, [showPointReward])

  const handleShowPointRewardTitle = useCallback((title: string) => {
    setShowPointRewardTitle(title)
  }, [])

  const handleShowCurrentPoint = useCallback((point: number) => {
    setShowCurrentShowPoint(point)
  }, [])

  const handleSetCurrentMenu = useCallback((id: number) => () => {
    setCurrentMenu(id)
  }, [])

  const handleSetFcmToken = useCallback((token: string) => {
    setFcmToken(token)
  }, [])

  return (
    <GeneralContext.Provider
      value={{
        currentMenu,
        handleSetCurrentMenu,
        fcmToken,
        handleSetFcmToken,
        showPointReward,
        handleShowPointReward,
        currentShowPoint,
        handleShowCurrentPoint,
        showPointRewardTitle,
        handleShowPointRewardTitle,
        currentMenuHome,
        handleSetCurrentMenuHome
      }}>
      {children}
    </GeneralContext.Provider>
  );
};

export { GeneralContext, GeneralProvider };
