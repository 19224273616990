import {
  listCommunitySearchService,
  ReqListCommunitySearch,
  ResListCommunity
} from "fu/services/community"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_COMMUNITY_BEGIN"
const STATE_SUCCESS = "LIST_COMMUNITY_SUCCESS"
const STATE_FAILED = "LIST_COMMUNITY_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListCommunity
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListCommunitySearch) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await listCommunitySearchService(body)

    dispatch({
      type: STATE_SUCCESS,
      payload: response
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
