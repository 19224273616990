import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconMoveFolder: FC<IIcon> = ({ size = "16", color, className = "" }) => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15_597)">
      <path d="M13.5 11.125H1.5V3.625H13.5V11.125ZM7.5 2.125L6 0.625H1.5C0.675 0.625 0 1.2925 0 2.125V11.125C0 11.9575 0.675 12.625 1.5 12.625H13.5C14.3325 12.625 15 11.9575 15 11.125V3.625C15 2.8 14.3325 2.125 13.5 2.125H7.5ZM6.75 8.125V6.625H9.75V4.375L12.75 7.375L9.75 10.375V8.125H6.75Z" fill="#FF212A"/>
      </g>
      <defs>
      <clipPath id="clip0_15_597">
      <rect width="15" height="12.5" fill="white" transform="translate(0 0.625)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default FuIconMoveFolder
