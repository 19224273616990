import { IUser } from "fu/models/user"
import client from "./client"
import formatError from "./error"
import { ICommunity, CommunityDetail, ICommunityByTopic, CommunityMember } from "fu/models/community"

export interface ResListCommunity {
  status: boolean
  data: Array<ICommunity>
}

export interface ReqListCommunity {
  limit: number
  current_page: number
}

export interface ReqListCommunitySearch {
  title: string
}

export interface ReqMyCommunity {
  limit: number
  current_page: number
  id_user: number
}

export interface ReqCommunityCreate {
  title: string
  image: File | null
  description: string
  id_user: number
  topics: string
  join_need_approval: boolean
}

export interface ResCommunityCreate {
  status: boolean
  message: string
}

export interface ReqUpdateCommunity extends ReqCommunityCreate {
  id_community: string
}

export interface ResUpdateCommunity {
  status: boolean
  message: string
}

export interface ReqCommunityDetail {
  id_community: string
  id_user: string
}

export interface ResCommunityDetail {
  status: boolean
  data: CommunityDetail
}

export interface ReqListCommunityByTopic {
  id_topic: string
  limit: number
  current_page: number
  id_user: string
}

export interface ResListCommunityByTopic {
  status: boolean
  data: Array<ICommunityByTopic>
}

export interface ReqJoinCommunity {
  id_community: string
  id_user: string
}

export interface ResJoinCommunity {
  status: boolean
  message: string
}

export interface ReqLeaveCommunity {
  id_user: string
  id_community: string
}

export interface ResLeaveCommunity {
  status: boolean
  message: string
}

export interface ReqListUserJoinRequest {
  id_user: string
  id_community: string
  limit: number
  current_page: number
}

export interface ResListUserJoinRequest {
  status: boolean
  data: Array<CommunityMember>
}

export interface ReqActionUserJoinRequest {
  id_logged_user: string
  id_request: string
  action: "accept" | "reject"
  reason: string
}

export interface ResActionUserJoinRequest {
  status: boolean
  message: string
}

export const listCommunityService =
  (body: ReqListCommunity): Promise<ResListCommunity> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListCommunity>("community", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const listCommunityByTopicService =
  (body: ReqListCommunityByTopic): Promise<ResListCommunityByTopic> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListCommunityByTopic>("community/by_topic", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const communityDetailService =
  (body: ReqCommunityDetail): Promise<ResCommunityDetail> => new Promise(async (resolve, reject) => {
    try {
      const { id_community, id_user } = body
      const params = `id_community=${id_community}&id_user=${id_user}`
      
      const response = (await client.get<ResCommunityDetail>(`community/detail?${params}`)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const communityCreateService = 
  (body: ReqCommunityCreate): Promise<ResCommunityCreate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResCommunityCreate>("community/create", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const listCommunitySearchService =
  (body: ReqListCommunitySearch): Promise<ResListCommunity> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListCommunity>("community/search", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const myCommunityService =
  (body: ReqMyCommunity): Promise<ResListCommunity> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListCommunity>("community/joined", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const joinCommunityService = 
  (body: ReqJoinCommunity): Promise<ResJoinCommunity> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResJoinCommunity>("community/join", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const joinPrivateCommunityService = 
  (body: ReqJoinCommunity): Promise<ResJoinCommunity> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResJoinCommunity>("community/req_join", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const cancelJoinCommunityService = 
  (body: ReqJoinCommunity): Promise<ResJoinCommunity> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResJoinCommunity>("community/cancel_req_join", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })
  
export const leaveCommunityService =
  (body: ReqLeaveCommunity): Promise<ResLeaveCommunity> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResLeaveCommunity>("community/leave", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const updateCommunityService =
  (body: ReqUpdateCommunity): Promise<ResUpdateCommunity> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResUpdateCommunity>("community/edit", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const listUserJoinRequestService =
  (body: ReqListUserJoinRequest): Promise<ResListUserJoinRequest> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListUserJoinRequest>("community/detail/request_join", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const actionUserJoinRequestService =
  (body: ReqActionUserJoinRequest): Promise<ResActionUserJoinRequest> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResActionUserJoinRequest>("community/action_req_join", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})
