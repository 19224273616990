import { ResUserProfile } from "fu/services/user"
import { StateActions } from "fu/stores/actions/user/detailUser"

type State = {
  loading: boolean
  user: ResUserProfile | null
  error: any
}

const initialState: State = {
  loading: true,
  user: null,
  error: null
}

const userDetailReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "DETAIL_USER_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "DETAIL_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        user: action.payload
      }
    case "DETAIL_USER_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default userDetailReducer