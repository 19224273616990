import { listThreadService, ReqListThread, ResListThread } from "fu/services/thread"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_THREAD_BEGIN"
const STATE_SUCCESS = "LIST_THREAD_SUCCESS"
const STATE_FAILED = "LIST_THREAD_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListThread
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListThread) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const items = getState().timeline.listTimeline.timeline
      dispatch({ type: STATE_BEGIN })

      const response = await listThreadService(body)

      dispatch({
        type: STATE_SUCCESS,
        payload:
          body.current_page === 1
            ? response
            : { data: [...items, ...response.data] }
      })
    } catch (error: any) {
      dispatch({
        type: STATE_FAILED,
        error,
      })
    }
  }
