import { IUser } from "fu/models/user"
import { StateActions } from "fu/stores/actions/auth/authenticate"
import { AuthStatus } from "fu/models/auth"

type State = {
  status: AuthStatus
  token: string
  user: IUser | null
}

const initialState: State = {
  status: "checking",
  token: "",
  user: null
}

const authenticateReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "AUTHENTICATE_BEGIN":
      return {
        ...state,
        status: "checking"
      }
    case "AUTHENTICATE_SUCCESS":
      return {
        ...state,
        status: "authenticated",
        token: action.payload.token,
        user: action.payload.user
      }
    case "AUTHENTICATE_FAILED":
      return {
        ...state,
        status: "no-authenticated",
        token: "",
        user: null
      }
    default:
      return state
  }
}

export default authenticateReducer