import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconMenu: FC<IIcon> = ({ width = "3", height = "14", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 5.45815C0.675 5.45815 0 6.14775 0 6.99059C0 7.83343 0.675 8.52302 1.5 8.52302C2.325 8.52302 3 7.83343 3 6.99059C3 6.14775 2.325 5.45815 1.5 5.45815ZM1.5 0.86084C0.675 0.86084 0 1.55044 0 2.39328C0 3.23612 0.675 3.92571 1.5 3.92571C2.325 3.92571 3 3.23612 3 2.39328C3 1.55044 2.325 0.86084 1.5 0.86084ZM1.5 10.0555C0.675 10.0555 0 10.7451 0 11.5879C0 12.4307 0.675 13.1203 1.5 13.1203C2.325 13.1203 3 12.4307 3 11.5879C3 10.7451 2.325 10.0555 1.5 10.0555Z" fill={color} />
    </Icon>
  )
}

export default FuIconMenu
