import faImage from "fu/assets/images/login"
import { ESizes, ETypes, FuButton, IButton } from "fu/components/FuButton"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import { FuModal } from "fu/components/FuModal"
import { FuLoader } from "fu/components/FuLoader"
import { KeyboardEvent } from "fu/components/FuInput/types"
import { FuInputLabel } from "fu/components/FuInput"
import { FuAsset } from "fu/components/FuImage"
import { MenuTab, Tabs, TabsProps, AppStores, LoginInputForm } from "fu/models/auth"
import { useAppDispatch } from "fu/stores"
import loginAction from "fu/stores/actions/auth/login"
import { AppState } from "fu/stores/reducers"
import { Paths } from "fu/routes/paths"
import { handleToast } from "fu/utils/toast"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import React, { useCallback, useState, FC, useMemo, useEffect, useContext } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { usePasswordToggler } from "fu/hooks/usePasswordToggler"
import { eyeHide, eyeShow } from "fu/assets/images/general"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-image: url(${faImage.background}), linear-gradient(161.76deg, #FDFBFE 13.46%, #F2F0F3 47.48%, #F2F0F3 47.48%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  justify-content: flex-end;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`

const TextTitle = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.textDark};
  font-family: Poppins-Medium, sans-serif;
`

const TextSub = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textDark};
  font-family: Poppins-Medium, sans-serif;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 26px 24px;
  background: ${({ theme }) => theme.white};
  border-radius: 12px 12px 0 0;
`

const FormAction = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const TextTnc = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Roboto-Regular, sans-serif;
`

const TextTncActive = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Roboto-Medium, sans-serif;
  cursor: pointer;
`

// const TextForgot = styled.span`
//   font-size: 14px;
//   color: ${({ theme }) => theme.greyLabel};
//   font-family: Roboto-Bold, sans-serif;
//   cursor: pointer;
//   width: fit-content;
// `

const FormRegister = styled(FormAction)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const TextRegister = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Roboto-Medium, sans-serif;
`

const TextRegisterActive = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Roboto-Medium, sans-serif;
  cursor: pointer;
`

const TabsContainer = styled.div`
  display: flex;
`

const TabItemContainer = styled.div<TabsProps>`
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  border-bottom: ${({ theme, isHighlight }) =>
    `${isHighlight ? `2px solid ${theme.primaryMain}` : ""}`};
`

const TabItemText = styled.div<TabsProps>`
  font-size: 13px;
  font-family: ${({ isHighlight }) =>
    `${isHighlight ? "Roboto-Medium" : "Roboto-Regular"}, sans-serif`};
  color: ${({ theme, isHighlight }) =>
    `${isHighlight ? `${theme.primaryMain}` : `${theme.greyLight}`}`};
  padding-bottom: 8px;
`

const DownloadAppContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DownloadAppTitle = styled.p`
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Roboto-Medium, sans-serif;
`
const DownloadAppDesc = styled.p`
  font-size: 14px;
  text-align: center;
  font-family: Roboto-Regular, sans-serif;
  margin-top: 10px;
`
const DownloadAppStores = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const DownloadAppStore = styled(FuAsset)`
  cursor: pointer;
  width: 136px;
  height: 48px;
`

const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;
`

const menuTabs: Array<MenuTab> = [
  {
    value: "email",
    text: "login.email"
  },
  {
    value: "phone",
    text: "login.phone"
  }
]

const appStores: Array<AppStores> = [
  {
    alt: "Apple-App-Store",
    source: faImage.appstore,
    link: "https://apps.apple.com/lk/app/purposeful-people/id1602236710"
  },
  {
    alt: "Google-Play-Store",
    source: faImage.playstore,
    link: "https://play.google.com/store/apps/details?id=id.lima.purposefulpeople"
  }
]

const buttonLogin = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const inputEmail = {
  name: "email"
}

const inputPhone = {
  name: "phone"
}

const inputPassword = {
  name: "password"
}

const {
  PRIVACY_POLICY: { path: PrivacyPolicyPath },
  TERMS_AND_CONDITIONS: { path: TnCPath }
} = Paths

const ViewLogin: FC = () => {
  const { t } = useTranslation()
  const { type, passwordVisibility, handlePasswordVisibility } = usePasswordToggler()
  const { fcmToken }: { fcmToken: string } = useContext(GeneralContext)
  const navigate = useNavigate();
  const { loading } = useSelector((state: AppState) => state.auth.login)
  const dispatch = useAppDispatch()
  const [form, setForm] = useState<LoginInputForm>({
    email: "",
    phone: "",
    password: ""
  })
  const [tab, setTab] = useState<Tabs>("email")
  const [showDownloadApp, setShowDownloadApp] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(true)

  useEffect(() => {
    const { email, phone, password } = form

    if (tab === "email") {
      setDisableButton(!email || !password)
    } else {
      setDisableButton(!phone || !password)
    }
  }, [tab, form])

  const handleChangeInput = useCallback((name: string, value: string) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [setForm])

  const handleSwitchTab = useCallback((value: Tabs) => () => {
    setTab(value)
  }, [])

  const handleShowDownloadApp = useCallback(() => {
    setShowDownloadApp(!showDownloadApp)
  }, [showDownloadApp])

  const handleOpenStore = useCallback((link: string) => () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }, [])

  const handleNavigateTermsAndPolicy = useCallback((route: string) => () => {
    navigate(route);
  }, [navigate]);

  const handleLoginError = useCallback((error: any) => {
    if (error) {
      handleToast("error", error.message)
    }
  }, [])

  const handleLogin = useCallback(async () => {
    if (!loading) {
      const modifiedForm = {
        email_phone: (tab === "email" ? form.email : form.phone) || "",
        password: form.password,
        fcm_token: fcmToken
      }

      const error = await dispatch(loginAction(modifiedForm))

      handleLoginError(error)
    }
  }, [form, loading, dispatch, loginAction, tab, handleLoginError, fcmToken])

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.code === "Enter" && !disableButton) {
      handleLogin();
    }
  }, [handleLogin, disableButton])

  const renderTabs: JSX.Element = useMemo(() => (
    <TabsContainer>
      {
        menuTabs.map((menu, index) => (
          <TabItemContainer
            onClick={handleSwitchTab(menu.value)}
            key={index}
            isHighlight={tab === menu.value}
          >
            <TabItemText isHighlight={tab === menu.value}>
              {t(menu.text)}
            </TabItemText>
          </TabItemContainer>
        ))
      }
    </TabsContainer>
  ), [tab])

  const renderShowDownloadAppModalContent: JSX.Element = useMemo(() => (
    <DownloadAppContentContainer>
      <DownloadAppTitle>{t("login.downloadAppTitle")}</DownloadAppTitle>
      <DownloadAppDesc>{t("login.downloadAppDesc")}</DownloadAppDesc>
      <DownloadAppStores>
        {appStores.map(({ source, alt, link }, index) => (
          <DownloadAppStore
            key={index}
            source={source}
            alt={alt}
            onClick={handleOpenStore(link)}
          />
        ))}
      </DownloadAppStores>
    </DownloadAppContentContainer>
  ), [handleOpenStore])

  const renderShowDownloadAppModal: JSX.Element =
    useMemo(() => showDownloadApp ? (
      <FuModal
        isOpen={showDownloadApp}
        onClose={handleShowDownloadApp}
        children={renderShowDownloadAppModalContent}
      />
    ) : <></>, [showDownloadApp, handleShowDownloadApp])

  const renderTopInput = useMemo(() => (
    tab === "email" ? (
      <FuInputLabel
        onKeyPress={handleKeyPress}
        input={inputEmail}
        type={"text"}
        label={t("form.email")}
        placeholder={t("form.emailPlaceholder")}
        value={form.email || ""}
        onChange={handleChangeInput}
      />
    ) : (
      <FuInputLabel
        onKeyPress={handleKeyPress}
        input={inputPhone}
        prepend={<p>+</p>}
        type={"text"}
        label={t("form.phone")}
        placeholder={t("form.phonePlaceholder")}
        value={form.phone || ""}
        onChange={handleChangeInput}
      />
    )
  ), [
    tab,
    inputPhone,
    form.phone,
    inputEmail,
    form.email,
    handleChangeInput,
    handleKeyPress
  ])

  const renderMainButton = useMemo(() => {
    let dynamicButtonLogin: IButton = { ...buttonLogin };
    if (disableButton) {
      dynamicButtonLogin["color"] = "greyLight"
    }

    return (
      <FuButton
        button={dynamicButtonLogin}
        text={t("form.login")}
        onClick={handleLogin}
        isDisabled={disableButton}
      />
    )
  }, [buttonLogin, handleLogin, disableButton])

  const renderLoaderModal = useMemo(() => loading && (
    <FuModal
      isOpen={loading}
      onClose={() => { }}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [loading])

  return (
    <Container>
      <Content>
        <Header>
          <TextTitle>
            <Trans i18nKey="login.title" components={{ br: <br /> }} />
          </TextTitle>
          <TextSub>{t("login.sub")}</TextSub>
        </Header>
        <Form>
          {renderTabs}
          {renderTopInput}
          <FuInputLabel
            type={type}
            onKeyPress={handleKeyPress}
            input={inputPassword}
            label={t("form.password")}
            placeholder={t("form.passwordPlaceholder")}
            value={form.password}
            onChange={handleChangeInput}
            append={
              passwordVisibility
                ? <Image
                  src={eyeShow}
                  onClick={handlePasswordVisibility}
                />
                : <Image
                  src={eyeHide}
                  onClick={handlePasswordVisibility}
                />
            }
          />
          <TextTnc>
            <Trans
              i18nKey="login.tnc"
              components={{
                policy:
                  <TextTncActive
                    onClick={
                      handleNavigateTermsAndPolicy(PrivacyPolicyPath)
                    }
                  />,
                tnc:
                  <TextTncActive
                    onClick={
                      handleNavigateTermsAndPolicy(TnCPath)
                    }
                  />
              }}
            />
          </TextTnc>
          <FormAction>
            {renderMainButton}
          </FormAction>
          {/* <TextForgot>{t("form.forgotPassword")}</TextForgot> */}
          <FormRegister>
            <TextRegister onClick={handleShowDownloadApp}>
              <Trans
                i18nKey="form.notHaveAccount"
                components={{ register: <TextRegisterActive /> }}
              />
            </TextRegister>
          </FormRegister>
        </Form>
      </Content>
      {renderShowDownloadAppModal}
      {renderLoaderModal}
    </Container>
  )
}

export default ViewLogin