import { ITimelineComment } from "fu/models/timeline"
import { StateActions } from "fu/stores/actions/timeline/listTimelineComment"

type State = {
  loading: boolean
  comment: Array<ITimelineComment>
  error: any
}

const initialState: State = {
  loading: true,
  comment: [],
  error: null
}

const listTimelineCommentReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_TIMELINE_COMMENT_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_TIMELINE_COMMENT_SUCCESS":
      return {
        ...state,
        loading: false,
        comment: action.payload.data
      }
    case "LIST_TIMELINE_COMMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listTimelineCommentReducer
