import React, { useState } from "react"
import Favicon from "react-favicon"
import { createRoot } from "react-dom/client"
import { ThemeProvider } from "styled-components"
import { Provider } from "react-redux"
import { defaultTheme, GlobalStyles } from "fu/components/FuApp/FuTheme"
import { GeneralProvider } from "./contexts/GeneralProvider"
import AppRoutes from "fu/routes"
import stores from "fu/stores"

import "./utils/firebase"
import "./utils/i18n"

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GeneralProvider>
        <Provider store={stores}>
          <GlobalStyles />
          <AppRoutes />
        </Provider>
      </GeneralProvider>
    </ThemeProvider>
  )
}

const root = createRoot(document.getElementById("app") as HTMLElement)

root.render(
  <div>
    <Favicon url="https://i.ibb.co/PF0sQD0/favicon-1.png"/>
    <App />
  </div>
)
