import { combineReducers } from "redux"
import listCommunityReducer from "./listCommunity"
import myCommunityReducer from "./myCommunity"
import createCommunityReducer from "./createCommunity"
import detailCommunityReducer from "./detailCommunity"
import listCommunityByTopicReducer from "./listCommunityByTopic"
import joinCommunityReducer from "./joinCommunity"
import joinPrivateCommunityReducer from "./joinPrivateCommunity"
import cancelJoinCommunityReducer from "./cancelCommunity"
import leaveCommunityReducer from "./leaveCommunity"
import updateCommunityReducer from "./updateCommunity"
import listUserJoinRequestReducer from "./listUserJoinRequest"
import actionUserJoinRequestReducer from "./actionUserJoinRequest"

const communityReducer = combineReducers({
  listCommunity: listCommunityReducer,
  myCommunity: myCommunityReducer,
  createCommunity: createCommunityReducer,
  detailCommunity: detailCommunityReducer,
  listCommunityByTopic: listCommunityByTopicReducer,
  joinCommunity: joinCommunityReducer,
  joinPrivateCommunity: joinPrivateCommunityReducer,
  cancelJoinCommunity: cancelJoinCommunityReducer,
  leaveCommunity: leaveCommunityReducer,
  updateCommunity: updateCommunityReducer,
  listUserJoinRequest: listUserJoinRequestReducer,
  actionUserJoinRequest: actionUserJoinRequestReducer
})

export default communityReducer