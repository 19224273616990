import { Dispatch } from "redux"
import {
  userProfileService,
  ReqUserProfile,
  ResUserProfile
} from "fu/services/user"

const STATE_BEGIN = "DETAIL_USER_BEGIN"
export const STATE_SUCCESS = "DETAIL_USER_SUCCESS"
const STATE_FAILED = "DETAIL_USER_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResUserProfile
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqUserProfile) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await userProfileService(body)

    dispatch({
      type: STATE_SUCCESS,
      payload: response
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
