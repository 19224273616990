import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconAddCircle: FC<IIcon> = ({ size = "22", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 11C21 5.47917 16.5208 1 11 1C5.47917 1 1 5.47917 1 11C1 16.5208 5.47917 21 11 21C16.5208 21 21 16.5208 21 11Z" stroke="#FF212A" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M15 11H7M11 7V15V7Z" stroke="#FF212A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </Icon>
  )
}

export default FuIconAddCircle