import { createGlobalStyle, DefaultTheme } from "styled-components"
import PoppinsBold from "fu/assets/fonts/Poppins/Poppins-Bold.ttf"
import PoppinsSemiBold from "fu/assets/fonts/Poppins/Poppins-SemiBold.ttf"
import PoppinsMedium from "fu/assets/fonts/Poppins/Poppins-Medium.ttf"
import PoppinsRegular from "fu/assets/fonts/Poppins/Poppins-Regular.ttf"
import PoppinsLight from "fu/assets/fonts/Poppins/Poppins-Light.ttf"
import RobotoBold from "fu/assets/fonts/Roboto/Roboto-Bold.ttf"
import RobotoMedium from "fu/assets/fonts/Roboto/Roboto-Medium.ttf"
import RobotoRegular from "fu/assets/fonts/Roboto/Roboto-Regular.ttf"
import RobotoLight from "fu/assets/fonts/Roboto/Roboto-Light.ttf"

export const defaultTheme: DefaultTheme = {
  body: "#FFFFFF",
  textMain: "#484848",
  textDark: "#1D1C1C",
  iconDark: "#24292E",
  primaryDark: "#C11E24",
  primaryDefault: "#FF9397",
  primaryLight: "#FFE0E1",
  primaryMain: "#FF212A",
  lightMain: "#FDEDED",
  greyInput: "#79797980",
  greyLabel: "#8D8D8D",
  greyPlaceholder: "clear",
  greyBorder: "#797979",
  greySearch: "#686868",
  greyTitle: "#454545",
  greyDark: "#575757",
  blue: "	#0000ff",
  greyLight: "#9D9D9D",
  whiteGrey: "#F0F0F0",
  background: "#FBFBFB",
  placeholder: "#C4C4C4",
  sub: "#848484",
  description: "#626060",
  border: "#F1F1F1",
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent"
}

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Poppins-Bold';
    src: url(${PoppinsBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-SemiBold';
    src: url(${PoppinsSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-Medium';
    src: url(${PoppinsMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-Regular';
    src: url(${PoppinsRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-Light';
    src: url(${PoppinsLight}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Bold';
    src: url(${RobotoBold}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Medium';
    src: url(${RobotoMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Light';
    src: url(${RobotoLight}) format('truetype');
  }
  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    width: 100vw;
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${({ theme }) => theme.body};
    font-family: Poppins-Regular, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  #app {
    display: flex;
    justify-content: center;
  }

  #app > div {
    width: 100%;
  }

  span {
    user-select: none;
    width: fit-content;
  }

  ::-webkit-scrollbar {
    display: none;
    width: 2px;
    height: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  ul, ol {
    margin-inline-start: 1rem !important;
  }

  .rsw-editor > .rsw-ce > a {
    color: blue !important;
  }

  .thread-discussion > a {
    color: blue !important;
    cursor: pointer;
  }

  svg * {
    display: block;
    width:  100%;
    height: 100%;
  }
`