import client from "./client"
import formatError from "./error"
import { IFileManager } from "fu/models/filemanager"

export interface ReqListFileManager {
  id_community: string
  path: string
}

export interface ResListFileManager {
  status: boolean
  current_path: string
  data: Array<IFileManager>
}

export interface ReqUploadFileManager {
  id_community: string
  path: string
  file: File | null
}

export interface ResUploadFileManager {
  status: boolean
  message: string
}

export interface ReqCreateFileManager {
  path: string
  folder_name: string
  id_community: string
}

export interface ResCreateFileManager {
  status: boolean
  message: string
}

export interface ReqDeleteFileManager {
  path: string
  community: string
  action: "folder" | "file"
  force: number
}

export interface ResDeleteFileManager {
  status: boolean
  message: string
}

export interface ReqMoveFileManager {
  origin_path: string
  destination_path: string
  action: "rename_file" | "move_file" | "rename_folder"
  id_community: string
}

export interface ResMoveFileManager {
  status: boolean
  message: string
}

export const listFileManagerService =
  (body: ReqListFileManager): Promise<ResListFileManager> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResListFileManager>("community_filemanager", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const uploadFileManagerService = 
  (body: ReqUploadFileManager): Promise<ResUploadFileManager> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResUploadFileManager>("community_filemanager/upload", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const createFileManagerService = 
  (body: ReqCreateFileManager): Promise<ResCreateFileManager> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResCreateFileManager>("community_filemanager/create", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const deleteFileManagerService = 
  (body: ReqDeleteFileManager): Promise<ResDeleteFileManager> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResDeleteFileManager>("community_filemanager/delete", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const moveFileManagerService =
  (body: ReqMoveFileManager): Promise<ResMoveFileManager> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResMoveFileManager>("community_filemanager/move", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

