import doc from "./doc.png"
import pdf from "./pdf.png"
import ppt from "./ppt.png"
import video from "./video.png"
import xls from "./xls.png"

export {
  doc,
  pdf,
  ppt,
  video,
  xls
}