import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconSend: FC<IIcon> = ({ size = "16", color, className = "" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_14_580)">
      <path d="M2.60742 9.71259H6.87906V8.28759H2.60742V1.76537C2.60743 1.70346 2.62356 1.64263 2.65422 1.58886C2.68488 1.53509 2.72901 1.49025 2.78226 1.45875C2.83551 1.42725 2.89604 1.41018 2.95789 1.40922C3.01974 1.40827 3.08077 1.42346 3.13497 1.45329L16.2788 8.68802C16.3346 8.71877 16.3811 8.76395 16.4136 8.81884C16.446 8.87374 16.4631 8.93633 16.4631 9.00009C16.4631 9.06386 16.446 9.12645 16.4136 9.18134C16.3811 9.23624 16.3346 9.28141 16.2788 9.31217L3.13497 16.5469C3.08077 16.5767 3.01974 16.5919 2.95789 16.591C2.89604 16.59 2.83551 16.5729 2.78226 16.5414C2.72901 16.5099 2.68488 16.4651 2.65422 16.4113C2.62356 16.3576 2.60743 16.2967 2.60742 16.2348V9.71259Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_14_580">
      <rect width="17.0865" height="17.1" fill="white" transform="translate(0.47168 0.450195)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default FuIconSend
