import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconDownload: FC<IIcon> = ({ width = "11", height = "11", color }) => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 14.875H12V13.2279H0M12 5.81617H8.57143V0.875H3.42857V5.81617H0L6 11.5809L12 5.81617Z" fill="#FF3A3A"/>
    </svg>
  )
}

export default FuIconDownload
