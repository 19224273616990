import { ICommunity } from "fu/models/community"
import { StateActions } from "fu/stores/actions/community/listCommunity"

type State = {
  loading: boolean
  communities: Array<ICommunity>
  error: any
}

const initialState: State = {
  loading: true,
  communities: [],
  error: null
}

const listCommunityReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LIST_COMMUNITY_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "LIST_COMMUNITY_SUCCESS":
      return {
        ...state,
        loading: false,
        communities: action.payload.data
      }
    case "LIST_COMMUNITY_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default listCommunityReducer