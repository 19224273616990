import { Dispatch } from "redux"
import {
  threadUpdateService,
  ResThreadUpdate
} from "fu/services/thread"

const STATE_BEGIN = "THREAD_UPDATE_BEGIN"
const STATE_END = "THREAD_UPDATE_END"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResThreadUpdate
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: STATE_BEGIN }) 

    const response = await threadUpdateService(body)
    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
