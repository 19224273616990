import { FuIconAdd, FuIconSearch } from "fu/components/FuIcon"
import { FuInput, IInput } from "fu/components/FuInput"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import faimage from "fu/assets/images/static"
import { IUser } from "fu/models/user"
import { useAppDispatch } from "fu/stores"
import listCommunity from "fu/stores/actions/community/listCommunity"
import listBannerAction from "fu/stores/actions/static/listBanner"
import listTopic from "fu/stores/actions/static/listTopic"
import myCommunity from "fu/stores/actions/community/myCommunity"
import { AppState } from "fu/stores/reducers"
import { Paths } from "fu/routes/paths"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  UIEvent,
  useContext
} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { generatePath, useNavigate } from "react-router-dom"
import styled from "styled-components"
import HomeBanner from "./Banner"
import HomeCategory from "./Category"
import HomeCommunity from "./Community"
import HomeMyCommunity from "./MyCommunity"
import HomeTab, { Tabs } from "./Tab"
import { FuModal } from "fu/components/FuModal"
import { FuAsset } from "fu/components/FuImage"
import { ITopic } from "fu/models/topic"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
  position: relative;
  overflow-y: auto;

  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 14px 0;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 18px;
  align-items: center;
`

const TextTitle = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Medium, sans-serif;
`

const ButtonAdd = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: fit-content;
  cursor: pointer;
  align-items: center;
`

const TextAdd = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
  font-family: Poppins-Regular, sans-serif;
`

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 18px;
  grid-gap: 8px;
`

const Search = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
`

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

const Latest = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  gap: 12px;

  ${({ theme }) => `
    background: linear-gradient(180deg, ${theme.primaryLight} 60%, ${theme.white} 60%);
  `};
`

const LatestHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
`

const TextLatest = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryDark};
  font-family: Roboto-Bold, sans-serif;
`

const TextShowAll = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.primaryDark};
  font-family: Roboto-Regular, sans-serif;
  cursor: pointer;
`

const Community = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  overflow-x: auto;
  scroll-behavior: smooth;
  justify-content: flex-start;
  padding: 2px 18px;
`

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0 18px;
`

const TextCategory = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.greyTitle};
  font-family: Roboto-Bold, sans-serif;
`

const ContentCategory = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(162px, 1fr));
  grid-gap: 10px;
`

const ShowCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`

const TextShowCategory = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Regular, sans-serif;
  font-style: italic;
  cursor: pointer;
`

const inputSearch: IInput = {
  name: "search",
  radius: "10px",
  border: "greySearch"
}

const AddCommunityGuardTitle = styled.p`
  font-size: 16px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyTitle};
  text-align: center;
  margin-bottom: 30px;
`

const AddCommunityGuardImage = styled(FuAsset)`
  width: 189px;
  height: 142px;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

const AddCommunityGuardDescContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin: 20px 0 20px 0;
`

const AddCommunityGuardFirstDesc = styled.span`
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
`

const AddCommunityGuardDesc = styled(AddCommunityGuardFirstDesc)``

const AddCommunityGuardItalicDesc = styled(AddCommunityGuardDesc)`
  font-style: italic;
`

const AddCommunityGuardBoldDesc = styled(AddCommunityGuardDesc)`
  font-weight: 700;
  font-family: Poppins-Bold; sans-serif;
`

interface IMenuTab {
  value: Tabs
  text: string
}

const menuTabs: Array<IMenuTab> = [
  {
    value: "timeline",
    text: "home.search"
  },
  {
    value: "thread",
    text: "home.myGroup"
  }
]

const AddCommunityGuardStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '400px',
    overflow: "hidden"
  }
}

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const { CATEGORY, COMMUNITY, COMMUNITY_ADD, CATEGORY_DETAIL } = Paths;

type TimelineProps = {
  onPageCategory: () => void
  onPageCommunity: () => void
}

const TabTimeline: FC<TimelineProps> = ({ onPageCategory, onPageCommunity }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { communities } = useSelector((state: AppState) => state.community.listCommunity)
  const { topics } = useSelector((state: AppState) => state.static.listTopic)

  const categories = useMemo(() => topics.slice(0, 6), [topics])

  const handleSelectCategory = useCallback((topic: ITopic) => {
    const combinedPath = generatePath(CATEGORY_DETAIL.path, { slug: topic.id_topic })

    navigate(combinedPath, { state: { topic } })
  }, [])

  return (
    <Fragment>
      <Banner>
        <HomeBanner />
      </Banner>

      <Latest>
        <LatestHeader>
          <TextLatest>{t("home.latest")}</TextLatest>
          <TextShowAll onClick={onPageCommunity}>
            {t("showAll")}
          </TextShowAll>
        </LatestHeader>

        <Community>
          {
            communities.map((item, index) => (
              <HomeCommunity
                key={index}
                row={index + 1}
                community={item}
              />
            ))
          }
        </Community>
      </Latest>

      <Category>
        <TextCategory>{t("category.title")}</TextCategory>
        <ContentCategory>
          {
            categories.map((item, index) => (
              <HomeCategory
                key={index}
                topic={item}
                onSelect={handleSelectCategory}
              />
            ))
          }
        </ContentCategory>
      </Category>

      <ShowCategory>
        <TextShowCategory onClick={onPageCategory}>
          {t("home.showAll")}
        </TextShowCategory>
      </ShowCategory>
    </Fragment>
  )
}

type TabItemProps = {
  tab: Tabs
  onPageCategory: () => void
  onPageCommunity: () => void
  search: string
}

const TabItem: FC<TabItemProps> = ({
  tab, onPageCategory, onPageCommunity, search
}) => {
  switch (tab) {
    case "timeline":
      return (
        <TabTimeline
          onPageCategory={onPageCategory}
          onPageCommunity={onPageCommunity}
        />)
    default:
      return (
        <HomeMyCommunity search={search} />
      )
  }
}

const ViewHome: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentMenuHome, handleSetCurrentMenuHome }: { currentMenuHome: 0 | 1, handleSetCurrentMenuHome: (value: 0 | 1) => void } = useContext(GeneralContext) 
  const { communities } = useSelector((state: AppState) => state.community.myCommunity)
  const { user } = useSelector((state: AppState) => state.auth.authenticate)
  const [search, setSearch] = useState<string>("")
  const [tab, setTab] = useState<Tabs>("timeline")
  const [showUpgradeMembership, setShowUpgradeMembership] = useState<boolean>(false)
  const [currentMyCommunityPage, setCurrentMyCommunityPage] = useState<number>(1)

  useEffect(() => {
    if (currentMenuHome === 0) {
      setTab("timeline")
    } else {
      setTab("thread")
    }
  }, [currentMenuHome])

  const handleShowUpgradeMembership = useCallback(() => {
    setShowUpgradeMembership(!showUpgradeMembership)
  }, [showUpgradeMembership])

  useEffect(() => {
    dispatch(listBannerAction())
    dispatch(listCommunity({ limit: 10, current_page: 1 }))
    dispatch(listTopic())
  }, [
    dispatch,
    listBannerAction,
    listCommunity,
    listTopic
  ])

  useEffect(() => {
    dispatch(myCommunity({ limit: 10, current_page: currentMyCommunityPage }))
  }, [dispatch, myCommunity, currentMyCommunityPage])

  const handlePageCategory = useCallback(() => {
    navigate(CATEGORY.path)
  }, [navigate])

  const handlePageCommunity = useCallback((search?: string) => {
    navigate(COMMUNITY.path, { state: { search } })
  }, [navigate])

  const handlePageCommunityAdd = useCallback(() => {
    if ((user as IUser).id_membership != "4") return handleShowUpgradeMembership()
    return navigate(COMMUNITY_ADD.path)
  }, [navigate, user, handleShowUpgradeMembership])

  const handleChangeSearch = useCallback((_: string, value: string) => {
    setSearch(value)
    
    if (tab === "timeline") {
      handlePageCommunity(value)
    }
  }, [setSearch, tab])

  const handleChangeTab = useCallback((value: Tabs) => {
    if (value === "thread") {
      handleSetCurrentMenuHome(1)
    } else {
      handleSetCurrentMenuHome(0)
    }

    setTab(value)
  }, [setTab, handleSetCurrentMenuHome])

  const handleScrollMyCommunity = useCallback(
    ({ currentTarget }: UIEvent<HTMLDivElement>
  ) => {
    const bottom =
      (currentTarget.scrollHeight - currentTarget.scrollTop)
        === currentTarget.clientHeight;

    if (
      bottom &&
      tab === "thread" &&
      !search.length &&
      communities.length === (currentMyCommunityPage * 10)
    ) {
      setCurrentMyCommunityPage(currentMyCommunityPage + 1)
    }
  }, [currentMyCommunityPage, tab, search, communities.length])

  const renderUpgradeMembershipModal = useMemo(() => showUpgradeMembership && (
    <FuModal
      isOpen={showUpgradeMembership}
      onClose={handleShowUpgradeMembership}
      customStyles={AddCommunityGuardStyles}
      children={
        <Fragment>
          <AddCommunityGuardTitle>
            {t("home.addCommunityGuardTitle")}
          </AddCommunityGuardTitle>
          <AddCommunityGuardImage
            source={faimage.upgrade}
            alt="Upgrade"
          />
          <AddCommunityGuardDescContainer>
            <AddCommunityGuardFirstDesc>
              {t("home.addCommunityGuardFeature")}{' '}
              <AddCommunityGuardItalicDesc>
                {t("home.addCommunityGuardDescItalic")}{' '}
              </AddCommunityGuardItalicDesc>
              <AddCommunityGuardDesc>
                {t("home.addCommunityGuardDesc")}{' '}
              </AddCommunityGuardDesc>
              <AddCommunityGuardBoldDesc>
                {t("home.addCommunityGuardDescBold")}{' '}
              </AddCommunityGuardBoldDesc>
            </AddCommunityGuardFirstDesc>
          </AddCommunityGuardDescContainer>
          <FuButton
            button={mainButton}
            onClick={handleShowUpgradeMembership}
            text={t("home.addCommunityGuardBtn")}
          />
        </Fragment>
      }
    />
  ), [showUpgradeMembership])

  return (
    <Container onScroll={handleScrollMyCommunity}>
      <Content>
        <Header>
          <TextTitle>{t("home.title")}</TextTitle>
          <ButtonAdd onClick={handlePageCommunityAdd}>
            <FuIconAdd />
            <TextAdd>{t("form.createNew")}</TextAdd>
          </ButtonAdd>
        </Header>
        <Menu>
          {
            menuTabs.map((menu, index) => (
              <HomeTab
                key={index}
                tab={menu.value}
                text={t(menu.text)}
                current={tab}
                onChange={handleChangeTab}
              />
            ))
          }
        </Menu>
        <Search>
          <FuInput
            input={inputSearch}
            type="text"
            placeholder={t("home.search")}
            value={search}
            prepend={<FuIconSearch
              color="greySearch"
              size="16"
            />}
            onChange={handleChangeSearch}
          />
        </Search>
      </Content>
      <ContentScroll>
        <TabItem
          tab={tab}
          onPageCategory={handlePageCategory}
          onPageCommunity={handlePageCommunity}
          search={search}
        />
      </ContentScroll>
      {renderUpgradeMembershipModal}
    </Container>
  )
}

export default ViewHome