import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconMembers: FC<IIcon> = ({ width = "23", height = "17", color = "iconDark", className = "" }) => {
  return (
    <Icon className={className} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9889 4.5C18.834 6.47701 17.2391 8 15.5 8C13.7609 8 12.1634 6.4775 12.0111 4.5C11.8526 2.44326 13.4051 1 15.5 1C17.5949 1 19.1474 2.48069 18.9889 4.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 11C11.8319 11 8.78538 12.4451 8.02217 15.2594C7.92106 15.6317 8.1753 16 8.59191 16H21.4086C21.8252 16 22.078 15.6317 21.9783 15.2594C21.2151 12.4 18.1685 11 15 11Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M8.9922 5.49741C8.88217 6.89741 7.73576 8 6.5 8C5.26424 8 4.11595 6.89785 4.0078 5.49741C3.89541 4.04095 5.01126 3 6.5 3C7.98874 3 9.10459 4.06767 8.9922 5.49741Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 11.512C8.17016 11.1422 7.25619 11 6.28751 11C3.89684 11 1.59352 12.156 1.01654 14.4075C0.940683 14.7053 1.13286 15 1.44732 15H6.60933" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    </Icon>
  )
}

export default FuIconMembers