import React, { FC, useMemo } from "react"
import styled, { DefaultTheme } from "styled-components"
import { ESizes, FSize, type IButton, ETypes } from "./types"

type Props = {
  customWidth?: string
  customBorderRadius?: string
  button: IButton
  text: string
  onClick: () => void
  isDisabled?: boolean
}

type ContainerProps = {
  borderRadius?: string
  width?: string
  type?: ETypes
  height: string
}

type SizeProps = {
  size: string
  type?: ETypes
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : "12px"};
  background: ${({ theme, type }) => type === "primary" ? theme.primaryMain : theme.white};
  border: ${({ theme, type }) => type === "secondary" ? `2px solid ${theme.primaryMain}` : ''};
  height: ${({ height }) => height};
  cursor: pointer;
  width: ${({ width }) => width ? width : "100%"};
`

const TextButton = styled.span<SizeProps>`
  font-size: ${({ size }) => size};
  color: ${({ theme, type }) => type === "secondary" ? theme.primaryMain : theme.white};
  font-family: ${({ type }) => type === "secondary" ? "Roboto-Regular" : "Roboto-Medium"}, sans-serif;
`

const FuButton: FC<Props> = ({ customBorderRadius, customWidth, button, text, onClick, isDisabled }) => {
  const size = useMemo(() => FSize(button.size || ESizes["regular"]), [
    FSize,
    ESizes,
    button.size
  ])

  const voidFunc = () => {}

  return (
    <Container
      borderRadius={customBorderRadius}
      width={customWidth}
      height={size.height}
      type={button.type}
      onClick={!isDisabled ? onClick : voidFunc}
    >
      <TextButton type={button.type} size={size.size}>
        {text}
      </TextButton>
    </Container>
  )
}

export default FuButton