import { listTimelineService, ReqListTimeline, ResListTimeline } from "fu/services/timeline"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_TIMELINE_BEGIN"
const STATE_SUCCESS = "LIST_TIMELINE_SUCCESS"
const STATE_FAILED = "LIST_TIMELINE_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListTimeline
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListTimeline) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const items = getState().timeline.listTimeline.timeline
      dispatch({ type: STATE_BEGIN })

      const response = await listTimelineService(body)

      dispatch({
        type: STATE_SUCCESS,
        payload:
          body.current_page === 1
            ? response
            : { data: [...items, ...response.data] }
      })
    } catch (error: any) {
      dispatch({
        type: STATE_FAILED,
        error,
      })
    }
  }
