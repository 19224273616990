import communityAsset from "fu/assets/images/community"
import { FuIconArrowLeft, FuIconMore, FuIconEdit, FuIconLeave } from "fu/components/FuIcon"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { AVATAR_URL } from "fu/constants/Environment"
import { FuModal } from "fu/components/FuModal"
import { FuImage } from "fu/components/FuImage"
import { FuCheckbox } from "fu/components/FuChecbox"
import { handleToast } from "fu/utils/toast"
import { FuShare } from "fu/components/FuShare"
import { FuLoader } from "fu/components/FuLoader"
import { FuAsset } from "fu/components/FuImage"
import { IUser } from "fu/models/user"
import { Paths } from "fu/routes/paths"
import { useAppDispatch } from "fu/stores"
import joinCommunity from "fu/stores/actions/community/joinCommunity"
import joinPrivateCommunity from "fu/stores/actions/community/joinPrivateCommunity"
import cancelJoinCommunity from "fu/stores/actions/community/cancelJoinCommunity"
import leaveCommunity from "fu/stores/actions/community/leaveCommunity"
import detailCommunity from "fu/stores/actions/community/detailCommunity"
import translations from "fu/langs/id.json"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import { AppState } from "fu/stores/reducers"
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState, useRef, useContext } from "react"
import useOutsideClick from "fu/utils/useOutsideClick"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate, useParams, generatePath } from "react-router-dom"
import styled, { DefaultTheme } from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
  overflow-y: scroll;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0 0 20px 20px;
`

const Header = styled.div`
  position: relative;
`

const Banner = styled(FuAsset)`
  margin-top: -12px;
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -60px;
`

const HeaderAction = styled.div`
  display: grid;
  padding: 14px 16px;
  grid-template-columns: 1fr auto;
  grid-gap: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeaderBack = styled.div`
  display: flex;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`

const IconMoreContainer = styled.div`
  cursor: pointer;
  position: relative;
`

const HeaderAvatar = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 100%;
  overflow: hidden;
  border: 6px solid ${({ theme }) => theme.white};

  @media only screen and (max-width: 480px) {
    height: 90px;
    width: 90px;
  }
`

const AvatarImage = styled(FuImage)`
  background-color: ${({ theme }) => theme.white};
  object-fit: cover;
  height: 120px;
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  margin-top: 72px;
`

const TextTitle = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.greyDark};
  font-family: Poppins-Medium, sans-serif;
  margin-bottom: 10px;
`

const PrivateLabelText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  text-align: center;
  margin-bottom: 10px;
`

const JoinedStatusText = styled(PrivateLabelText)`
  color: ${({ theme }) => theme.greySearch};
  padding: 0 12px 0 12px;
`

const ShareText = styled(PrivateLabelText)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.primaryMain};
  cursor: pointer;
  margin: 10px 0 10px 0;
`

const DescTitle = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.black};
  text-align: left;
  font-family: Poppins-Medium, sans-serif;
  margin: 20px;
  margin-bottom: 12px;
`

const DescDesc = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.greyDark};
  text-align: left;
  font-family: Poppins-Regular, sans-serif;
  margin: 0 20px 0 20px;
  margin-bottom: 12px;
`

const DeclineText = styled(ShareText)`
  font-family: Poppins-Regular, sans-serif;
  text-align: center;
  margin-top: 0px;
`

const MemberText = styled(DeclineText)`
  text-align: left;
  margin: 20px 0 20px 20px;
`

const DeclineAlertTitle = styled.p`
  font-family: Poppins-Bold, sans-serif;
  margin-bottom: 12px;
  font-size: 16px;
`

const DeclineAlertDesc = styled.p`
  font-family: Poppins-Regular, sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
`

const DeclineAlertBtnContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
`

const DeclineAlertBtnText = styled(DeclineAlertDesc)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
`

const DeclineAlertBtnLeftText = styled(DeclineAlertBtnText)`
  margin-right: 20px
`

const JoinedStatusButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
`

const ReqJoinModalContentScroll = styled.div`
  overflow-y: scroll;
  height: 65%;
  margin-bottom: 20px;
`

const ReqJoinModalTitle = styled.p`
  font-size: 16px;
  font-family: Poppins-Medium, sans-serif;
  text-align: left;
  font-weight: 700;
  color: ${({ theme }) => theme.greyDark};
  margin-bottom: 20px;
  

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 10.5px;
  }
`

const ReqJoinModalCheckboxLabel = styled.label`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  font-family: Poppins-Regular, sans-serif;
  margin-left: 12px;

  @media only screen and (max-width: 600px) {
    font-size: 10px
  }

  @media only screen and (max-width: 480px) {
    font-size: 8px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 6.5px;
  }
`

const ReqJoinModalItalicTitle = styled.span`
  font-size: 16px;
  font-family: Poppins-Medium, sans-serif;
  text-align: left;
  font-weight: 700;
  font-style: italic;

  @media only screen and (max-width: 600px) {
    font-size: 14px
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px
  }

  @media only screen and (max-width: 320px) {
    font-size: 10.5px;
  }
`

const ReqJoinModalContent = styled.div`
  margin: 12px 0 12px 0;
`

const ReqJoinModalContentContainer = styled.div`
  flex-direction: row;
  display: flex;
`
const ReqJoinModalContentBottomContainer = styled(ReqJoinModalContentContainer)`
  margin-top: 12px;
`

const ReqJoinModalTitleLeft = styled.div`
  width: 15%;
`

const ReqJoinModalDescLeft = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ReqJoinModalContentTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Regular, sans-serif;
  width: 85%;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 10px
  }

  @media only screen and (max-width: 320px) {
    font-size: 8.5px;
  }
`

const ReqJoinModalContentDescLine = styled.div`
  height: 100%;
  width: 1%;
  background-color: ${({ theme }) => theme.greyLight};
`

const ReqJoinModalContentDesc: any = styled(ReqJoinModalContentTitle)`
  color: ${({ theme }) => theme.greyDark};
  width: 85%;
`

const ReqJoinModalMainBtn = styled.div`
  margin-top: 20px;
`

const TopLineSeparator = styled.div`
  width: 80px;
  height: 2px;
  background-color: ${({ theme }) => theme.primaryMain};
  display: flex;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin: 24px 0 32px 0;
`

const MemberContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-bottom: 12px;
`

const MemberLeftContainer = styled.div`
  flex-direction: row;
  display: flex;
`

const MemberPhoto = styled(FuImage)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
`

const MemberVerticalContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const MemberName = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  color: ${({ theme }) => theme.black};
  margin-right: 12px;
`

const MemberNameContainer = styled.div`
  display: flex;
`

const MemberAdminContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryMain};
  border-radius: 5px;
  padding: 5px;
`

const MemberBio = styled.p`
  font-family: Poppins-Regular, sans-serif;
  font-size: 13px;
  color: ${({ theme }) => theme.black};
  margin-top: 4px;
`

const MemberAdminText = styled.p`
  font-size: 9px;
  color: ${({ theme }) => theme.white};
`

const DisableStatusBtn = styled.div`
  width: 200px;
  border-radius: 24px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: ${({ theme }) => theme.greyLabel};
  cursor: pointer;
`

const DisableStatusBtnText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.white};
  font-family: Roboto-Medium, sans-serif;
`

const SuccessReqJoinModalTitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.black};
  font-family: Roboto-Medium, sans-serif;
  text-align: center;
  margin-bottom: 12px;
`

const SuccessReqJoinModalDesc = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Roboto-Regular, sans-serif;
  text-align: center;
`

const SuccessReqJoinModalDescBold = styled(SuccessReqJoinModalDesc)`
  color: ${({ theme }) => theme.primaryMain};
  font-family: Roboto-Medium, sans-serif;
`

const SuccessReqJoinModalBtnContainer = styled.div`
  margin-top: 12px;
`

const TopMenuContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  left: -195px;
  z-index: 200;
  border-radius: 8px;
`

const TopMenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px;
  cursor: pointer
`

const TopMenuItemIconContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const SecondMenuItemIconContainer = styled(TopMenuItemIconContainer)`
  margin-left: -2px;
  margin-top: 6px;
  margin-right: -4px;
`

const TopMenuItemText = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.black};
  margin-left: 12px;
`

const secondaryButton = {
  type: ETypes["secondary"],
  size: ESizes["regular"],
}

const primaryButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const ReqJoinModalStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '600px',
    height: '80%',
    overflow: "hidden"
  }
}

const DeclineJoinStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '400px',
    overflow: "hidden"
  }
}

interface Props {
  handleSetBgColor: (color: keyof DefaultTheme) => void
}

const { COMMUNITY_TIMELINE: { path }, COMMUNITY_EDIT: { path: communityEditPath } } = Paths

const ViewCommunityDetail: FC<Props> = ({ handleSetBgColor }) => {
  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { slug } = useParams()
  const dispatch = useAppDispatch()
  const {
    handleSetCurrentMenu
  }:
    {
      handleSetCurrentMenu: (menu: number) => () => void
    } =
    useContext(GeneralContext)
  const { user } : { user: IUser } = useSelector((state: AppState) => state.auth.authenticate)
  const { loading: loadingJoin } = useSelector((state: AppState) => state.community.joinCommunity)
  const { loading: loadingJoinPrivate } = useSelector((state: AppState) => state.community.joinPrivateCommunity)
  const { loading: loadingCancelJoin } = useSelector((state: AppState) => state.community.cancelJoinCommunity)
  const { loading: loadingLeave } = useSelector((state: AppState) => state.community.leaveCommunity)
  const { detail, loading } = useSelector((state: AppState) => state.community.detailCommunity)
  const [showReqJoinModal, setShowReqJoinModal] = useState<boolean>(false)
  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const [agreeReqJoinRules, setAgreeReqJoinRules] = useState<boolean>(true)
  const [showDeclineAlert, setShowDeclineAlert] = useState<boolean>(false)
  const [showSuccessReqJoinModal, setShowSuccessReqJoinModal] = useState<boolean>(false)
  const [showTopMenu, setShowTopMenu] = useState<boolean>(false)
  useOutsideClick(wrapperRef, () => {
    setShowTopMenu(false)
  });

  const handleShowTopMenu = useCallback(() => {
    setShowTopMenu(!showTopMenu)
  }, [showTopMenu])

  const handleShowSuccessReqJoinModal = useCallback(() => {
    setShowSuccessReqJoinModal(!showSuccessReqJoinModal)
  }, [showSuccessReqJoinModal])
  
  const handleShowDeclineAlert = useCallback(() => {
    setShowDeclineAlert(!showDeclineAlert)
  }, [showDeclineAlert])

  const handleShowReqJoinModal = useCallback(() => {
    setShowReqJoinModal(!showReqJoinModal)
  }, [showReqJoinModal])

  const handleShowShareModal = useCallback(() => {
    setShowShareModal(!showShareModal)
  }, [showShareModal])

  const handleAgreeReqJoinRules = useCallback(() => {
    setAgreeReqJoinRules(!agreeReqJoinRules)
  }, [agreeReqJoinRules])

  const handleNavigateBack = useCallback(() => {
    navigate(-1)
  }, [])

  useEffect(() => {
    handleSetBgColor("whiteGrey")
    dispatch(detailCommunity({ id_community: slug || "" }))

    return () => {
      handleSetBgColor("white")
    }
  }, [])

  const renderPublicPrivateLabel = useMemo(() => detail?.join_need_approval === "true" && (
    <PrivateLabelText>
      {t("communityDetail.privateLabel")}
    </PrivateLabelText>
  ), [detail])

  const renderJoinedStatusLabel = useMemo(() => (
    <JoinedStatusText>
      {t(`communityDetail.${detail?.is_joined ? "isJoined" : "isNotJoined"}`)}
    </JoinedStatusText>
  ), [detail])

  const getJoinedStatusButtonText = useMemo(() => {
    if (detail?.is_joined) {
      return t("communityDetail.joinedButton")
    } else if (detail?.already_req_join) {
      return t("communityDetail.joinedOnProgressButton")
    } else if (detail?.join_need_approval === "false") {
      return t("communityDetail.joinedDirectButton")
    } else {
      return t("communityDetail.joinedReqButton")
    }
  }, [detail])

  const handleTriggerJoin = useCallback(async () => {
    if (detail?.join_need_approval === "true" && !agreeReqJoinRules) {
      handleToast("error", t("communityDetail.joinedReqRules.alert"))
    } else {
      setShowReqJoinModal(false)

      const params = { id_community: detail?.id_community || "" }

      const response = detail?.join_need_approval === "true"
        ? await dispatch(joinPrivateCommunity(params))
        : await dispatch(joinCommunity(params))
      if (response.status) {
        await dispatch(detailCommunity({ id_community: slug || "" }))
        if (detail?.join_need_approval === "true" && !loading) {
          handleShowSuccessReqJoinModal()
        } else {
          handleToast("success", response.message)
        }
      } else {
        handleToast("error", response.message)
      }

    }
  }, [detail, agreeReqJoinRules, slug, handleShowSuccessReqJoinModal])

  const handleJoinStatusButton = useCallback(() => {
    if (detail?.is_joined) {
      const combinedPath = generatePath(path, { slug: detail?.id_community })
      handleSetCurrentMenu(0)()
      navigate(combinedPath, { state: { detail }})
    } else {
      handleShowReqJoinModal()
    }
  }, [handleShowReqJoinModal, detail, handleSetCurrentMenu])

  const renderJoinedStatusButton = useMemo(() => (
    <JoinedStatusButton>
      {(detail?.already_req_join && !detail?.is_joined)? (
        <DisableStatusBtn>
          <DisableStatusBtnText>
            {getJoinedStatusButtonText}
          </DisableStatusBtnText>
        </DisableStatusBtn>
      ) : (
        <FuButton
          customBorderRadius="24px"
          customWidth="200px"
          button={detail?.is_joined ? secondaryButton : primaryButton}
          text={getJoinedStatusButtonText}
          onClick={handleJoinStatusButton}
        />
      )}
    </JoinedStatusButton>
  ), [getJoinedStatusButtonText, detail, handleJoinStatusButton])

  const handleConfirmCancelJoin = useCallback(async () => {
    setShowDeclineAlert(false)

    const params = { id_community: detail?.id_community || "" }

    const response = await dispatch(cancelJoinCommunity(params))
    
    if (response.status) {
      await dispatch(detailCommunity({ id_community: slug || "" }))
      handleToast("success", response.message)
    } else {
      handleToast("error", response.message)
    }
  }, [detail, loading])

  const renderDeclineReqJoinBtn = useMemo(() => (detail?.already_req_join && !detail.is_joined) ? (
    <DeclineText onClick={handleShowDeclineAlert}>
      {t("communityDetail.joinedReqDeclineButton")}
    </DeclineText>
  ) : <></>, [detail, handleShowDeclineAlert])

  const renderDeclineAlertModal = useMemo(() => showDeclineAlert && (
    <FuModal
      isOpen={showDeclineAlert}
      onClose={handleShowDeclineAlert}
      customStyles={DeclineJoinStyles}
      children={
        <Fragment>
          <DeclineAlertTitle>
            {t("communityDetail.joinedReqDeclineAlertTitle")}
          </DeclineAlertTitle>
          <DeclineAlertDesc>
            {t("communityDetail.joinedReqDeclineAlertDesc")}
          </DeclineAlertDesc>
          <DeclineAlertBtnContainer>
            <DeclineAlertBtnLeftText onClick={handleConfirmCancelJoin}>
              {t("form.confirmationYes")}
            </DeclineAlertBtnLeftText>
            <DeclineAlertBtnLeftText onClick={handleShowDeclineAlert}>
              {t("form.confirmationNo")}
            </DeclineAlertBtnLeftText>
          </DeclineAlertBtnContainer>
        </Fragment>
      }
    />
  ), [showDeclineAlert, handleShowDeclineAlert])

  const renderReqJoinModal = useMemo(() => (
    <FuModal
      isOpen={showReqJoinModal}
      onClose={handleShowReqJoinModal}
      customStyles={ReqJoinModalStyles}
      children={
        <Fragment>
          <div>
            <ReqJoinModalTitle>
              {t("communityDetail.joinedReqRules.title")}{' '}
              <ReqJoinModalItalicTitle>
                '{detail?.title}'
              </ReqJoinModalItalicTitle>
            </ReqJoinModalTitle>
          </div>
          <ReqJoinModalContentScroll>
            {Object.keys(translations.communityDetail.joinedReqRules.content).map((_, index) => (
              <ReqJoinModalContent>
                <ReqJoinModalContentContainer>
                  <ReqJoinModalTitleLeft />
                  <ReqJoinModalContentTitle>
                    {t(`communityDetail.joinedReqRules.content._${index + 1}.title`)}
                  </ReqJoinModalContentTitle>
                </ReqJoinModalContentContainer>
                <ReqJoinModalContentBottomContainer>
                  <ReqJoinModalDescLeft>
                    <ReqJoinModalContentDescLine />
                  </ReqJoinModalDescLeft>
                  <ReqJoinModalContentDesc>
                    {t(`communityDetail.joinedReqRules.content._${index + 1}.desc`)}
                  </ReqJoinModalContentDesc>
                </ReqJoinModalContentBottomContainer>
              </ReqJoinModalContent>
            ))}
          </ReqJoinModalContentScroll>
          <FuCheckbox
            isChecked={agreeReqJoinRules}
            onChange={handleAgreeReqJoinRules}
            label={t("communityDetail.joinedReqRules.agreement")}
            customLabel={
              <ReqJoinModalCheckboxLabel>
                {t("communityDetail.joinedReqRules.agreement")}
              </ReqJoinModalCheckboxLabel>
            }
          />
          <ReqJoinModalMainBtn>
            <FuButton
              button={primaryButton}
              text={t("form.agree")}
              onClick={handleTriggerJoin}
            />
          </ReqJoinModalMainBtn>
        </Fragment>
      }
    />
  ), [
    showReqJoinModal,
    handleShowReqJoinModal,
    detail,
    agreeReqJoinRules,
    handleAgreeReqJoinRules,
    handleTriggerJoin
  ])

  const renderShareModal = useMemo(() => showShareModal && (
    <FuShare
      isOpen={showShareModal}
      onClose={handleShowShareModal}
      name={detail?.title || ""}
    />
  ), [showShareModal, handleShowShareModal, detail])

  const renderLoaderModal = useMemo(() => (
    <FuModal
      isOpen={true}
      onClose={() => {}}
      children={
        <FuLoader
          color={defaultTheme.primaryMain}
          width={80}
          height={80}
          type={"bubbles"}
        />
      }
    />
  ), [])

  const renderMembers = useMemo(() => (
    <Fragment>
      {detail?.members.map((member, index) => (
        <MemberContainer key={index}>
          <MemberLeftContainer>
            <MemberPhoto
              source={
                member.photo.length ?
                  `upload/profil/${member.photo}` :
                  (AVATAR_URL || "")
                }
              alt={"Photo"}
              alternateImage={AVATAR_URL}
            />
            <MemberVerticalContainer>
              <MemberNameContainer>
                <MemberName>{member.full_name}</MemberName>
                {member.id_user === detail?.admin.id_user && (
                  <MemberAdminContainer>
                    <MemberAdminText>Admin</MemberAdminText>
                  </MemberAdminContainer>
                )}
              </MemberNameContainer>
              {member.bio && <MemberBio>{member.bio}</MemberBio>}
            </MemberVerticalContainer>
          </MemberLeftContainer>
        </MemberContainer>
      ))}
    </Fragment>
  ), [detail?.members])

  const renderSuccessReqJoinModal = useMemo(() => showSuccessReqJoinModal && (
    <FuModal
      isOpen={showSuccessReqJoinModal}
      onClose={handleShowSuccessReqJoinModal}
      customStyles={DeclineJoinStyles}
      children={
        <Fragment>
          <SuccessReqJoinModalTitle>
            {t("communityDetail.successReqJoinModalTitle")}
          </SuccessReqJoinModalTitle>
          <SuccessReqJoinModalDesc>
            {t("communityDetail.successReqJoinModalDesc_1")}
            <SuccessReqJoinModalDescBold>
              {detail?.title}
            </SuccessReqJoinModalDescBold>
            <SuccessReqJoinModalDesc>
              {t("communityDetail.successReqJoinModalDesc_2")}
            </SuccessReqJoinModalDesc>
          </SuccessReqJoinModalDesc>
          <SuccessReqJoinModalBtnContainer>
            <FuButton
              button={primaryButton}
              text={t("communityDetail.successReqJoinModalBtn")}
              onClick={handleShowSuccessReqJoinModal}
            />
          </SuccessReqJoinModalBtnContainer>
        </Fragment>
      }
    />
  ), [showSuccessReqJoinModal, handleShowSuccessReqJoinModal, detail])

  const handleLeaveCommunity = useCallback(async () => {
    setShowTopMenu(false)
    
    if (user?.id_user === detail?.admin.id_user) {
      handleToast("error", t("communityDetail.leaveGroupError"))
    } else {
      const result = await dispatch(leaveCommunity({ id_community: detail?.id_community || ""}))
        
      if (result.isSuccess) {
        navigate(-1)
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }
    }
    
  }, [detail?.id_community, user])

  const handleNavigateEditCommunity = useCallback(() => {
    setShowTopMenu(false)
    
    navigate(communityEditPath, { state: { detail, isEdit: true }})
  }, [detail])
  
  return (
    <Container>
      {(
        loading ||
        loadingJoin ||
        loadingJoinPrivate ||
        loadingCancelJoin ||
        loadingLeave
      ) ? renderLoaderModal : (
        <Content>
          <Header>
            <Banner
              source={communityAsset.banner}
              alt="Banner Detail Community"
            />
            <HeaderContent>
              <HeaderAction>
                <HeaderBack onClick={handleNavigateBack}>
                  <FuIconArrowLeft
                    color="primaryMain"
                  />
                </HeaderBack>
                <IconMoreContainer onClick={handleShowTopMenu}>
                  <FuIconMore
                    color="white"
                  />
                  {showTopMenu && (
                    <TopMenuContainer ref={wrapperRef}>
                      {user.id_user === detail?.admin.id_user && (
                        <TopMenuItemContainer onClick={handleNavigateEditCommunity}>
                          <TopMenuItemIconContainer>
                            <FuIconEdit />
                          </TopMenuItemIconContainer>
                          <TopMenuItemIconContainer>
                            <TopMenuItemText>
                              {t("communityDetail.topMenuEditGroup")}
                            </TopMenuItemText>
                          </TopMenuItemIconContainer>
                        </TopMenuItemContainer>
                      )}
                      <TopMenuItemContainer onClick={handleLeaveCommunity}>
                        <SecondMenuItemIconContainer>
                          <FuIconLeave />
                        </SecondMenuItemIconContainer>
                        <TopMenuItemIconContainer>
                          <TopMenuItemText>
                            {t("communityDetail.topMenuLeaveGroup")}
                          </TopMenuItemText>
                        </TopMenuItemIconContainer>
                      </TopMenuItemContainer>
                    </TopMenuContainer>
                  )}
                </IconMoreContainer>
              </HeaderAction>
              <HeaderAvatar>
                <AvatarImage
                  source={`upload/community/${detail?.image}`}
                  alt="Detail Community"
                />
              </HeaderAvatar>
            </HeaderContent>
          </Header>
          <Detail>
            <TextTitle>{detail?.title}</TextTitle>
          </Detail>
          {renderPublicPrivateLabel}
          {renderJoinedStatusLabel}
          {renderJoinedStatusButton}
          <ShareText onClick={handleShowShareModal}>
            {t("communityDetail.share")}
          </ShareText>
          {renderDeclineReqJoinBtn}
          <DescTitle>
            {t("communityDetail.descTitle")}
          </DescTitle>
          <DescDesc>
            {detail?.description}
          </DescDesc>
          <TopLineSeparator />
        </Content>
      )}
      <MemberText>{t("communityDetail.membersTitle")} ( {detail?.members.length} )</MemberText>
      {renderMembers}
      {renderReqJoinModal}
      {renderShareModal}
      {renderDeclineAlertModal}
      {renderSuccessReqJoinModal}
    </Container>
  )
}

export default ViewCommunityDetail