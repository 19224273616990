import { FuImage } from "fu/components/FuImage"
import { ITopic } from "fu/models/topic"
import React, { FC, useCallback } from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  user-select: none;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  height: 118px;
  background: ${({ theme }) => theme.placeholder};
`

const Content = styled.div`
  position: absolute;
  left: 8px;
  top: 4px;
`

const TextTitle = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  font-family: Poppins-Medium, sans-serif;
`

const Image = styled(FuImage)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  user-select: none;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  height: 118px;
  object-fit: contain;
`

type Props = {
  topic: ITopic
  onSelect: (topic: ITopic) => void
}

const HomeCategory: FC<Props> = ({ topic, onSelect }) => {

  const handleSelect = useCallback(() => {
    if (topic.id_topic) {
      onSelect(topic)
    }
  }, [topic, onSelect])

  return (
    <Container onClick={handleSelect}>
      <Image
        source={`upload/topic/${topic.icon}`}
        alt={topic.title}
      />
      <Content>
        <TextTitle>{topic.title}</TextTitle>
      </Content>
    </Container>
  )
}

export default HomeCategory