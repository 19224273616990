import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconBackDirectory: FC<IIcon> = ({ size = "23", color }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15_982)">
      <path d="M16 3.11111V7.63C15.296 7.23333 14.48 7 13.6 7C10.952 7 8.8 9.09222 8.8 11.6667C8.8 11.9311 8.832 12.1878 8.872 12.4444H1.6C0.72 12.4444 0 11.7522 0 10.8889V1.55556C0 0.692222 0.712 0 1.6 0H6.4L8 1.55556H14.4C15.28 1.55556 16 2.24778 16 3.11111ZM11.2 10.8889H12.8V14H14.4V10.8889H16L13.6 8.55556L11.2 10.8889Z" fill="#484848"/>
      </g>
      <defs>
      <clipPath id="clip0_15_982">
      <rect width="16" height="14" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default FuIconBackDirectory
