import { IThread, IThreadComment } from "fu/models/thread"
import client from "./client"
import formatError from "./error"
import { ITimeline } from "fu/models/timeline"

export interface ReqListThread {
  id_community: string
  limit: number
  current_page: number
}

export interface ResListThread {
  status: boolean
  data: Array<ITimeline>
}

export interface ReqThreadDelete {
  id_thread: string
  id_user: string
}

export interface ResThreadDelete {
  status: boolean
  message: string
}

export interface ReqThreadCreate {
  id_community: string
  id_user: string
  title: string
  content: string
}

export interface ResThreadCreate {
  status: boolean
  message: string
}

export interface ReqThreadCommentCreate {
  id_thread: string
  id_user: string
  comment: string
}

export interface ResThreadCommentCreate {
  status: boolean
  message: string
}

export interface ReqThreadUpdate {
  id_thread: string
  title: string
  content: string
  deleted_files: string
}

export interface ResThreadUpdate {
  status: boolean
  message: string
}

export interface ReqThreadDetail {
  id_thread: string
}

export interface ResThreadDetail {
  status: boolean
  data: IThread
}

export interface ReqListThreadComment {
  id_thread: string
  limit: number
  current_page: number
}

export interface ResListThreadComment {
  status: boolean
  data: Array<IThreadComment>
}

export interface ReqThreadCommentDelete {
  id_user: string
  id_comment_thread: string
}

export interface ResThreadCommentDelete {
  status: boolean
  message: string
}

export const listThreadService = (body: ReqListThread): Promise<ResListThread> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.post<ResListThread>("thread", body)).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})

export const listThreadCommentService =
  (body: ReqListThreadComment): Promise<ResListThreadComment> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResListThreadComment>("thread/comment", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const threadDetailService =
  (body: ReqThreadDetail): Promise<ResThreadDetail> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.get<ResThreadDetail>(`thread/detail?id_thread=${body.id_thread}`)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const threadCreateService = 
  (body: ReqThreadCreate): Promise<ResThreadCreate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResThreadCreate>("thread/create", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const threadCommentCreateService = 
  (body: ReqThreadCommentCreate): Promise<ResThreadCommentCreate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResThreadCreate>("thread/comment/create", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const threadUpdateService = 
  (body: ReqThreadUpdate): Promise<ResThreadUpdate> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResThreadUpdate>("thread/update", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const threadDeleteService = 
  (body: ReqThreadDelete): Promise<ResThreadDelete> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResThreadDelete>("thread/delete", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })

export const threadCommentDeleteService =
  (body: ReqThreadCommentDelete): Promise<ResThreadCommentDelete> => new Promise(async (resolve, reject) => {
    try {
      const response = (await client.post<ResThreadCommentDelete>("thread/comment/delete", body)).data
      resolve(response)
    } catch (err: any) {
      reject(formatError(err))
    }
  })