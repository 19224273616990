import { FuImage } from "fu/components/FuImage"
import { AppState } from "fu/stores/reducers"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import BannerPagination from "./Pagination"
import BannerPreview from "../BannerPreview"
import { IBanner } from "fu/models/banner"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 9px;
  background: clear;
`

type ContentProps = {
  translateX: string
  reset: boolean
}

const Content = styled.div<ContentProps>`
  display: grid;
  grid-gap: 0;
  grid-auto-columns: 80%;
  grid-auto-flow: column;
  scroll-behavior: smooth;
  justify-content: flex-start;
  transition: all 1s;

  ${({ translateX }) => `
    transform: translateX(${translateX}%);
  `};

  ${({ reset }) => reset && `
    transform: translateX(0);
    transition: none;
  `};
`

const Item = styled.div`
  overflow: hidden;
  padding: 0 9px;
`

const Image = styled(FuImage)`
  object-fit: contain;
  border-radius: 10px;
  overflow: hidden;
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  padding: 0 9px;
`

const HomeBanner: FC = () => {
  const { banners } = useSelector((state: AppState) => state.static.listBanner)
  const [current, setCurrent] = useState<number>(0)
  const [temp, setTemp] = useState<number>(0)
  const [reset, setReset] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<string | null>(null)

  const handleShowPreview = useCallback((itemIdt?: string) => () => {
    if (itemIdt) return setShowPreview(itemIdt)

    setShowPreview(null)
  }, [])

  const items: Array<IBanner> = useMemo(() => {
    if (banners.length > 0) {
      return [...banners, ...banners]
    }

    return banners
  }, [banners, current])

  const handleNextPagination = useCallback(() => {
    if (current + 1 === banners.length) {
      setCurrent(0)
      setTemp(prevState => prevState + 1)

      const timeout = setTimeout(() => {
        setReset(true)
      }, 1000)
  
      return () => clearTimeout(timeout)
    }

    setCurrent(current + 1)
    setTemp(current + 1)
    setReset(false)
  }, [current, banners, setCurrent, setTemp, setReset])

  useEffect(() => {
    const intervalId = setInterval(() => {
      !showPreview && handleNextPagination()
    }, 5000)
  
    return () => clearInterval(intervalId)
  }, [handleNextPagination, showPreview])

  const handleChangePagination = useCallback((value: number) => {
    setCurrent(value)
    setTemp(value)
  }, [setCurrent, setTemp])

  const translateX = useMemo(() => `-${temp * 80}`, [temp, current])

  return (
    <Container>
      <Content
        translateX={translateX}
        reset={reset}
      >
        {
          items.map((image, index) => (
            <Item key={index} onClick={handleShowPreview(image.banner)}>
              <Image
                source={`upload/banner/${image.banner}`}
                alt="banner"
              />
            </Item>
          ))
        }
      </Content>
      {
        banners.length > 0 && (
          <Pagination>
            <BannerPagination
              total={banners.length}
              current={current}
              onChange={handleChangePagination}
            />
          </Pagination>
        )
      }
      <BannerPreview onClose={handleShowPreview()} image={showPreview} />
    </Container>
  )
}

export default HomeBanner