import { CacheKey, IStorage } from "fu/models/auth"
import { IUser } from "fu/models/user"
import { Dispatch } from "redux"

export const STATE_BEGIN = "AUTHENTICATE_BEGIN"
export const STATE_SUCCESS = "AUTHENTICATE_SUCCESS"
export const STATE_FAILED = "AUTHENTICATE_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: IStorage
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

interface CacheValues {
  [CacheKey.Token]: string
  [CacheKey.User]: IUser
}

interface CacheUtil {
  set: <T extends CacheKey>(key: T, object: CacheValues[T]) => void
  get: <T extends CacheKey>(key: T) => CacheValues[T]
  remove: (key: CacheKey) => void
  removeAll: () => void
}

export const cacheUtil: CacheUtil = {
  set: (key, object) => {
    localStorage.setItem(key, JSON.stringify(object));
  },
  get: key => JSON.parse(localStorage.getItem(key) ?? "null"),
  remove: (key) => localStorage.removeItem(key),
  removeAll: () => localStorage.clear()
}

export default () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: STATE_BEGIN })    

    const token = cacheUtil.get(CacheKey.Token)
    const user = cacheUtil.get(CacheKey.User)

    if (!token || !user) {
      cacheUtil.removeAll()
      dispatch({ type: STATE_FAILED })
      return
    }

    dispatch({
      type: STATE_SUCCESS,
      payload: {
        token: token,
        user: user
      }
    })
  } catch (error: any) {
    dispatch({ type: STATE_FAILED })
  }
}
