import React, { FC, useCallback } from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

type BulletProps = {
  active: boolean
}

const Bullet = styled.div<BulletProps>`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.primaryMain};
  background: ${({ theme }) => theme.white};
  cursor: pointer;

  ${({ theme, active }) => active && `
    background: ${theme.primaryMain};
  `};
`

type ItemProps = {
  value: number
  current: number
  onChange: (value: number) => void
}

const Item: FC<ItemProps> = ({ value, current, onChange }) => {
  const handleSelect = useCallback(() => {
    if (current !== value) {
      onChange(value)
    }
  }, [value, current, onChange])

  return (
    <Bullet
      active={value === current}
      onClick={handleSelect}
    />
  )
}

type PaginationProps = {
  current: number,
  total: number,
  onChange: (current: number) => void
}


const BannerPagination: FC<PaginationProps> = ({ current, total, onChange }) => (
  <Container>
    {
      Array.from(Array(total), (_, i) => (
        <Item
          key={i}
          current={current}
          value={i}
          onChange={onChange}
        />
      ))
    }
  </Container>
)

export default BannerPagination