import staticAsset from "fu/assets/images/static"
import { FuModal } from "../FuModal"
import { useAppDispatch } from "fu/stores"
import logoutAction from "fu/stores/actions/auth/logout"
import { AppState } from "fu/stores/reducers"
import { Paths, Path } from "fu/routes/paths"
import { useTranslation } from "react-i18next"
import React, { Fragment, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { FuIconLogout, FuIconArrowLeft } from "../FuIcon"
import {
  Header,
  HeaderAction,
  HeaderBackBtnContainer,
  HeaderBlank,
  HeaderBlankContent,
  HeaderContent,
  HeaderLogo,
  HeaderTitle,
  Logo,
  TextLogo,
  LogoutConfirmTitle,
  LogoutConfirmDesc,
  LogoutChoiceContainer,
  LogoutChoice,
  LogoutChoiceWithPadding
} from "./Component"

const {
  LOGIN,
  CATEGORY,
  COMMUNITY,
  COMMUNITY_DETAIL,
  COMMUNITY_ADD,
  COMMUNITY_EDIT,
  HOME,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  CATEGORY_DETAIL,
  COMMUNITY_TIMELINE,
  FORUM_DISCUSSION_ADD,
  FORUM_DISCUSSION_EDIT,
  COMMUNITY_TIMELINE_POST,
  COMMUNITY_THREAD_DETAIL,
  FILE_MANAGER_MOVE
} = Paths

const headerWithMainLogoAndName = [
  LOGIN,
  CATEGORY,
  COMMUNITY,
  COMMUNITY_DETAIL,
  HOME,
  COMMUNITY_ADD,
  COMMUNITY_EDIT,
  CATEGORY_DETAIL,
  COMMUNITY_TIMELINE,
  FORUM_DISCUSSION_ADD,
  FORUM_DISCUSSION_EDIT,
  COMMUNITY_TIMELINE_POST,
  COMMUNITY_THREAD_DETAIL,
  FILE_MANAGER_MOVE
];

const blankBgHeaderWithTitleAndBackNav = [
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS
];

const FuHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { status } = useSelector((state: AppState) => state.auth.authenticate)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState<boolean>(false)

  const getRouteTitle = useMemo(() => (
    Object.entries(Paths).filter(route => route[1].path === pathname).length > 0 ?
      Object.entries(Paths).filter(route => route[1].path === pathname)[0][1].title :
      ""
  ), [pathname])

  const isUseRoute = useCallback((routes: Array<Path>) => (
    routes.map(route => route.path.replace(':slug', '')).includes(pathname.replace(/[0-9]+/g, ''))
  ), [pathname])

  const handleShowLogoutConfirmation = useCallback(() => {
    setShowLogoutConfirmation(!showLogoutConfirmation)
  }, [showLogoutConfirmation])

  const handleConfirmLogout = useCallback(() => {
    handleShowLogoutConfirmation()
  }, [handleShowLogoutConfirmation])

  const handleLogout = useCallback(() => {
    handleShowLogoutConfirmation()
    dispatch(logoutAction())
  }, [dispatch, logoutAction, handleShowLogoutConfirmation])

  const handleNavigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handlePageHome = useCallback(() => {
    navigate("/")
  }, [navigate])

  return (
    <Fragment>
      {isUseRoute(headerWithMainLogoAndName) && (
        <Header>
          <HeaderContent>
            <HeaderLogo>
              <Logo
                source={staticAsset.logo}
                alt="Purposeful People"
                onClick={handlePageHome}
              />
              <TextLogo onClick={handlePageHome}>Purposeful People</TextLogo>
            </HeaderLogo>
            {
              status === "authenticated" && (
                <HeaderAction onClick={handleConfirmLogout}>
                  <FuIconLogout color="white" />
                </HeaderAction>
              )
            }
          </HeaderContent>
        </Header>
      )}
      {isUseRoute(blankBgHeaderWithTitleAndBackNav) && (
        <HeaderBlank>
          <HeaderBlankContent>
            <HeaderBackBtnContainer onClick={handleNavigateBack}>
              <FuIconArrowLeft color="black" />
            </HeaderBackBtnContainer>
              <HeaderTitle>{t(getRouteTitle)}</HeaderTitle>
          </HeaderBlankContent>
        </HeaderBlank>
      )}
      <FuModal
        isOpen={showLogoutConfirmation}
        onClose={handleShowLogoutConfirmation}
        children={
          <Fragment>
            <LogoutConfirmTitle>
              {t("logout.confirmationTitle")}
            </LogoutConfirmTitle>
            <LogoutConfirmDesc>
              {t("logout.confirmationDesc")}
            </LogoutConfirmDesc>
            <LogoutChoiceContainer>
              <LogoutChoiceWithPadding onClick={handleLogout}>
                {t("form.confirmationYes")}
              </LogoutChoiceWithPadding>
              <LogoutChoice onClick={handleShowLogoutConfirmation}>
                {t("form.confirmationNo")}
              </LogoutChoice>
            </LogoutChoiceContainer>
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default FuHeader