import { Dispatch } from "redux"
import {
  threadDetailService,
  ReqThreadDetail,
  ResThreadDetail
} from "fu/services/thread"

const STATE_BEGIN = "DETAIL_THREAD_BEGIN"
const STATE_SUCCESS = "DETAIL_THREAD_SUCCESS"
const STATE_FAILED = "DETAIL_THREAD_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResThreadDetail
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqThreadDetail) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await threadDetailService(body)
    dispatch({
      type: STATE_SUCCESS,
      payload: response.data
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
