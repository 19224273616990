import { communityDetailService, ReqCommunityDetail, ResCommunityDetail } from "fu/services/community"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "DETAIL_COMMUNITY_BEGIN"
const STATE_SUCCESS = "DETAIL_COMMUNITY_SUCCESS"
const STATE_FAILED = "DETAIL_COMMUNITY_FAILED"

interface PartialReqCommunityDetail {
  id_community: string
}

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResCommunityDetail
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: PartialReqCommunityDetail) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: STATE_BEGIN })

      const { user: { id_user } } = getState().auth.authenticate
      const response = await communityDetailService({ ...body, id_user })

      dispatch({
        type: STATE_SUCCESS,
        payload: response
      })
    } catch (error: any) {
      dispatch({
        type: STATE_FAILED,
        error,
      })
    }
  }
