import React, { FC, useCallback, useMemo } from "react"
import styled from "styled-components"

export type Tabs = "timeline" | "thread"

type Props = {
  current: Tabs
  tab: Tabs
  text: string
  onChange: (value: Tabs) => void
}

type MenuItemProps = {
  active: boolean
}

const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${({ theme, active }) => active && `
    ${MenuLine} {
      background-color: ${theme.primaryMain};
    }
  `};
`

const MenuLine = styled.div`
  height: 4px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
`

const TextMenu = styled.span`
  font-size: 11.5px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;

  @media only screen and (min-width: 600px) {
    font-size: 14px;
  }
`

const HomeTab: FC<Props> = ({ tab, current, text, onChange }) => {
  const active = useMemo(() => {
    return tab === current
  }, [tab, current])

  const handleChange = useCallback(() => {
    if (tab !== current) {
      onChange(tab)
    }
  }, [tab, current, onChange])

  return (
    <MenuItem
      active={active}
      onClick={handleChange}
    >
      <TextMenu>{text}</TextMenu>
      <MenuLine />
    </MenuItem>
  )
}

export default HomeTab