import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconDropdown: FC<IIcon> = ({ width = "10", height = "55", color, className = "" }) => {
  return (
    <Icon color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L5 5L10 0H0Z" fill="#FF212A"/>
    </Icon>
  )
}

export default FuIconDropdown
