import { StateActions } from "fu/stores/actions/auth/login"

type State = {
  loading: boolean
  status: boolean
  token: string
  error: any
}

const initialState: State = {
  loading: false,
  status: false,
  token: "",
  error: null
}

const loginReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "LOGIN_BEGIN":
      return {
        ...state,
        loading: true,
        error: null
      }
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        status: true,
        token: action.payload.token
      }
    case "LOGIN_FAILED":
      return {
        ...state,
        loading: false,
        error: state.error
      }
    default:
      return state
  }
}

export default loginReducer