import React, { FC, useCallback, useMemo, useEffect, Fragment, useState, useRef, useContext } from "react"
import { FuIconArrowLeft, FuIconMenu, FuIconWarning, FuIconEditPost, FuIconTrash, FuIconSend } from "fu/components/FuIcon"
import { Paths } from "fu/routes/paths"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { FuPointReward } from "fu/components/FuPointReward"
import { FuInputLabel, FuInput } from "fu/components/FuInput"
import { FuModal } from "fu/components/FuModal"
import { AVATAR_URL } from "fu/constants/Environment"
import { FuNotFound } from "fu/components/FuNotFound"
import { FuLoader } from "fu/components/FuLoader"
import { FuImage } from "fu/components/FuImage"
import { DefaultTheme } from "styled-components"
import { useLocation, useParams, useNavigate } from "react-router-dom"
import listTimelineComment from "fu/stores/actions/timeline/listTimelineComment"
import createTimelineComment from "fu/stores/actions/timeline/createTimelineComment"
import deleteTimelineComment from "fu/stores/actions/timeline/deleteTimelineComment"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import { handleToast } from "fu/utils/toast"
import updateTimeline from "fu/stores/actions/timeline/updateTimeline"
import deleteTimeline from "fu/stores/actions/timeline/deleteTimeline"
import { useAppDispatch } from "fu/stores"
import { useSelector } from "react-redux"
import { AppState } from "fu/stores/reducers"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import styled from "styled-components"
import { useTranslation, Trans } from "react-i18next"
import moment from "moment"
import { IUser } from "fu/models/user"
import useOutsideClick from "fu/utils/useOutsideClick"
import { ITimelineComment } from "fu/models/timeline"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  padding: 14px 0;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 100px;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  user-select: none;
  padding: 0 18px;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const TopMainPostContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  padding: 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const TopMainPostLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const TopMainPostLeftDescContainer = styled.div`
  margin-left: 12px;
`

const TopMainPostImage = styled(FuImage)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`

const UsernameText = styled.p`
  font-size: 11px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
`

const DateText = styled.p`
  font-size: 11px;
  font-family: Poppins-Light, sans-serif;
  color: ${({ theme }) => theme.greyLight};
`

const CommentText = styled.p`
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
`

const TimelinePostMenuIcon = styled.div`
  position: sticky;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
`

const TimelinePostMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  width: 150px;
  padding: 12px;
  padding-bottom: 0px;
  border-radius: 10px;
  left: -150px;
  top: 15px;
  box-shadow: 0 0 2px 2px #ccc;
  z-index: 300;
`

const TimelinePostMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 14px;
`

const TimelinePostMenuContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TimelinePostMenuText = styled.p`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-left: 6px;
`

const AddNewTimelineTitle = styled.p`
  font-size: 16px;
  font-family: Poppins-Medium, sans-serif;
  text-align: center;
  color: ${({ theme }) => theme.greyLabel};
  margin-bottom: 12px;
`

const AddNewTimelineTextTnc = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.greyLabel};
  font-family: Poppins-Regular, sans-serif;
  margin-top: 8px;
  margin-bottom: 20px;
`

const AddNewTimelineTextTncActive = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.primaryMain};
  font-family: Poppins-Medium, sans-serif;
  cursor: pointer;
`

const ConfirmAddFileTitle = styled.p`
  font-family: Poppins-Bold, sans-serif;
  margin-bottom: 12px;
  font-size: 16px;
`

const ConfirmAddFileDesc = styled.p`
  font-family: Poppins-Regular, sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
  max-width: 450px;
`

const ConfirmAddFileBtnContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
`

const ConfirmAddFileBtnText = styled(ConfirmAddFileDesc)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
`

const ConfirmAddFileBtnLeftText = styled(ConfirmAddFileBtnText)`
  margin-right: 20px
`

const CommentContainer = styled.div`
  margin: 18px 18px 0 18px;
  display: flex;
  flex-direction: row;
`

const CommentPostContainer = styled.div`
  margin-left: 12px;
  padding: 18px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.white};
  width: 100%;
`

const CommentPostIconContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const CommentIconContainer = styled.div`
  cursor: pointer;
`

const CommentIconRightContainer = styled(CommentIconContainer)`
  margin-left: 12px;
`

const SendCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px;
  background-color: ${({ theme }) => theme.lightMain};
  width: 100%;
`

const SendCommentInputContainer = styled.div`
  width: 100%;
`

const SendCommentButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.primaryMain};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
`

const MoreCommentText = styled.p`
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-top: 18px;
`

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const EditTimelineModalStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '360px'
  }
}

const inputEditTimelinePost = { name: "content" }
const inputCommentName: any = { name: "comment", backgroundColor: "white", border: "white" }

interface Props {
  handleSetBgColor: (color: keyof DefaultTheme) => void
}

const {
  PRIVACY_POLICY: { path: PrivacyPolicyPath },
  TERMS_AND_CONDITIONS: { path: TnCPath }
} = Paths

const ViewCommunityTimelineComment: FC<Props> = ({ handleSetBgColor }) => {
  const wrapperRef = useRef(null);
  const { state: { post, detail } } = useLocation()
  const {
    showPointReward,
    handleShowPointReward,
    currentShowPoint,
    handleShowCurrentPoint,
    showPointRewardTitle,
    handleShowPointRewardTitle
  }:
    {
      showPointReward: boolean,
      handleShowPointReward: () => void,
      currentShowPoint: number,
      handleShowCurrentPoint: (val: number) => void,
      showPointRewardTitle: string,
      handleShowPointRewardTitle: (val: string) => void
    } =
    useContext(GeneralContext)
  const { slug } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loading, comment } = useSelector((state: AppState) => state.timeline.listTimelineComment)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showTopPostMenu, setShowTopPostMenu] = useState<boolean>(false)
  const [showEditTimelineModal, setShowEditTimelineModal] = useState<boolean>(false)
  const [editTimelineContent, setEditTimelineContent] = useState<string>(post.content)
  const [confirmedEditedContent, setConfirmedEditedContent] = useState<string>(post.content)
  const [showConfirmDeleteTimelineModal, setShowConfirmDeleteTimelineModal] = useState<boolean>(false)
  const [showConfirmDeleteCommentModal, setConfirmDeleteCommentModal] = useState<string | null>(null)
  const [showConfirmReportCommentModal, setShowConfirmReportCommentModal] = useState<boolean>(false)
  const [currentReportedComment, setCurrentReportedComment] = useState<ITimelineComment | null>(null)
  const [inputComment, setInputComment] = useState<string>("")
  const { user }: { user: IUser } = useSelector((state: AppState) => state.auth.authenticate)
  useOutsideClick(wrapperRef, () => {
    setShowTopPostMenu(false)
  })

  const handleShowConfirmDeleteCommentModal = useCallback((id_feed_comment?: string) => () => {
    setConfirmDeleteCommentModal(id_feed_comment || null)
  }, [])

  const handleAddMorePage = useCallback(() => {
    setCurrentPage(currentPage + 1)
  }, [currentPage])

  const handleOnChangeInputComment = (_: string, text: string) => {
    setInputComment(text)
  }

  const handleShowConfirmReportCommentModal = useCallback((post?: ITimelineComment) => () => {
    setCurrentReportedComment(post || null)
    setShowConfirmReportCommentModal(!showConfirmReportCommentModal)
  }, [showConfirmReportCommentModal])

  const handleEditTimelineContent = useCallback((_: string, text: string) => {
    setEditTimelineContent(text)
  }, [])
  
  const handleShowEditTimelineModal = useCallback(() => {
    setShowEditTimelineModal(!showEditTimelineModal)
  }, [showEditTimelineModal])

  const handleShowTopPostMenu = useCallback(() => {
    setShowTopPostMenu(!showTopPostMenu)
  }, [showTopPostMenu])

  useEffect(() => {
    handleSetBgColor("lightMain")

    return () => {
      handleSetBgColor("white")
    }
  }, [])

  useEffect(() => {
    dispatch(listTimelineComment({ id_feed: slug || "", limit: 10, current_page: currentPage }))
  }, [currentPage, slug])

  const handlePageBack = useCallback(() => {
    navigate(-1)
  }, [])

  const handleSendComment = useCallback(async () => {
    if (!inputComment.length) {
      handleToast("error", t("timelinePost.commentSendError"))
    } else {
      const result = await dispatch(createTimelineComment({
        id_feed: slug || "",
        comment: inputComment
      }))

      if (result.isSuccess) {
        setCurrentPage(1)
        setInputComment("")
        await dispatch(listTimelineComment({ limit: 10, current_page: 1, id_feed: slug || ""}))

        handleShowCurrentPoint(50)
        handleShowPointRewardTitle("Membuat Post atau Reply di Timeline Community")
        handleShowPointReward()
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }
    }
  }, [inputComment, post, slug])

  const handleEditTimeline = useCallback(() => {
    setShowTopPostMenu(false)
    setEditTimelineContent(confirmedEditedContent)
    setShowEditTimelineModal(true)
  }, [confirmedEditedContent])

  const handleReportTimelinePost = useCallback((user_name: string) => () => {
    setShowTopPostMenu(false)

    const line1 = 'Saya ingin melaporkan Postingan Timeline'
    const line2 = `Dari: *${user_name}*`
    const line3 = 'Grup'
    const link = `https://web.whatsapp.com/send?phone=+6281219406474&text=${encodeURIComponent(`${line1}\r\n\n${line2}\r\n${line3}`)}&app_absent=0`
    window.open(link, '_blank', 'noopener,noreferrer')
  }, [])

  const handleReportComment = useCallback(() => {
    setShowConfirmReportCommentModal(false)

    const line1 = 'Saya ingin melaporkan Postingan Komentar Timeline'
    const line2 = `Dari: *${currentReportedComment?.user.full_name}*`
    const line3 = `Konten: *${currentReportedComment?.comment}*`
    const line4 = 'Dikarenakan : <Isi alasan anda>'
    const link = `https://web.whatsapp.com/send?phone=+6281219406474&text=${encodeURIComponent(`${line1}\r\n\n${line2}\r\n${line3}\r\n\n${line4}`)}&app_absent=0`
    window.open(link, '_blank', 'noopener,noreferrer')
  }, [currentReportedComment])

  const handleShowConfirmDeleteTimelineModal = useCallback(async () => {
    setShowTopPostMenu(false)
    setShowConfirmDeleteTimelineModal(!showConfirmDeleteTimelineModal)
  }, [showConfirmDeleteTimelineModal])

  const handleConfirmEditTimeline = useCallback(async () => {
    if (!editTimelineContent.length) {
      handleToast("error", t("communityTimeline.addTimelineModal.error"))
    } else {
      setShowEditTimelineModal(false)

      const result = await dispatch(updateTimeline({
        id_feed: post.id_feed,
        content: editTimelineContent
      }))

      if (result.isSuccess) {
        setConfirmedEditedContent(editTimelineContent)
        handleToast("success", result.message)
      } else {
        handleToast("error", result.message)
      }
    }
  }, [post.id_feed, editTimelineContent])

  const handleConfirmDeleteTimeline = useCallback(async () => {
    setShowConfirmDeleteTimelineModal(false)

    const result = await dispatch(deleteTimeline({
      id_feed: post.id_feed
    }))
    
    if (result.isSuccess) {
      navigate(-1)
      handleToast("success", result.message)
    } else {
      handleToast("error", result.message)
    }
  }, [post.id_feed])

  const handleConfirmDeleteComment = useCallback(async () => {
    setConfirmDeleteCommentModal(null)

    const result = await dispatch(deleteTimelineComment({
      id_comment_feed: showConfirmDeleteCommentModal || ""
    }))

    if (result.isSuccess) {
      setCurrentPage(1)
      await dispatch(listTimelineComment({ id_feed: slug || "", limit: 10, current_page: 1 }))

      handleToast("success", result.message)
    } else {
      handleToast("error", result.message)
    }
  }, [showConfirmDeleteCommentModal])

  const handleNavigateTermsAndPolicy = useCallback((route: string) => () => {
    navigate(route);
  }, [navigate])

  const renderEditTimelineModal = useMemo(() => (
    <FuModal
      isOpen={showEditTimelineModal}
      onClose={handleShowEditTimelineModal}
      customStyles={EditTimelineModalStyles}
      children={
        <Fragment>
          <AddNewTimelineTitle>
            {t("communityTimeline.addTimelineModal.title")}
          </AddNewTimelineTitle>
          <FuInputLabel
            isTextArea
            input={inputEditTimelinePost}
            type={"text"}
            label={""}
            placeholder={t("communityTimeline.addTimelineModal.placeholder")}
            value={editTimelineContent}
            onChange={handleEditTimelineContent}
          />
          <AddNewTimelineTextTnc>
            <Trans
              i18nKey="login.tnc"
              components={{
                policy:
                  <AddNewTimelineTextTncActive
                    onClick={
                      handleNavigateTermsAndPolicy(PrivacyPolicyPath)
                    }
                  />,
                tnc:
                  <AddNewTimelineTextTncActive
                    onClick={
                      handleNavigateTermsAndPolicy(TnCPath)
                    }
                  />
              }}
            />
          </AddNewTimelineTextTnc>
          <FuButton
            button={mainButton}
            text={t("communityTimeline.addTimelineModal.button")}
            onClick={handleConfirmEditTimeline}
          />
        </Fragment>
      }
    />
  ), [showEditTimelineModal, handleShowEditTimelineModal, editTimelineContent])

  const renderTopMainPost = useMemo(() => (
    <TopMainPostContainer>
      <TopMainPostLeftContainer>
        <TopMainPostImage
          source={
            post.user.photo.length > 0 ?
              `upload/profil/${post.user.photo}` :
              (AVATAR_URL || "")
          }
          alt={'User-Photo'}
          alternateImage={AVATAR_URL}
        />
        <TopMainPostLeftDescContainer>
          <UsernameText>{post.user.full_name}</UsernameText>
          <DateText>{moment(post.created_at).format('DD MMM YYYY')}</DateText>
          <CommentText>{confirmedEditedContent}</CommentText>
        </TopMainPostLeftDescContainer>
      </TopMainPostLeftContainer>
      <TimelinePostMenuIcon onClick={handleShowTopPostMenu}>
        <Fragment>
          {showTopPostMenu && (
            <TimelinePostMenu ref={wrapperRef}>
              <TimelinePostMenuContainer onClick={handleReportTimelinePost(post.user.full_name)}>
                <TimelinePostMenuContainerIcon>
                  <FuIconWarning />
                </TimelinePostMenuContainerIcon>
                <TimelinePostMenuText>
                  {t("communityTimeline.menu.report")}
                </TimelinePostMenuText>
              </TimelinePostMenuContainer>
              
                {user.id_user === post.user.id_user && (
                  <TimelinePostMenuContainer
                    onClick={handleEditTimeline}
                  >
                    <TimelinePostMenuContainerIcon>
                      <FuIconEditPost color={"primaryMain"} />
                    </TimelinePostMenuContainerIcon>
                    <TimelinePostMenuText>
                      {t("communityTimeline.menu.edit")}
                    </TimelinePostMenuText>
                  </TimelinePostMenuContainer>
                )}

                {(user.id_user === post.user.id_user ||
                  detail?.is_admin ||
                  detail?.id_user === post.user.id_user
                ) && (
                  <TimelinePostMenuContainer onClick={handleShowConfirmDeleteTimelineModal}>
                    <TimelinePostMenuContainerIcon>
                      <FuIconTrash color={"primaryMain"} />
                    </TimelinePostMenuContainerIcon>
                    <TimelinePostMenuText>
                      {t("communityTimeline.menu.delete")}
                    </TimelinePostMenuText>
                  </TimelinePostMenuContainer>
                )}
              
            </TimelinePostMenu>
          )}
        </Fragment>
        <FuIconMenu />
      </TimelinePostMenuIcon>
    </TopMainPostContainer>
  ), [confirmedEditedContent, showTopPostMenu, post, user, handleShowTopPostMenu, handleReportTimelinePost, handleEditTimeline, handleShowConfirmDeleteTimelineModal])
  
  const renderConfirmDeleteTimelineModal = useMemo(() => showConfirmDeleteTimelineModal && (
    <FuModal
      isOpen={showConfirmDeleteTimelineModal}
      onClose={handleShowConfirmDeleteTimelineModal}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.deleteTimelineModal.title")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.deleteTimelineModal.description")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteTimeline}>
              {t("communityTimeline.deleteTimelineModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteTimelineModal}>
              {t("communityTimeline.deleteTimelineModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmDeleteTimelineModal, handleShowConfirmDeleteTimelineModal, handleConfirmDeleteTimeline])

  const renderConfirmDeleteCommentModal = useMemo(() => showConfirmDeleteCommentModal && (
    <FuModal
      isOpen={showConfirmDeleteCommentModal ? true : false}
      onClose={handleShowConfirmDeleteCommentModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("timelinePost.commentDeleteTitle")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("timelinePost.commentDeleteDescription")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteComment}>
              {t("timelinePost.commentDeleteAgree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteCommentModal()}>
              {t("timelinePost.commentDeleteCancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmDeleteCommentModal, handleShowConfirmDeleteCommentModal, handleConfirmDeleteComment])

  const renderConfirmReportCommentModal = useMemo(() => showConfirmReportCommentModal && (
    <FuModal
      isOpen={showConfirmReportCommentModal}
      onClose={handleShowConfirmReportCommentModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("timelinePost.commentReportTitle")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("timelinePost.commentReportDescription")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleReportComment}>
              {t("timelinePost.commentReportAgree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmReportCommentModal()}>
              {t("timelinePost.commentReportCancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [handleReportComment, handleShowConfirmReportCommentModal, showConfirmReportCommentModal])

  const renderComments = useMemo(() => !loading && (
    <Fragment>
      {!comment.length ? (
        <FuNotFound text={t("timelinePost.noComment")} />
      ) : (
        <Fragment>
          {comment.map((data, index) => (
            <CommentContainer key={index}>
              <TopMainPostImage
                source={
                  data.user.photo.length > 0 ?
                    `upload/profil/${data.user.photo}` :
                    (AVATAR_URL || "")
                }
                alt={'User-Photo'}
                alternateImage={AVATAR_URL}
              />
              <CommentPostContainer>
                <div>
                  <UsernameText>{data.user.full_name}</UsernameText>
                  <DateText>{moment(data.created_at).format('DD MMM YYYY')}</DateText>
                  <CommentText>{data.comment}</CommentText>
                </div>
                <CommentPostIconContainer>
                  {(user.id_user === data.id_user || user.id_user === detail.is_admin) && (
                    <CommentIconContainer onClick={handleShowConfirmDeleteCommentModal(data.id_comment_feed)}>
                      <FuIconTrash />
                    </CommentIconContainer>
                  )}
                  <CommentIconRightContainer onClick={handleShowConfirmReportCommentModal(data)}>
                    <FuIconWarning />
                  </CommentIconRightContainer>
                </CommentPostIconContainer>
              </CommentPostContainer>
            </CommentContainer>
          ))}
          {!loading && comment.length === currentPage * 10 && (
            <MoreCommentText onClick={handleAddMorePage}>
              {t("timelinePost.moreComment")}
            </MoreCommentText>
          )}
        </Fragment>
      )}
    </Fragment>
  ), [handleShowConfirmDeleteCommentModal, user, detail, comment, currentPage, loading, handleShowConfirmReportCommentModal, handleAddMorePage])

  const renderPointRewardModal = useMemo(() => showPointReward && (
    <FuPointReward
      title={showPointRewardTitle}
      point={currentShowPoint}
      isOpen={showPointReward}
      onClose={handleShowPointReward}
    />
  ), [showPointReward, handleShowPointReward, showPointRewardTitle, currentShowPoint])

  return (
    <Container>
      <Content>
        <Header>
          <IconHeader onClick={handlePageBack}>
            <FuIconArrowLeft />
          </IconHeader>
          <TextHeader>{t("timelinePost.title")}</TextHeader>
        </Header>
      </Content>
      {(loading && currentPage === 1) ? (
        <LoaderContainer>
          <FuLoader
            color={defaultTheme.primaryMain}
            width={80}
            height={80}
            type={"bubbles"}
          />
        </LoaderContainer>
      ) : (
        <ContentScroll>
          {renderTopMainPost}
          {renderComments}
        </ContentScroll>
      )}
      {renderEditTimelineModal}
      {renderConfirmDeleteTimelineModal}
      {renderConfirmReportCommentModal}
      {renderConfirmDeleteCommentModal}
      {renderPointRewardModal}
      <SendCommentContainer>
        <SendCommentInputContainer>
          <FuInput
            input={inputCommentName}
            type="text"
            placeholder={t("timelinePost.commentInputPlaceholder")}
            value={inputComment}
            onChange={handleOnChangeInputComment}
          />
        </SendCommentInputContainer>
        <SendCommentButton onClick={handleSendComment}>
          <FuIconSend />
        </SendCommentButton>
      </SendCommentContainer>
    </Container>
  )
}

export default ViewCommunityTimelineComment
