import { KeyboardEvent } from "./types"
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from "react"
import styled from "styled-components"

interface IInput {
  name: string
  radius?: string
}

type Props = {
  input: IInput
  type: string
  placeholder: string
  label: string
  value: string
  onChange: (name: string, value: string) => void
  prepend?: JSX.Element
  append?: JSX.Element
  onKeyPress?: (e: KeyboardEvent) => void
  isTextArea?: boolean
}

type ContainerProps = {
  radius?: string
}

type LabelProps = {
  labeled: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  border: solid 1px ${({ theme }) => theme.greyBorder};
  border-radius: ${({ radius }) => radius || "6px"};
  padding: 14px 15px 14px 11px;
  position: relative;
`

const Input = styled.input.attrs(({ placeholder }) => ({
  placeholder: placeholder,
}))`
  width: 100%;
  box-sizing: border-box;
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.textMain} !important;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder { 
    color: ${({ theme }) => theme.greyPlaceholder};
  }
`

const TextArea = styled.textarea.attrs(({ placeholder }) => ({
  placeholder: placeholder,
}))`
  width: 100%;
  box-sizing: border-box;
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.textMain} !important;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder { 
    color: ${({ theme }) => theme.greyPlaceholder};
  }
`

const PrependIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`

const Label = styled.span<LabelProps>`
  font-family: Poppins-Medium, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.greyDark};
  position: absolute;
  padding: 2px 4px;
  left: 14px;

  ${({ labeled }) => labeled && `
    background: white;
    left: 18px;
    top: -12px;
  `};
`

const FuInput: FC<Props> = ({
  input,
  type,
  label,
  placeholder,
  value,
  onChange,
  prepend,
  append,
  onKeyPress,
  isTextArea
}) => {
  const [focus, setFocus] = useState<boolean>(false)

  const handleFocus = useCallback(() => {
    setFocus(true)
  }, [setFocus])

  const handleBlur = useCallback(() => {
    setFocus(false)
  }, [setFocus])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(input.name, event.target.value)
  }, [input.name, onChange])

  const labeled = useMemo(() => (focus || value !== ""), [focus, value])

  const getInputProps = useMemo(() => {
    let props = {
      onKeyPress: onKeyPress,
      placeholder: focus ? placeholder : "",
      value: value,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange
    }

    return isTextArea ? { ...props, rows: 6 } : { ...props, type }
  }, [
    isTextArea,
    focus,
    placeholder,
    value,
    handleFocus,
    handleBlur,
    handleChange,
    type
  ])

  return (
    <Container onClick={handleFocus}>
      <Label labeled={labeled}>
        {label}
      </Label>
      {
        prepend && focus && (
          <PrependIcon>
            {prepend}
          </PrependIcon>
        )
      }
      {isTextArea ? (
        <TextArea {...getInputProps} />
      ) : (
        <Input {...getInputProps} />
      )}

      {append && (append)}
    </Container>
  )
}

export default FuInput