import { Dispatch } from "redux"
import {
  actionUserJoinRequestService,
  ResActionUserJoinRequest
} from "fu/services/community"
import { RootState } from "fu/stores"

const STATE_BEGIN = "ACTION_USER_JOIN_REQUEST_BEGIN"
const STATE_END = "ACTION_USER_JOIN_REQUEST_END"

interface PartialReqActionUserJoinRequest {
  id_request: string
  action: "accept" | "reject"
  reason: string
}

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResActionUserJoinRequest
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: PartialReqActionUserJoinRequest) => async (
  dispatch: Dispatch, getState: () => RootState
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const { user: { id_user } } = getState().auth.authenticate

    const response = await actionUserJoinRequestService({...body, id_logged_user: id_user })

    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
