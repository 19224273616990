import { Dispatch } from "redux"
import {
  joinCommunityService,
  ResJoinCommunity
} from "fu/services/community"
import { RootState } from "fu/stores"

const STATE_BEGIN = "COMMUNITY_JOIN_BEGIN"
const STATE_END = "COMMUNITY_JOIN_END"

interface PartialReqJoinCommunity {
  id_community: string
}

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResJoinCommunity
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: PartialReqJoinCommunity) => async (
  dispatch: Dispatch, getState: () => RootState
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const { user: { id_user } } = getState().auth.authenticate

    const response = await joinCommunityService( {...body, id_user })
    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
