import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconFolderLarge: FC<IIcon> = ({ size = "26", color, className = "" }) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_387_130)">
      <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z" fill="#FFB1B5"/>
      <path d="M19.2 11H10.8C9.246 11 8 12.2237 8 13.75V30.25C8 30.9793 8.295 31.6788 8.8201 32.1945C9.3452 32.7103 10.0574 33 10.8 33H33.2C33.9426 33 34.6548 32.7103 35.1799 32.1945C35.705 31.6788 36 30.9793 36 30.25V16.5C36 14.9737 34.74 13.75 33.2 13.75H22L19.2 11Z" fill="#FF212A"/>
      </g>
      <defs>
      <clipPath id="clip0_387_130">
      <rect width="44" height="44" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default FuIconFolderLarge