import React, { FC, useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import loadingSrc from "./loadingSrc"
import { IMAGE_URL } from "fu/constants/Environment"

type Props = {
  source: string
  alt: string
  className?: string
  alternateImage?: string
}

type containerProps = {
  shimer: boolean
}

const Container = styled.div<containerProps>`
  display: block;
  
  ${({ theme, shimer }) => shimer && `
    background: ${theme.placeholder};
  `};
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const FuImage: FC<Props> = ({ className, source, alt = "image", alternateImage = "" }) => {
  const [error, setError] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [webp, setWebp] = useState("")

  const src = useMemo(() => {
    if (source && !error && source.includes('http')) return source
    if (source && !error) return `${IMAGE_URL}${source}${webp}`
    if (source && error) return alternateImage
    
    return loadingSrc
  }, [source, error, webp])

  const shimer = useMemo(() => {
    if (alternateImage.length > 0) return false
    if (!source || !isVisible || error) return true

    return false
  }, [source, isVisible, error, alternateImage])

  const handleLoaded = useCallback(() => {
    if (!source) {
      setIsVisible(false)
      return
    }

    setIsVisible(true)
  }, [source, setIsVisible])

  const handleError = useCallback(() => {
    if (webp) {
      setWebp('')
      return
    }

    setError(true)
  }, [webp, setError, setWebp])

  return (
    <Container shimer={shimer}>
      <Image
        className={className}
        src={src}
        alt={error ? "" : alt}
        loading="lazy"
        decoding="async"
        onLoad={handleLoaded}
        onError={handleError}
      />
    </Container>
  )
}

export default FuImage