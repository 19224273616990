import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconFileLarge: FC<IIcon> = ({ size = "26", color, className = "" }) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z" fill="#BDDFD3"/>
      <path d="M23.7713 18.1V10.95L30.9649 18.1H23.7713ZM14.6159 9C13.1641 9 12 10.157 12 11.6V32.4C12 33.0896 12.2756 33.7509 12.7662 34.2385C13.2567 34.7261 13.9221 35 14.6159 35H30.311C31.0047 35 31.6701 34.7261 32.1607 34.2385C32.6512 33.7509 32.9268 33.0896 32.9268 32.4V16.8L25.0793 9H14.6159Z" fill="#2DC08B"/>
    </svg>

  )
}

export default FuIconFileLarge