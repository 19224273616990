import { defaultTheme } from "../FuApp/FuTheme";
import { FuModal } from "../FuModal";
import { FuButton, ETypes, ESizes } from "../FuButton";
import React, { FC, Fragment, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  grid-gap: 10px;
  margin: 12px 0 12px 0;
`

const Title = styled.p`
  font-size: 16px;
  font-family: Poppins-Bold, sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
  margin-bottom: 18px;

  @media only screen and (max-width: 320px) {
    font-size: 14px;
  }
`

const DescContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  text-align: center;

  @media only screen and (max-width: 320px) {
    margin-bottom: 14px;
  }
` 

const Desc = styled.span`
  font-size: 14px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;

  @media only screen and (max-width: 320px) {
    font-size: 12px;
  }
`

const DescBold = styled(Desc)`
  font-family: Poppins-Bold, sans-serif;
`

const DescBoldLink = styled(DescBold)`
  cursor: pointer;
`

const customStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '500px',
    overflow: "hidden"
  }
}

const iconStyles = {
  borderRadius: "50%",
  width: 32,
  height: 32,
  marginRight: 12
}

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

interface Props {
  isOpen: boolean
  onClose: () => void
  name: string
}

const FuShare: FC<Props> = ({ isOpen, onClose, name }) => {
  const { t } = useTranslation()
  const url = "https://purposeful-people.net/download"
  const mediaUrl = "https://play-lh.googleusercontent.com/v1HgA897p3lbuY-0Nv64mbPyWY02kh6xmvkdkTwVz7cZsK1jY5T7gBaJfNposrhhLtQ=w480-h960-rw"

  const handleOpenStore = useCallback(() => {
    window.open("https://purposeful-people.net/download", '_blank', 'noopener,noreferrer');
  }, [])

  const shareText = useCallback((isOriginal?: boolean) => (
    `${t("communityDetail.shareModalDesc_1")} ${name} ${t("communityDetail.shareModalDesc_2")}
    \n${t("communityDetail.shareModalDesc_3")} ${t("communityDetail.shareModalDesc_4")} ${t(isOriginal ? "communityDetail.shareModalDesc_5" : "communityDetail.shareModalDesc_5_alt")}`
  ), [name])

  return (
    <FuModal
      isOpen={isOpen}
      onClose={onClose}
      customStyles={customStyles}
      children={
        <Fragment>
          <Title>
            {t("communityDetail.shareModalTitle")}
          </Title>
          <DescContainer>
            <Desc>
              {t("communityDetail.shareModalDesc_1")}{' '}
              <DescBold>{name}{' '}</DescBold>
              <Desc>
                {t("communityDetail.shareModalDesc_2")}
              </Desc>
            </Desc>
          </DescContainer>
          <DescContainer>
            <Desc>
              {t("communityDetail.shareModalDesc_3")}{' '}
              <DescBold>{t("communityDetail.shareModalDesc_4")}{' '}</DescBold>
              <Desc>
                {t("communityDetail.shareModalDesc_5")}{' '}
              </Desc>
            </Desc>
            <DescBoldLink onClick={handleOpenStore}>
              {t("communityDetail.shareModalDesc_6")}{' '}
            </DescBoldLink>
          </DescContainer>
          <DescBold>{t("communityDetail.shareModalDesc_7")}</DescBold>
          <Container>
            <FacebookShareButton url={url} quote={shareText()}>
              <FacebookIcon style={iconStyles} />
            </FacebookShareButton>
            <LineShareButton url={url} title={shareText()}>
              <LineIcon style={iconStyles} />
            </LineShareButton>
            <TwitterShareButton url={url} title={shareText()}>
              <TwitterIcon style={iconStyles} />
            </TwitterShareButton>
            <TelegramShareButton url={url}  title={shareText()}>
              <TelegramIcon style={iconStyles} />
            </TelegramShareButton>
            <WhatsappShareButton url={url} title={shareText(true)}>
              <WhatsappIcon style={iconStyles} />
            </WhatsappShareButton>
            <LinkedinShareButton url={url} title={shareText()}>
              <LinkedinIcon style={iconStyles} />
            </LinkedinShareButton>
            <PinterestShareButton url={url} media={mediaUrl} title={shareText()}>
              <PinterestIcon style={iconStyles} />
            </PinterestShareButton>
          </Container>
          <FuButton
            button={mainButton}
            onClick={onClose}
            text={t("home.addCommunityGuardBtn")}
          />
        </Fragment>
      }
    />
  )
}

export default FuShare
