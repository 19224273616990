import {
  listTimelineCommentService,
  ReqListTimelineComment,
  ResListTimelineComment
} from "fu/services/timeline"
import { RootState } from "fu/stores"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_TIMELINE_COMMENT_BEGIN"
const STATE_SUCCESS = "LIST_TIMELINE_COMMENT_SUCCESS"
const STATE_FAILED = "LIST_TIMELINE_COMMENT_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListTimelineComment
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListTimelineComment) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const items = getState().timeline.listTimelineComment.comment
      dispatch({ type: STATE_BEGIN })

      const response = await listTimelineCommentService(body)

      dispatch({
        type: STATE_SUCCESS,
        payload:
          body.current_page === 1
            ? response
            : { data: [...items, ...response.data] }
      })
    } catch (error: any) {
      dispatch({
        type: STATE_FAILED,
        error,
      })
    }
  }
