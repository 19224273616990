import { toast, ToastOptions } from "react-hot-toast"

type ToastType = "success" | "error" | "custom"

const toastOptions: ToastOptions = {
  position: "bottom-center",
  style: {
    fontSize: 12,
    fontFamily: "Roboto-Regular, sans-serif",
    minWidth: '300px'
  },
  duration: 2000
}

export const handleToast = (type: ToastType, message: string, customTemplate?: (idToast: string) => JSX.Element) => {
  if (type === "success") {
    toast.success(message, toastOptions)
  } else if (type === "error") {
    toast.error(message, toastOptions)
  } else if (type === "custom" && customTemplate) {
    toast.custom((t) => customTemplate(t.id))
  }
}