import axios from "axios"
import { BASE_URL } from "fu/constants/Environment"
import { CacheKey } from "fu/models/auth"
import { cacheUtil } from "fu/stores/actions/auth/authenticate"

const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
})

client.interceptors.request.use(function (config) {
  const token = cacheUtil.get(CacheKey.Token)
  if (token) {
    config.headers.Authorization = token
  }
  return config
})

export default client
