import { DefaultTheme } from "styled-components"

export enum ETypes {
  primary = "primary",
  secondary = "secondary"
}

export interface ISize {
  height: string
  size: string
}

const TRegularSize: ISize = {
  height: "46px",
  size: "14px"
}

const TSmallSize: ISize = {
  height: "46px",
  size: "14px"
}

export enum ESizes {
  regular = "regular",
  small = "small"
}

export const FSize = (size: ESizes): ISize => {
  switch (size) {
    case "regular":
      return TRegularSize
    case "small":
      return TSmallSize
    default:
      return TRegularSize
  }
}

export interface IButton {
  type: ETypes
  color?: keyof DefaultTheme
  size?: ESizes
}