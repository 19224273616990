import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconWarning: FC<IIcon> = ({ size = "15", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33333 8.33333H6.66667V3.33333H8.33333M6.66667 10H8.33333V11.6667H6.66667M10.6083 0H4.39167L0 4.39167V10.6083L4.39167 15H10.6083L15 10.6083V4.39167L10.6083 0Z" fill="#FF212A"/>
    </Icon>
  )
}

export default FuIconWarning
