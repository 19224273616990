import client from "./client"
import formatError from "./error"
import { IBanner } from "fu/models/banner"

export interface ResListBanner {
  status: boolean
  banner: Array<IBanner>
}

export const listBannerService = (): Promise<ResListBanner> => new Promise(async (resolve, reject) => {
  try {
    const response = (await client.get<ResListBanner>("banner")).data
    resolve(response)
  } catch (err: any) {
    reject(formatError(err))
  }
})