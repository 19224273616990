import React, { FC, useCallback, useEffect, Fragment, useState, useRef, useMemo, ChangeEvent, useContext } from "react"
import { FuIconArrowLeft, FuIconMenu, FuIconWarning, FuIconEditPost, FuIconTrash, FuIconAttach, FuIconAdd, FuIconCross } from "fu/components/FuIcon"
import { DefaultTheme } from "styled-components"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { FuImage, FuAsset } from "fu/components/FuImage"
import { IMAGE_URL } from "fu/constants/Environment"
import { FuNotFound } from "fu/components/FuNotFound"
import { FuPointReward } from "fu/components/FuPointReward"
import { FuLoader } from "fu/components/FuLoader"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import { useLocation, useParams, useNavigate, generatePath } from "react-router-dom"
import createThreadComment from "fu/stores/actions/thread/createThreadComment"
import deleteThreadComment from "fu/stores/actions/thread/deleteThreadComment"
import Editor from 'react-simple-wysiwyg';
import { doc, pdf, ppt, video, xls } from "fu/assets/images/thread"
import detailThread from "fu/stores/actions/thread/detailThread"
import { Paths } from "fu/routes/paths"
import deleteThread from "fu/stores/actions/thread/deleteThread"
import { AVATAR_URL } from "fu/constants/Environment"
import { handleToast } from "fu/utils/toast"
import { FuModal } from "fu/components/FuModal"
import { useSelector } from "react-redux"
import { useAppDispatch } from "fu/stores"
import { AppState } from "fu/stores/reducers"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { IUser } from "fu/models/user"
import useOutsideClick from "fu/utils/useOutsideClick"
import moment from "moment"
import { IThread, IThreadComment } from "fu/models/thread"
import listThreadComment from "fu/stores/actions/thread/listThreadComment"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  padding: 14px 0;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 100px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 16px;
  padding: 0 18px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
`

const MenuIconHeader = styled(IconHeader)`
  position: sticky;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const ThreadPostMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  width: 150px;
  padding: 12px;
  padding-bottom: 0px;
  border-radius: 10px;
  left: -150px;
  top: 15px;
  box-shadow: 0 0 2px 2px #ccc;
  z-index: 300;
`

const ThreadPostMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 14px;
`

const ThreadPostMenuContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ThreadPostMenuText = styled.p`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-left: 6px;
`

const ConfirmAddFileTitle = styled.p`
  font-family: Poppins-Bold, sans-serif;
  margin-bottom: 12px;
  font-size: 16px;
`

const ConfirmAddFileDesc = styled.p`
  font-family: Poppins-Regular, sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
  max-width: 450px;
`

const ConfirmAddFileBtnContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
`

const ConfirmAddFileBtnText = styled(ConfirmAddFileDesc)`
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
`

const ConfirmAddFileBtnLeftText = styled(ConfirmAddFileBtnText)`
  margin-right: 20px
`

const MainThreadContainer = styled.div`
  margin: 18px;
  padding: 18px;
  border-radius: 9px;
  background-color: ${({ theme }) => theme.white};
`

const ThreadProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ThreadProfileLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ThreadProfilePhoto = styled(FuImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const ThreadProfilePhotoContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
`

const ThreadProfileName = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.black};
`

const ThreadProfileDate = styled.p`
  font-family: Poppins-Regular, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.greyLight};
`

const MainThreadSubject = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  margin: 12px 0;
`

const MainThreadContent = styled.div`
  margin-bottom: 12px;
  word-wrap: break-word;
  overflow: hidden;
`

const AttachedFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 18px 0 0;
  margin-bottom: 18px;
`

const AttachedFilesCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
`

const AttachedFileContainer = styled.div`
  position: relative;
  margin-right: 18px;
  cursor: pointer;
`

const AttachedFileAssetIcon = styled(FuAsset)`
  width: 50px;
  height: 50px;
`

const AttachedFileImageIcon = styled(FuImage)`
  width: 50px;
  height: 50px;
  border-radius: 12.5px;
`

const AttachedFileImageFormIcon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 12.5px;
`

const CommentTitle = styled.p`
  font-family: Poppins-Medium, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  margin: 12px 18px;
`

const CommentContentContainer = styled.div`
  margin-top: 12px;
`

const CommentPostIconContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const CommentIconContainer = styled.div`
  cursor: pointer;
`

const CommentIconRightContainer = styled(CommentIconContainer)`
  margin-left: 12px;
`

const MoreCommentText = styled.p`
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-top: 18px;
`

const SendCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px;
  background-color: ${({ theme }) => theme.lightMain};
  width: 100%;
`

const SendCommentInputBtn = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid ${({ theme }) => theme.greyLight};
  padding: 18px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const SendCommentPlaceholder = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.greyLight};
  font-family: Poppins-Regular, sans-serif;
  margin-left: 12px;
`

const EditorInputLabel = styled.p`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.black};
  margin-bottom: 8px;
`

const AttachmentInputContainer = styled.label`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 12px 0;
`

const AttachmentInputText = styled.p`
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
`

const AttachmentInputIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
`

const AttachmentInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`

const AttachedFileDelete = styled.div`
  position: absolute;
  left: 45px;
  top: -10px;
  z-index: 50;
  cursor: pointer;
`

const PreviewImage = styled(FuImage)`
  max-width: 400px;
`

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const AddCommentModalStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '550px'
  }
}

const previewImageStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderWidth: 0,
    borderRadius: 0
  },
  overlay: {
    backgroundColor: defaultTheme.black,
    zIndex: 999
  }
}

interface Props {
  handleSetBgColor: (color: keyof DefaultTheme) => void
}

interface AddCommentForm {
  comment: string
  files: Array<File | null>
}

const {
  FORUM_DISCUSSION_EDIT: { path: ForumDiscussionsEditPath }
} = Paths

const ViewCommunityThreadComment: FC<Props> = ({ handleSetBgColor }) => {
  const wrapperRef = useRef(null)
  const { state: { detail } } = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { slug } = useParams()
  const { t } = useTranslation()
  const {
    showPointReward,
    handleShowPointReward,
    currentShowPoint,
    handleShowCurrentPoint,
    showPointRewardTitle,
    handleShowPointRewardTitle
  }:
    {
      showPointReward: boolean,
      handleShowPointReward: () => void,
      currentShowPoint: number,
      handleShowCurrentPoint: (val: number) => void,
      showPointRewardTitle: string,
      handleShowPointRewardTitle: (val: string) => void
    } =
    useContext(GeneralContext)
  const { thread, loading: loadingThread }: { thread: IThread, loading: boolean } =
    useSelector((state: AppState) => state.thread.detailThread)
  const { comments, loading: loadingComments } = useSelector((state: AppState) => state.thread.listThreadComment)
  const { user }: { user: IUser }  = useSelector((state: AppState) => state.auth.authenticate)
  const [showTopMenu, setShowTopMenu] = useState<boolean>(false)
  const [showConfirmDeleteThreadModal, setShowConfirmDeleteThreadModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showConfirmReportCommentModal, setShowConfirmReportCommentModal] = useState<boolean>(false)
  const [currentReportedComment, setCurrentReportedComment] = useState<IThreadComment | null>(null)
  const [showConfirmDeleteCommentModal, setConfirmDeleteCommentModal] = useState<string | null>(null)
  const [showAddCommentModal, setShowAddCommentModal] = useState<boolean>(false)
  const [showPreviewImage, setShowPreviewImage] = useState<string | File | null>(null)
  const [showPreviewImageType, setShowPreviewImageType] = useState<"thread" | "comment">("thread")
  const [form, setForm] = useState<AddCommentForm>({
    comment: "",
    files: []
  })
  useOutsideClick(wrapperRef, () => {
    setShowTopMenu(false)
  })

  const handleShowPreviewImage = useCallback((itemIdt?: string | File, type?: "thread" | "comment") => () => {
    setShowPreviewImageType(type || "thread")

    if (itemIdt) return setShowPreviewImage(itemIdt)

    setShowPreviewImage(null)
  }, [])

  const handleShowAddCommentModal = useCallback(() => {
    setShowAddCommentModal(!showAddCommentModal)
  }, [showAddCommentModal])

  const handleShowConfirmReportCommentModal = useCallback((comment?: IThreadComment) => () => {
    setCurrentReportedComment(comment || null)
    setShowConfirmReportCommentModal(!showConfirmReportCommentModal)
  }, [showConfirmReportCommentModal])

  useEffect(() => {
    dispatch(detailThread({ id_thread: slug || "" }))
  }, [slug])

  useEffect(() => {
    if (thread) {
      dispatch(listThreadComment({
        id_thread: thread?.id_thread || "",
        limit: 10,
        current_page: currentPage
      }))
    }
  }, [thread, currentPage])

  useEffect(() => {
    handleSetBgColor("lightMain")

    return () => {
      handleSetBgColor("white")
    }
  }, [])

  const handleShowConfirmDeleteThreadModal = useCallback(() => {
    setShowTopMenu(false)
    setShowConfirmDeleteThreadModal(!showConfirmDeleteThreadModal)
  }, [showConfirmDeleteThreadModal])

  const handleShowTopMenu = useCallback(() => {
    setShowTopMenu(!showTopMenu)
  }, [showTopMenu])

  const handlePageBack = useCallback(() => {
    navigate(-1)
  }, [])
  
  const handleReportThreadPost = useCallback((user_name: string) => () => {
    setShowTopMenu(false)

    const line1 = 'Saya ingin melaporkan Postingan Thread'
    const line2 = `Dari: *${user_name}*`
    const line3 = 'Grup'
    const link = `https://web.whatsapp.com/send?phone=+6281219406474&text=${encodeURIComponent(`${line1}\r\n\n${line2}\r\n${line3}`)}&app_absent=0`
    window.open(link, '_blank', 'noopener,noreferrer')
  }, [])

  const handleReportComment = useCallback(() => {
    setShowConfirmReportCommentModal(false)

    const line1 = 'Saya ingin melaporkan Postingan Tanggapan Thread'
    const line2 = `Dari: *${currentReportedComment?.user.full_name}*`
    const line3 = `Konten: *${currentReportedComment?.comment}*`
    const line4 = 'Dikarenakan : <Isi alasan anda>'
    const link = `https://web.whatsapp.com/send?phone=+6281219406474&text=${encodeURIComponent(`${line1}\r\n\n${line2}\r\n${line3}\r\n\n${line4}`)}&app_absent=0`
    window.open(link, '_blank', 'noopener,noreferrer')
  }, [currentReportedComment])

  const handleEditThread = useCallback(() => {
    const combinedPath =
      generatePath(ForumDiscussionsEditPath, { slug: detail?.id_community })

    navigate(combinedPath, { state: { detail, thread, isUpdate: true }})
  }, [detail, thread])

  const handleConfirmDeleteThread = useCallback(async () => {
    setShowConfirmDeleteThreadModal(false)

    const result = await dispatch(deleteThread({
      id_thread: thread?.id_thread
    }))
    
    if (result.isSuccess) {
      navigate(-1)
      handleToast("success", result.message)
    } else {
      handleToast("error", result.message)
    }
  }, [thread])

  const renderConfirmDeleteThreadModal = useMemo(() => showConfirmDeleteThreadModal && (
    <FuModal
      isOpen={showConfirmDeleteThreadModal}
      onClose={handleShowConfirmDeleteThreadModal}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("communityTimeline.deleteThreadModal.title")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("communityTimeline.deleteThreadModal.description")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteThread}>
              {t("communityTimeline.deleteThreadModal.agree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteThreadModal}>
              {t("communityTimeline.deleteThreadModal.cancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmDeleteThreadModal, handleShowConfirmDeleteThreadModal, handleConfirmDeleteThread])

  const handleOpenAttachedFile = useCallback((media: string, type: "thread" | "comment") => () => {
    if (type === "thread") {
      window.open(`${IMAGE_URL}/upload/thread/${media}`, '_blank', 'noopener,noreferrer')
    } else {
      window.open(`${IMAGE_URL}/upload/thread/comment/${media}`, '_blank', 'noopener,noreferrer')
    }
  }, [])

  const renderAttachedFiles = useCallback((media: string, type: "thread" | "comment") => {
    if (media.includes(".png") ||
      media.includes(".jpeg") ||
      media.includes(".jpg")) {
      return (
        <AttachedFileContainer onClick={handleShowPreviewImage(media, type)}>
          <AttachedFileImageIcon
            source={
              type === "thread" ?
                `upload/thread/${media}` :
                `upload/thread/comment/${media}`
            }
            alt={"Image-File"}
          />
        </AttachedFileContainer>
      )
    } else if (media.includes(".doc") || media.includes(".docx")) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(media, type)}>
          <AttachedFileAssetIcon
            source={doc}
            alt={"Document-File"}
          />
        </AttachedFileContainer>
      )
    } else if (media.includes(".pdf")) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(media, type)}>
          <AttachedFileAssetIcon
            source={pdf}
            alt={"Pdf-File"}
          />
        </AttachedFileContainer>
      )
    } else if (
      media.includes(".xls") ||
      media.includes(".xlsx")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(media, type)}>
          <AttachedFileAssetIcon
            source={xls}
            alt={"Excel-File"}
          />
        </AttachedFileContainer>
      )
    } else if (
      media.includes("ppt") ||
      media.includes("pptx")
    ) {
      return (
      <AttachedFileContainer onClick={handleOpenAttachedFile(media, type)}>
        <AttachedFileAssetIcon
          source={ppt}
          alt={"Ppt-File"}
        />
      </AttachedFileContainer>
      )
    } else if (
      media.includes("avi") ||
      media.includes("wav") ||
      media.includes("mp4") ||
      media.includes("mp3")
    ) {
      return (
        <AttachedFileContainer onClick={handleOpenAttachedFile(media, type)}>
          <AttachedFileAssetIcon
            source={video}
            alt={"Video-File"}
          />
        </AttachedFileContainer>
      )
    }
  }, [handleShowPreviewImage, handleOpenAttachedFile])

  const renderMainThread = useMemo(() => (
    <MainThreadContainer>
      <ThreadProfileContainer>
        <ThreadProfileLeftContainer>
          <ThreadProfilePhotoContainer>
            <ThreadProfilePhoto
              source={
                thread?.user?.photo.length > 0 ?
                  `upload/profil/${thread?.user?.photo}` :
                  (AVATAR_URL || "")
              }
              alt={'User-Photo'}
              alternateImage={AVATAR_URL}
            />
          </ThreadProfilePhotoContainer>
          <div>
            <ThreadProfileName>{thread?.user?.full_name}</ThreadProfileName>
            <ThreadProfileDate>
              {moment(thread?.created_at).format('DD MMM YYYY HH:mm')}
            </ThreadProfileDate>
          </div>
        </ThreadProfileLeftContainer>
      </ThreadProfileContainer>
      <MainThreadSubject>{thread?.title}</MainThreadSubject>
      <MainThreadContent className="thread-discussion" dangerouslySetInnerHTML={{ __html: thread?.content }}/>
      <AttachedFilesContainer>
        {thread?.media.map((file, index) => (
          <div key={index}>{renderAttachedFiles(file.media, "thread")}</div>
        ))}
      </AttachedFilesContainer>
    </MainThreadContainer>
  ), [thread])

  const handleShowConfirmDeleteCommentModal = useCallback((idThread?: string) => () => {
    setConfirmDeleteCommentModal(idThread || null)
  }, [])

  const handleAddMorePage = useCallback(() => {
    setCurrentPage(currentPage + 1)
  }, [currentPage])

  const renderComments = useMemo(() => (
    <Fragment>
      <CommentTitle>
        {t("threadPost.commentTitle")} ({comments.length})
      </CommentTitle>
      {comments.length === 0 ? (
        <FuNotFound text={t("threadPost.noComment")} />
      ) : (
        <Fragment>
          {comments.map((comment, index) => (
            <MainThreadContainer key={index}>
              <ThreadProfileContainer>
                <ThreadProfileLeftContainer>
                  <ThreadProfilePhotoContainer>
                    <ThreadProfilePhoto
                      source={
                        comment.user?.photo.length > 0 ?
                          `upload/profil/${comment?.user?.photo}` :
                          (AVATAR_URL || "")
                      }
                      alt={'User-Photo'}
                      alternateImage={AVATAR_URL}
                    />
                  </ThreadProfilePhotoContainer>
                  <div>
                    <ThreadProfileName>{comment.user?.full_name}</ThreadProfileName>
                    <ThreadProfileDate>
                      {moment(comment.created_at).format('DD MMM YYYY HH:mm')}
                    </ThreadProfileDate>
                  </div>
                </ThreadProfileLeftContainer>
                <CommentPostIconContainer>
                  {(user.id_user === comment.id_user || user.id_user === detail.is_admin) && (
                    <CommentIconContainer
                      onClick={handleShowConfirmDeleteCommentModal(comment.id_comment_thread)}
                    >
                      <FuIconTrash />
                    </CommentIconContainer>
                  )}
                  <CommentIconRightContainer
                    onClick={handleShowConfirmReportCommentModal(comment)}
                  >
                    <FuIconWarning />
                  </CommentIconRightContainer>
                </CommentPostIconContainer>
              </ThreadProfileContainer>
              <CommentContentContainer>
                <div dangerouslySetInnerHTML={{ __html: comment.comment }}/>
              </CommentContentContainer>
              <AttachedFilesCommentContainer>
                {comment?.media.map((file, index) => (
                  <div key={index}>
                    {renderAttachedFiles(file.media, "comment")}
                  </div>
                ))}
              </AttachedFilesCommentContainer>
            </MainThreadContainer>
          ))}
        </Fragment>
      )}
      {!loadingComments && comments.length === currentPage * 10 && (
        <MoreCommentText onClick={handleAddMorePage}>
          {t("threadPost.moreComment")}
        </MoreCommentText>
      )}
    </Fragment>
  ), [comments, user, detail, loadingComments, currentPage])

  const renderConfirmReportCommentModal = useMemo(() => showConfirmReportCommentModal && (
    <FuModal
      isOpen={showConfirmReportCommentModal}
      onClose={handleShowConfirmReportCommentModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("threadPost.commentReportTitle")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("threadPost.commentReportDescription")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleReportComment}>
              {t("threadPost.commentReportAgree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmReportCommentModal()}>
              {t("threadPost.commentReportCancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [handleReportComment, handleShowConfirmReportCommentModal, showConfirmReportCommentModal])

  const handleConfirmDeleteComment = useCallback(async () => {
    setConfirmDeleteCommentModal(null)

    const result = await dispatch(deleteThreadComment({
      id_comment_thread: showConfirmDeleteCommentModal || ""
    }))
    
    if (result.isSuccess) {
      setCurrentPage(1)
      await dispatch(listThreadComment({ id_thread: slug || "", limit: 10, current_page: 1 }))

      handleToast("success", result.message)
    } else {
      handleToast("error", result.message)
    }
  }, [showConfirmDeleteCommentModal])

  const renderConfirmDeleteCommentModal = useMemo(() => showConfirmDeleteCommentModal && (
    <FuModal
      isOpen={showConfirmDeleteCommentModal ? true : false}
      onClose={handleShowConfirmDeleteCommentModal()}
      children={
        <Fragment>
          <ConfirmAddFileTitle>
            {t("threadPost.commentDeleteTitle")}
          </ConfirmAddFileTitle>
          <ConfirmAddFileDesc>
            {t("threadPost.commentDeleteDescription")}{' '}
          </ConfirmAddFileDesc>
          <ConfirmAddFileBtnContainer>
            <ConfirmAddFileBtnLeftText onClick={handleConfirmDeleteComment}>
              {t("threadPost.commentDeleteAgree")}
            </ConfirmAddFileBtnLeftText>
            <ConfirmAddFileBtnLeftText onClick={handleShowConfirmDeleteCommentModal()}>
              {t("threadPost.commentDeleteCancel")}
            </ConfirmAddFileBtnLeftText>
          </ConfirmAddFileBtnContainer>
        </Fragment>
      }
    />
  ), [showConfirmDeleteCommentModal, handleShowConfirmDeleteCommentModal, handleConfirmDeleteComment])

  const handleChangeInput = useCallback((
    name: string, value: string | boolean | File | null
  ) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [setForm])

  const handleOnChangeFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    setForm(prevState => ({
      ...prevState,
      files: [...prevState.files, file]
    }))
  }, [setForm])

  const handleDeleteFile = useCallback((index: number) => (e: any) => {
    e.stopPropagation()

    setForm(prevState => ({
      ...prevState,
      files: prevState.files.filter((_, idxFile) => idxFile !== index)
    }))
  }, [setForm, form])

  const renderFormAttachedFiles = useCallback((file: File | null, index: number) => {
    if (file?.name.includes("png") ||
      file?.type.includes("png") ||
      file?.name.includes("jpeg") ||
      file?.type.includes("jpeg") ||
      file?.name.includes("jpg") ||
      file?.type.includes("jpg")) {
      return (
        <AttachedFileContainer>
          <AttachedFileImageFormIcon
            src={URL.createObjectURL(file)}
            alt={"Image-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (file?.name.includes("doc") ||
      file?.type.includes("doc") ||
      file?.name.includes("docx") ||
      file?.type.includes("docx")) {
      return (
        <AttachedFileContainer>
          <AttachedFileAssetIcon
            source={doc}
            alt={"Document-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("pdf") ||
      file?.type.includes("pdf")
    ) {
      return (
        <AttachedFileContainer>
          <AttachedFileAssetIcon
            source={pdf}
            alt={"Pdf-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("xls") ||
      file?.type.includes("xls") ||
      file?.name.includes("xlsx") ||
      file?.type.includes("xlsx")
    ) {
      return (
        <AttachedFileContainer>
          <AttachedFileAssetIcon
            source={xls}
            alt={"Excel-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("ppt") ||
      file?.type.includes("ppt") ||
      file?.name.includes("pptx") ||
      file?.type.includes("pptx")
    ) {
      return (
        <AttachedFileContainer>
        <AttachedFileAssetIcon
          source={ppt}
          alt={"Ppt-File"}
        />
        <AttachedFileDelete onClick={handleDeleteFile(index)}>
          <FuIconCross />
        </AttachedFileDelete>
      </AttachedFileContainer>
      )
    } else if (
      file?.name.includes("avi") ||
      file?.type.includes("avi") ||
      file?.name.includes("wav") ||
      file?.type.includes("wav") ||
      file?.name.includes("mp4") ||
      file?.type.includes("mp4") ||
      file?.name.includes("mp3") ||
      file?.type.includes("mp3")
    ) {
      return (
        <AttachedFileContainer>
          <AttachedFileAssetIcon
            source={video}
            alt={"Video-File"}
          />
          <AttachedFileDelete onClick={handleDeleteFile(index)}>
            <FuIconCross />
          </AttachedFileDelete>
        </AttachedFileContainer>
      )
    }
  }, [handleDeleteFile])

  const handleConfirmSendComment = useCallback(async () => {
    if (!form.comment.length) {
      handleToast("error", t("threadPost.commentSendError"))
    } else {
      setShowAddCommentModal(false)

      let formData = new FormData()
      formData.append("comment", form.comment)
      formData.append("id_thread", slug || "")
      if (form.files.length > 0) {
        for (let i = 0; i < form.files.length; i++) {
          formData.append(`files[${i}]`, (form.files[i] as any))
        }
      }

      const result = await dispatch(createThreadComment(formData))

      if (result.isSuccess) {
        setCurrentPage(1)
        setForm({
          comment: "",
          files: []
        })
        await dispatch(listThreadComment({ limit: 10, current_page: 1, id_thread: slug || ""}))

        handleToast("success", result.message)
        handleShowCurrentPoint(50)
        handleShowPointRewardTitle("Membuat Post atau Reply di Thread Community")
        handleShowPointReward()
      } else {
        handleToast("error", result.message)
      }
    }
  }, [form, slug])

  const renderAddCommentModal = useMemo(() => showAddCommentModal && (
    <FuModal
      isOpen={showAddCommentModal}
      onClose={handleShowAddCommentModal}
      customStyles={AddCommentModalStyles}
      children={
        <Fragment>
          <EditorInputLabel>
            {t("forumDiscussionsForm.discussionLabel")}
          </EditorInputLabel>
          <Editor
            value={form.comment}
            onChange={(e) => handleChangeInput("comment", e.target.value)}
          />
          <AttachmentInputContainer htmlFor="add-single-img">
            <AttachmentInputText>
              {t("forumDiscussionsForm.discussionAttachment")}
            </AttachmentInputText>
            <AttachmentInputIcon>
              <FuIconAdd />
            </AttachmentInputIcon>
            <AttachmentInput
              type="file"
              id="add-single-img"
              accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, .mp4, .mp3, .ppt, .pptx, .avi, .wav"
              onChange={handleOnChangeFile}
            />
          </AttachmentInputContainer>
          {form.files.length > 0 && (
            <AttachedFilesContainer>
              {form.files.length > 0 && form.files.map((file, index) => (
                <div key={index}>{renderFormAttachedFiles(file, index)}</div>
              ))}
            </AttachedFilesContainer>
          )}
          <FuButton
            button={mainButton}
            text={t("threadPost.commentSend")}
            onClick={handleConfirmSendComment}
          />
        </Fragment>
      }
    />
  ), [showAddCommentModal, handleShowAddCommentModal, form, handleChangeInput, handleOnChangeFile, handleConfirmSendComment])

  const renderPreviewImage = useMemo(() => showPreviewImage && (
    <FuModal
      customStyles={previewImageStyles}
      isOpen={showPreviewImage ? true : false}
      onClose={handleShowPreviewImage()}
      children={
        <PreviewImage
          source={
            showPreviewImageType === "thread" ?
              `upload/thread/${showPreviewImage}` :
              `upload/thread/comment/${showPreviewImage}`
          }
          alt="preview-image"
        />
      }
    />
  ), [showPreviewImage, showPreviewImageType, handleShowPreviewImage])

  const renderPointRewardModal = useMemo(() => showPointReward && (
    <FuPointReward
      title={showPointRewardTitle}
      point={currentShowPoint}
      isOpen={showPointReward}
      onClose={handleShowPointReward}
    />
  ), [showPointReward, handleShowPointReward, showPointRewardTitle, currentShowPoint])

  return (
    <Container>
      <Content>
        <HeaderContainer>
          <Header>
            <IconHeader onClick={handlePageBack}>
              <FuIconArrowLeft />
            </IconHeader>
            <TextHeader>{t("threadPost.title")}</TextHeader>
          </Header>
          {!loadingThread && !loadingComments && (
            <MenuIconHeader onClick={handleShowTopMenu}>
              <Fragment>
              {showTopMenu && (
                <ThreadPostMenu ref={wrapperRef}>
                  <ThreadPostMenuContainer onClick={handleReportThreadPost(thread?.user?.full_name)}>
                    <ThreadPostMenuContainerIcon>
                      <FuIconWarning />
                    </ThreadPostMenuContainerIcon>
                    <ThreadPostMenuText>
                      {t("communityTimeline.menu.report")}
                    </ThreadPostMenuText>
                  </ThreadPostMenuContainer>
                  
                    {user.id_user === thread?.user?.id_user && (
                      <ThreadPostMenuContainer
                        onClick={handleEditThread}
                      >
                        <ThreadPostMenuContainerIcon>
                          <FuIconEditPost color={"primaryMain"} />
                        </ThreadPostMenuContainerIcon>
                        <ThreadPostMenuText>
                          {t("communityTimeline.menu.edit")}
                        </ThreadPostMenuText>
                      </ThreadPostMenuContainer>
                    )}

                    {(user.id_user === thread?.user?.id_user ||
                      detail?.is_admin ||
                      detail?.id_user === thread?.user?.id_user
                    ) && (
                      <ThreadPostMenuContainer onClick={handleShowConfirmDeleteThreadModal}>
                        <ThreadPostMenuContainerIcon>
                          <FuIconTrash color={"primaryMain"} />
                        </ThreadPostMenuContainerIcon>
                        <ThreadPostMenuText>
                          {t("communityTimeline.menu.delete")}
                        </ThreadPostMenuText>
                      </ThreadPostMenuContainer>
                    )}
                  </ThreadPostMenu>
                )}
              </Fragment>
              <FuIconMenu />
            </MenuIconHeader>
          )}
        </HeaderContainer>
      </Content>
      {(loadingThread || loadingComments) ? (
        <LoaderContainer>
          <FuLoader
            color={defaultTheme.primaryMain}
            width={80}
            height={80}
            type={"bubbles"}
          />
        </LoaderContainer>
      ) : (
        <ContentScroll>
          {renderMainThread}
          {renderComments}
        </ContentScroll>
      )}
      {renderConfirmDeleteThreadModal}
      {renderConfirmReportCommentModal}
      {renderConfirmDeleteCommentModal}
      {renderAddCommentModal}
      {renderPointRewardModal}
      {renderPreviewImage}
      <SendCommentContainer>
        <SendCommentInputBtn onClick={handleShowAddCommentModal}>
          <FuIconAttach />
          <SendCommentPlaceholder>
            {t("threadPost.commentInputPlaceholder")}
          </SendCommentPlaceholder>
        </SendCommentInputBtn>
      </SendCommentContainer>
    </Container>
  )
}

export default ViewCommunityThreadComment
