import styled, { DefaultTheme } from "styled-components"
import { FuAsset } from "../FuImage"

interface LoaderProps {
  small?: boolean
}

interface ContainerProps {
  bgColor?: keyof DefaultTheme
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  background: ${({ theme, bgColor }) => bgColor ? theme[bgColor] : theme.white};
  align-items: center;
`

export const Header = styled.header`
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  background: ${({ theme }) => theme.primaryMain};
  display:flex;
  justify-content:center;
  align-items: center;
  user-select: none;
  z-index: 500;
`

export const HeaderBackBtnContainer = styled.div`
  align-items: center;
  margin: 0 16px;
  justify-content: center;
  display: flex;
  cursor: pointer;
`

export const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0;
  width: 100%;
  max-width: 702px;
  padding: 0 16px;
`

export const HeaderBlank = styled.div`
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  background: ${({ theme }) => theme.white};
  display:flex;
  align-items: center;
  user-select: none;
  z-index: 999;
  box-shadow: 0px 20px 10px -16px rgba(0, 0, 0, 0.25);
`

export const HeaderBlankContent = styled.div`
  flex-direction: row;
  display: flex;
  width: 678px;
  margin-left: auto;
  margin-right: auto;
`

export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const HeaderLogo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  align-items: center;
`

export const HeaderTitle = styled.span`
  font-size: 18px;
`

export const Logo = styled(FuAsset)`
  height: 40px;
  width: 34px;
  cursor: pointer;
`

export const TextLogo = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.white};
  font-family: Poppins-Bold, sans-serif;
`

export const Main = styled.main`
  margin-top: 69px;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 670px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const Loader = styled.div<LoaderProps>`
  margin: auto;
  border: ${({ small }) => small ? "5px" : "20px"} solid ${({ theme }) => theme.background};
  border-radius: 50%;
  border-top: ${({ small }) => small ? "5px" : "20px"} solid ${({ theme }) => theme.primaryMain};
  width: ${({ small }) => small ? "25px" : "100px"};
  height: ${({ small }) => small ? "25px" : "100px"};;
  animation: spinner 1s linear infinite;

  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const LogoutConfirmTitle = styled.p`
  font-family: Poppins-Bold, sans-serif;
  font-size: 20px;
  margin-bottom: 12px;
`

export const LogoutConfirmDesc = styled.p`
  font-family: Poppins-Regular, sans-serif;
  font-size: 14px;
`

export const LogoutChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`

export const LogoutChoice = styled.p`
  font-size: 14px;
  cursor: pointer;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.greyLight};
`

export const LogoutChoiceWithPadding = styled(LogoutChoice)`
  padding-right: 20px;
  color: ${({ theme }) => theme.primaryMain};
`