import { FuLayout, FuLoader } from "fu/components/FuApp"
import { useAppDispatch } from "fu/stores"
import authenticateAction from "fu/stores/actions/auth/authenticate"
import logout from "fu/stores/actions/auth/logout"
import { FuModal } from "fu/components/FuModal"
import { FuButton, ETypes, ESizes } from "fu/components/FuButton"
import { Toaster } from 'react-hot-toast';
import { AppState } from "fu/stores/reducers"
import { getFcmToken, onMessageListener } from "fu/utils/firebase"
import React, { Suspense, useCallback, useEffect, useState, useMemo, Fragment, useContext } from "react"
import { GeneralContext } from "fu/contexts/GeneralProvider"
import detailUser from "fu/stores/actions/user/detailUser"
import { useSelector } from "react-redux"
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { defaultTheme } from "fu/components/FuApp/FuTheme"
import ProtectedRoutes from "./protected"
import PublicRoutes from "./public"
import styled, { DefaultTheme } from "styled-components"
import { IUser } from "fu/models/user"
import { useTranslation } from "react-i18next"
import { handleToast } from "fu/utils/toast"

const ExpiredModalTitle = styled.p`
  font-size: 15px;
  font-family: Poppins-Bold, sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
`

const ExpiredModalDesc = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
  margin: 16px 0 32px 0;
`

const ExpiredModalSecondBtn = styled.p`
  font-family: Poppins-Bold, sans-serif;
  color: ${({ theme }) => theme.greyLabel};
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin: 16px 0 8px 0;
`

const ExpiredModalStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(0%, -45%)",
    borderWidth: 0.5,
    borderRadius: 10,
    boxShadow: `1px 1px 4px 1px ${defaultTheme.greyLight}`,
    maxWidth: '400px',
    overflow: "hidden"
  }
}

const mainButton = {
  type: ETypes["primary"],
  size: ESizes["regular"]
}

const AppRoutes = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { handleSetFcmToken }: { handleSetFcmToken: (token: string) => void } = useContext(GeneralContext)
  const { status } = useSelector((state: AppState) => state.auth.authenticate)
  const { user: userProfile } = useSelector((state: AppState) => state.user.userDetail)
  const { user }: { user: IUser } = useSelector((state: AppState) => state.auth.authenticate)
  const [bgColor, setBgColor] = useState<keyof DefaultTheme>("white")
  const [showForegroundNotif, setShowForegroundNotif] = useState<boolean>(false)
  const [notification, setNotification] = useState({title: '', body: ''})
  const [isFcmTokenFound, setIsFcmTokenFound] = useState<boolean>(false)
  getFcmToken(setIsFcmTokenFound, handleSetFcmToken)

  onMessageListener().then((payload : any) => {
    setShowForegroundNotif(true)
    setNotification({ title: payload.notification.title, body: payload.notification.body })
  }).catch((err: any) => {})

  const handleSetBgColor = useCallback((color: keyof DefaultTheme) => {
    setBgColor(color)
  }, [])

  useEffect(() => {
    dispatch(authenticateAction())
  }, [dispatch, authenticateAction])

  useEffect(() => {
    if (status === "authenticated" && user) {
      dispatch(detailUser({
        id_user: user.id_user,
        logged_id_user: user.id_user
      }))
    }
  }, [status, user])

  const renderNotifCustom = useCallback((idToast: string) => {
    return (
      <div>
        
      </div>
    )
  }, [])

  useEffect(() => {
    if (showForegroundNotif) {
      handleToast("custom", "", renderNotifCustom)
    }
  }, [showForegroundNotif])

  const handleNavigateDownloadApp = useCallback(() => {
    window.open("https://purposeful-people.net/download", '_blank', 'noopener,noreferrer');
  }, [])

  const handleConfirmLogout = useCallback(() => {
    dispatch(logout())
  }, [])

  const renderExpiredModal = useMemo(() => userProfile?.expired == 1 && (
    <FuModal
      isOpen={userProfile?.expired == 1}
      customStyles={ExpiredModalStyles}
      onClose={() => {}}
      children={
        <Fragment>
          <ExpiredModalTitle>
            {t("home.expiredTitle")}
          </ExpiredModalTitle>
          <ExpiredModalDesc>
            {t("home.expiredDesc")}
          </ExpiredModalDesc>
          <FuButton
            button={mainButton}
            onClick={handleNavigateDownloadApp}
            text={t("home.expiredMainButton")}
          />
          <ExpiredModalSecondBtn onClick={handleConfirmLogout}>
            {t("home.expiredSecondButton")}
          </ExpiredModalSecondBtn>
        </Fragment>
      }
    />
  ), [userProfile])

  if (status === "checking") return <FuLoader />

  return (
    <Router>
      <Suspense fallback={<FuLoader />}>
        <FuLayout customBgColor={bgColor}>
          <Fragment>
            <Routes>
              {
                status === "authenticated"
                  ? <Route path="/*" element={<ProtectedRoutes handleSetBgColor={handleSetBgColor} />} />
                  : <Route path="/*" element={<PublicRoutes />} />
              }
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
            {renderExpiredModal}
            <Toaster />
          </Fragment>
        </FuLayout>
      </Suspense>
    </Router>
  )
}

export default AppRoutes