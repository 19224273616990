import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconEdit: FC<IIcon> = ({ size = "12", color, className = "" }) => {
  return (
    <Icon className={className} color={color} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 9L5.71458 8.99071L11.6395 3.08354C11.8721 2.84948 12 2.53864 12 2.20799C12 1.87734 11.8721 1.5665 11.6395 1.33244L10.6639 0.35039C10.1989 -0.117725 9.38753 -0.115249 8.92618 0.348532L3 6.25694V9ZM9.79414 1.22594L10.7716 2.20613L9.78921 3.18571L8.81361 2.20428L9.79414 1.22594ZM4.23026 6.77336L7.93951 3.07487L8.91511 4.05692L5.20648 7.75417L4.23026 7.75726V6.77336Z" />
      <path d="M1.22222 12H9.77778C10.4518 12 11 11.402 11 10.6667V4.888L9.77778 6.22133V10.6667H3.15211C3.13622 10.6667 3.11972 10.6733 3.10383 10.6733C3.08367 10.6733 3.0635 10.6673 3.04272 10.6667H1.22222V1.33333H5.4065L6.62872 0H1.22222C0.548167 0 0 0.598 0 1.33333V10.6667C0 11.402 0.548167 12 1.22222 12Z" />
    </Icon>
  )
}

export default FuIconEdit