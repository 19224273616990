import React, { lazy } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Paths } from "./paths"

const {
  LOGIN: { path: LoginPath },
  PRIVACY_POLICY: { path: PrivacyPolicyPath },
  TERMS_AND_CONDITIONS: { path: TnCPath }
} = Paths

const Login = lazy(() => import("../views/Login"))
const TermsAndPolicy = lazy(() => import("../views/TermsAndPolicy"))

const PublicRoutes = () => (
  <Routes>
    <Route path={LoginPath} element={<Login />} />
    <Route path={TnCPath} element={<TermsAndPolicy />} />
    <Route path={PrivacyPolicyPath} element={<TermsAndPolicy />} />
    <Route path="*" element={<Navigate to={LoginPath} replace />} />
  </Routes>
)

export default PublicRoutes