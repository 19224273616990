import React, { FC } from "react"
import { Icon } from "./Component"
import { IIcon } from "./types"

const FuIconLeave: FC<IIcon> = ({ size = "12", color, className = "" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
      <g id="SVGRepo_bgCarrier" stroke-width="0"/>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="SVGRepo_iconCarrier"> <path d="M16.5 15V19.5H5.5V5.5H16.5V10M10 12.5H22.5" stroke="#FF212A" stroke-width="1.5"/> <path d="M20 10L22.5 12.5L20 15" stroke="#FF212A" stroke-width="1.5"/> </g>
    </svg>
  )
}

export default FuIconLeave
