import { listFileManagerService, ReqListFileManager, ResListFileManager } from "fu/services/filemanager"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_FILE_MANAGER_BEGIN"
const STATE_SUCCESS = "LIST_FILE_MANAGER_SUCCESS"
const STATE_FAILED = "LIST_FILE_MANAGER_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListFileManager
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListFileManager) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: STATE_BEGIN })

      const response = await listFileManagerService(body)

      dispatch({
        type: STATE_SUCCESS,
        payload: response
      })
    } catch (error: any) {
      dispatch({
        type: STATE_FAILED,
        error,
      })
    }
  }
