import { combineReducers } from "redux"
import authReducer from "./auth"
import staticReducer from "./static"
import communityReducer from "./community"
import timelineReducer from "./timeline"
import threadReducer from "./thread"
import fileManagerReducer from "./filemanager"
import userReducer from "./user"

const rootReducer = combineReducers({
  auth: authReducer,
  static: staticReducer,
  community: communityReducer,
  timeline: timelineReducer,
  thread: threadReducer,
  fileManager: fileManagerReducer,
  user: userReducer
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer