import React, { FC } from "react"
import ReactLoading, { LoadingType } from "react-loading"

interface Props {
  type: LoadingType
  color: string
  height: number
  width: number
}

const FuLoader: FC<Props> = ({ type, color, height, width }) => (
  <ReactLoading type={type} color={color} height={height} width={width} />
)

export default FuLoader
