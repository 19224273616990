import { Dispatch } from "redux"
import {
  timelineDeleteService,
  ReqTimelineDelete,
  ResTimelineDelete
} from "fu/services/timeline"

const STATE_BEGIN = "TIMELINE_DELETE_BEGIN"
const STATE_END = "TIMELINE_DELETE_END"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResTimelineDelete
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: ReqTimelineDelete) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await timelineDeleteService(body)
    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
