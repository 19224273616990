import { Dispatch } from "redux"
import {
  moveFileManagerService,
  ResMoveFileManager
} from "fu/services/filemanager"

const STATE_BEGIN = "MOVE_FILE_MANAGER_BEGIN"
const STATE_END = "MOVE_FILE_MANAGER_END"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateEndAction {
  readonly type: typeof STATE_END
  readonly payload: ResMoveFileManager
}

export type StateActions =
| IStateBeginAction
| IStateEndAction

export default (body: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await moveFileManagerService(body)

    dispatch({ type: STATE_END })

    return { ...response, isSuccess: true }
  } catch (error: any) {
    dispatch({ type: STATE_END })
    
    return error
  }
}
