import { StateActions } from "fu/stores/actions/filemanager/deleteFileManager"

type State = {
  loading: boolean
}

const initialState: State = {
  loading: false
}

const deleteFileManagerReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "DELETE_FILE_MANAGER_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "DELETE_FILE_MANAGER_END":
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default deleteFileManagerReducer
