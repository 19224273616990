import { StateActions } from "fu/stores/actions/community/actionUserJoinRequest"

type State = {
  loading: boolean
}

const initialState: State = {
  loading: false
}

const actionUserJoinRequestReducer = (state: State = initialState, action: StateActions) => {
  switch (action.type) {
    case "ACTION_USER_JOIN_REQUEST_BEGIN":
      return {
        ...state,
        loading: true
      }
    case "ACTION_USER_JOIN_REQUEST_END":
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default actionUserJoinRequestReducer
