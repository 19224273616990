import { Dispatch } from "redux"
import { cacheUtil, STATE_BEGIN, STATE_FAILED } from "./authenticate"
import { STATE_SUCCESS } from "../user/detailUser"

export default () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: STATE_BEGIN })

    cacheUtil.removeAll()

    dispatch({ type: STATE_SUCCESS, payload: null })
    dispatch({ type: STATE_FAILED })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
