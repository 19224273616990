import { initializeApp } from "firebase/app"
import { getMessaging, onMessage, getToken } from "firebase/messaging"

const FIREBASE_MESSAGING_KEY = process.env.FIREBASE_MESSAGING_KEY

const firebaseConfig = {
  apiKey: "AIzaSyDkZ6AMDcS2tinNpKhV-56NLwM-mgwCW4o",
  authDomain: "purposeful-people.firebaseapp.com",
  databaseURL: "https://purposeful-people-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "purposeful-people",
  storageBucket: "purposeful-people.appspot.com",
  messagingSenderId: "137291546455",
  appId: "1:137291546455:web:4dd26afaff6950057f4812",
  measurementId: "G-4MRRTR8QE2"
}

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFcmToken = (
  setTokenFound: (isFound: boolean) => void,
  setToken: (token: string) => void
) => {
  return getToken(messaging, {vapidKey: FIREBASE_MESSAGING_KEY}).then((currentToken: string) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true)
      setToken(currentToken)
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.')
      setTokenFound(false)
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
    // catch error while creating client token
  })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});