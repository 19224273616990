import { FuIconSearch } from "fu/components/FuIcon"
import FuIconArrowLeft from "fu/components/FuIcon/IconArrowLeft"
import { FuNotFound } from "fu/components/FuNotFound"
import { Paths } from "fu/routes/paths"
import { FuImage } from "fu/components/FuImage"
import { FuInput, IInput } from "fu/components/FuInput"
import { useAppDispatch } from "fu/stores"
import listTopic from "fu/stores/actions/static/listTopic"
import { AppState } from "fu/stores/reducers"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate, generatePath } from "react-router-dom"
import styled from "styled-components"
import { ITopic } from "fu/models/topic"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  background: ${({ theme }) => theme.white};
  padding: 14px 0;
`

const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow-y: auto;
  
  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  user-select: none;
  padding: 0 18px;
`

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const TextHeader = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.textMain};
  font-family: Poppins-Bold, sans-serif;
`

const SearchHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0 18px;
`

const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  user-select: none;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  cursor: pointer;
`

const ItemList = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  padding: 0 6px;
`

const ImageItem = styled.div`
  height: 52px;
  width: 52px;
  border-radius: 10px;
  overflow: hidden;
`

const TextItem = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
  font-family: Poppins-Bold, sans-serif;
`

const inputSearch: IInput = {
  name: "search",
  radius: "10px",
  border: "greySearch"
}

const { CATEGORY_DETAIL } = Paths

const ViewCategory: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState<string>("")
  const { topics } = useSelector((state: AppState) => state.static.listTopic)

  useEffect(() => {
    dispatch(listTopic())
  }, [dispatch, listTopic])

  const handlePageCategoryDetail = useCallback((topic: ITopic) => () => {
    const combinedPath = generatePath(CATEGORY_DETAIL.path, { slug: topic.id_topic })

    navigate(combinedPath, { state: { topic }})
  }, [navigate])

  const handlePageBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleChangeSearch = useCallback((_: string, value: string) => {
    setSearch(value)
  }, [setSearch])

  const categories = useMemo(() => {
    if (search) return topics.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))

    return topics
  }, [topics, search])

  return (
    <Container>
      <Content>
        <Header>
          <IconHeader onClick={handlePageBack}>
            <FuIconArrowLeft />
          </IconHeader>
          <TextHeader>{t("category.all")}</TextHeader>
        </Header>
        <SearchHeader>
          <FuInput
            input={inputSearch}
            type="text"
            placeholder={t("category.search")}
            value={search}
            prepend={<FuIconSearch
              color="greySearch"
              size="16"
            />}
            onChange={handleChangeSearch}
          />
        </SearchHeader>
      </Content>
      <ContentScroll>
        <List>
          {
            categories.length ? categories.map((item, index) => (
              <ContentList key={index} onClick={handlePageCategoryDetail(item)}>
                <ItemList>
                  <ImageItem>
                    <FuImage
                      source={`upload/topic/${item.icon}`}
                      alt={item.title}
                    />
                  </ImageItem>
                  <TextItem>{item.title}</TextItem>
                </ItemList>
              </ContentList>
            )) : (
              <FuNotFound text={"category.notFound"} />
            )
          }
        </List>
      </ContentScroll>
    </Container>
  )
}

export default ViewCategory