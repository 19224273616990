import { FuImage } from "fu/components/FuImage"
import { ICommunity } from "fu/models/community"
import { Paths } from "fu/routes/paths"
import React, { FC, useCallback } from "react"
import { useNavigate, generatePath } from "react-router-dom"
import { Trans } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  user-select: none;
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0px 14px 0px 14px;
  background: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
  width: 166px;
  margin-top: 20px;
  height: 130px;
  justify-content: center;
`

const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: ${({ theme }) => theme.primaryDefault};
  position: absolute;
  top: 0;
  left: 63px;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 6px;
`

const ImageHeader = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 6px;
  overflow: hidden;
`

const TextHeaderContainer = styled.div`
  justify-content: center;
`

const TextHeader = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.greyTitle};
  font-family: Poppins-Bold, sans-serif;
`

  const TextDescriptionContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TextDescription = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.greySearch};
  font-family: Poppins-Regular, sans-serif;
`

type Props = {
  row: number
  community: ICommunity
}

const {  COMMUNITY_DETAIL: { path } } = Paths;

const HomeCommunity: FC<Props> = ({ row, community }) => {
  const navigate = useNavigate()

  const handleNavigateDetail = useCallback((id: string) => () => {
    const combinedPath = generatePath(path, { slug: id })
    navigate(combinedPath)
  }, [])

  return (
    <Container onClick={handleNavigateDetail(community.id_community)}>
      <Content>
        <Bullet>
          {row}
        </Bullet>
        <Header>
          <ImageHeader>
            <FuImage
              alt={community.title}
              source={`upload/community/${community.image}`}
            />
          </ImageHeader>
          <TextHeaderContainer>
            <TextHeader>{community.title}</TextHeader>
          </TextHeaderContainer>
        </Header>
        <TextDescription>
          <Trans i18nKey="home.member" values={{ count: community.count_members }} />
        </TextDescription>
        <TextDescriptionContainer>
          <TextDescription>{community.description}</TextDescription>
        </TextDescriptionContainer>
      </Content>
    </Container>
  )
}

export default HomeCommunity