import {
  listUserJoinRequestService,
  ReqListUserJoinRequest,
  ResListUserJoinRequest
} from "fu/services/community"
import { Dispatch } from "redux"

const STATE_BEGIN = "LIST_USER_JOIN_REQUEST_BEGIN"
const STATE_SUCCESS = "LIST_USER_JOIN_REQUEST_SUCCESS"
const STATE_FAILED = "LIST_USER_JOIN_REQUEST_FAILED"

export interface IStateBeginAction {
  readonly type: typeof STATE_BEGIN
}

export interface IStateSuccessAction {
  readonly type: typeof STATE_SUCCESS
  readonly payload: ResListUserJoinRequest
}

export interface IStateFailedAction {
  readonly type: typeof STATE_FAILED
}

export type StateActions =
| IStateBeginAction
| IStateSuccessAction
| IStateFailedAction

export default (body: ReqListUserJoinRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: STATE_BEGIN })

    const response = await listUserJoinRequestService(body)

    dispatch({
      type: STATE_SUCCESS,
      payload: response
    })
  } catch (error: any) {
    dispatch({
      type: STATE_FAILED,
      error,
    })
  }
}
